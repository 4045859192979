import React, {useState} from 'react';
import '../styles/StudentListButtons.css';

const StudentListButtons = ({onFilterChange}) => {
    const buttons = [
        "Pros",
        "Unverified Cert",
        "Active Course",
        "Upcoming Event",
        "Outstanding Charges",
        "Outstanding Forms"
    ];

    const [activeButtons, setActiveButtons] = useState([]);

    const handleButtonClick = (button) => {
        const isActive = activeButtons.includes(button);
        const newActiveButtons = isActive
            ? activeButtons.filter(b => b !== button)
            : [...activeButtons, button];
        setActiveButtons(newActiveButtons);
        onFilterChange(newActiveButtons);
    };

    return (
        <div className="flex flex-wrap mt-1 mx-1">
            {buttons.map((button, index) => {
                const isFirst = index === 0;
                const isLast = index === buttons.length - 1;
                const isActive = activeButtons.includes(button);

                return (
                    <button
                        key={index}
                        type="button"
                        onClick={() => handleButtonClick(button)}
                        className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                            isFirst ? 'rounded-l-md' : ''
                        } ${isLast ? 'rounded-r-md' : ''} ${!isFirst ? '-ml-px' : ''} ${
                            isActive ? 'bg-blue-500 text-white' : 'bg-white text-gray-900'
                        }`}
                    >
                        {button}
                    </button>
                );
            })}
        </div>
    );
};

export default StudentListButtons;
