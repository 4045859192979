import React from 'react';

const SearchInput = ({ value, onChange }) => {
    return (
        <div className="flex items-center space-x-2">
            <input
                type="search"
                id="search"
                name="search"
                value={value}
                onChange={onChange}
                placeholder="Search by name, email, phone, city, state, or course code (e.g. PADI-OW)."
                className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
        </div>
    );
};

export default SearchInput;
