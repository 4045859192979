import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {post} from "aws-amplify/api";
import {useState} from "react";

const features = [
    {
        name: 'Maintain your web presence',
        description: "You'll keep your current website address and data, ready for you when you return. The home page carousel will continue to show the courses you chose to highlight.",
        retained: true,
    },
    {
        name: 'Courses page',
        description: "Keep your customized Courses page with pricing and links to full course pages specifying minimum requirements, progression opportunities, academics, equipment, dives, FAQ, and social media sharing links.",
        retained: true,
    },
    {
        name: 'Sign Up button',
        description: "The Sign Up button will be removed as you'll no longer manage student signups on the website. Customers will see a single 'Inquire' button that leads to your About page with contact information.",
        retained: false,
    },
    {
        name: 'Calendar',
        description: "Retain the calendar with all past event details, including uploaded media, links to digital wallets for tips, and links to collect reviews on sites like Google.",
        retained: true,
    },
    {
        name: 'Manage Calendar',
        description: "The ability to create new events in the calendar will be removed.",
        retained: false,
    },
    {
        name: 'Contact page',
        description: "The Contact page will be removed. Your customers can reach out using the contact information provided on the About page.",
        retained: false,
    },
    {
        name: 'About page',
        description: "Customers can reach out using the contact information provided on the About page.",
        retained: true,
    },
    {
        name: 'Digital Wallets',
        description: "Customers will no longer be able to issue payments to your digital wallets through the website.",
        retained: false,
    },
    {
        name: 'Student Lists',
        description: "The ability to manage student lists will be removed. You'll receive all your existing data in a CSV file you can import into your favorite spreadsheet software.",
        retained: false,
    },
    {
        name: 'Transactions',
        description: "The ability to view and manage transactions will be removed. You'll receive all your existing data in a CSV file you can import into your favorite spreadsheet software.",
        retained: false,
    }
]

export default function BarnacleTier() {
    const navigate = useNavigate();
    const subscriptionDetails = useSelector((state) => state.instrSubscription.subscriptionDetails);
    const currentFrequency = subscriptionDetails.frequency;
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);

    const handleSwitchAtEndOfTerm = async () => {
        try {
            const apiName = 'InstrOfScubaAPI';
            const path = '/manage-subscription';
            const options = {
                body: {
                    new_tier_id: 4,  // 4 is the ID for the Barnacle tier
                    action: 'stripe_subscription',
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            if (data.success) {
                // dispatch(setSubscriptionDetails(data.subscriptionDetails));
                // TODO: update this
                console.log('');
            } else {
                // Technically should never happen as we check for subscription_id vs. sub_id_at_expiry before getting here.
                console.log(data.error)
            }
        } catch (error) {
            setErrorInfo('/manage-subscription from BarnacleTier\n' + error)
        }
    }

    const handleSwitchImmediately = () => {
        console.log('Annual to Monthly Immediately');
    }

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div className="bg-white">
            <div aria-hidden="true" className="relative">
                <img
                    alt=""
                    src="/images/barnacle-tier.webp"
                    className="h-96 w-full object-cover object-center"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-white"/>
            </div>

            <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
                <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Maintain Your Presence
                        with the Barnacle Tier</h2>
                    <p className="mt-4 text-gray-500">
                        Barnacles are masters of resilience, firmly attaching themselves to their surroundings and
                        thriving in the most challenging environments. With the Barnacle tier, you can securely
                        maintain your website's presence while taking a break. Your data remains intact, ready for
                        you when you return. Perfect for those who need to pause but not lose their footing. Stay
                        anchored, and come back stronger.
                    </p>
                </div>

                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
                    {features.map((feature) => (
                        <div key={feature.name} className="flex items-start">
                            {feature.retained ? (
                                <FaCheckCircle className="flex-shrink-0 h-6 w-6 text-green-500 pr-2"/>
                            ) : (
                                <FaTimesCircle className="flex-shrink-0 h-6 w-6 text-red-500 pr-2"/>
                            )}
                            <div>
                                <dt className="font-medium text-gray-900">{feature.name}</dt>
                                <dd className="mt-2 text-gray-500">{feature.description}</dd>
                            </div>
                        </div>
                    ))}
                </dl>
                <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        className={`mb-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600`}
                        onClick={() => {
                            navigate('/manage-subscription');
                        }}
                    >
                        Keep Current Tier
                    </button>
                    <button
                        type="button"
                        className={`mb-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 text-white bg-orange-600 hover:bg-orange-500 focus-visible:outline-orange-600`}
                        onClick={handleSwitchAtEndOfTerm}
                    >
                        Switch at End of Term
                    </button>
                    {currentFrequency === 'Annually' && (
                    <button
                        type="button"
                        className={`mb-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 text-white bg-red-600 hover:bg-red-500 focus-visible:outline-red-600`}
                        onClick={handleSwitchImmediately}
                    >
                        Switch Immediately
                    </button>
                    )}
                </div>
            </div>
        </div>
    );
}