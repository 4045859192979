import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Underwater Navigator",
    description: "Be the scuba diver everyone wants to follow because you know where you are and where you're going. " +
        "The PADI Underwater Navigator course fine-tunes your observation skills and teaches you to more accurately " +
        "use your compass underwater. If you like challenges with big rewards, take this course and have fun " +
        "finding your way."
}

const faqs = [
    {
        question: 'What will I learn?',
        answer: "You'll learn how to use a compass and natural navigation cues, navigate different search patterns, " +
            "mark or relocate a submerged object, and estimate distance underwater."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Learn how to use a compass underwater, recognize natural navigation cues, navigate different search " +
            "patterns, mark or relocate a submerged object, and estimate distance underwater. " +
            "It's your course on your time. Study offline, or online using a computer or mobile device. " +
            "Normally 2-4 hours of self study. " +
            "This is done at your own pace, but should be completed before in-water activities with your instructor.",

    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are three dives required for this course, which could be conducted in a single day but is " +
            "usually done over two days.  You will practice navigation skills and learn how to use a compass underwater. " +
            "You will also learn how to navigate using natural cues and how to estimate distance underwater. "
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '10 years old',
    'PADI Open Water Diver'
];

const PadiUnderwaterNavigator = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-UN"
        imageUrl='/images/PADI/UN2-b0FkhHx8.jpg'
        courseSlug='/courses/padi-underwater-navigator'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Underwater%20Navigator%20Course"
    />
);

export default PadiUnderwaterNavigator;
