import 'symbol-observable';
import {Amplify} from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import store from './redux/store';
import {Provider} from 'react-redux';
import {MyFallbackComponent} from "./containers/MyFallbackComponent";
import {HelmetProvider} from "react-helmet-async";
import ErrorBoundary from "./containers/ErrorBoundary";
import {sendToGoogleAnalytics} from './send-to-google-analytics';
import amplifyConfig from './amplifyconfiguration.json';
import customConfig from "./customconfig.json"

Amplify.configure({...amplifyConfig}, {...customConfig});

const router = createBrowserRouter([
    {
        path: "*",
        element: <ErrorBoundary FallbackComponent={MyFallbackComponent}><App/></ErrorBoundary>,
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <RouterProvider router={router} fallbackElement={<MyFallbackComponent/>}/>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
