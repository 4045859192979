import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Dry Suit Diver",
    description: "Unlike wetsuits, drysuits are filled with air. During your drysuit course, your instructor will " +
        "teach you how to control the air in your drysuit along with your buoyancy. You'll also learn about " +
        "different types of drysuits, basic drysuit repairs and maintenance."
}

const faqs = [
    {
        question: 'Do I need my own dry suit?',
        answer: "Yes.  While some dive shops may have dry suits available for rent, " +
            "it is not common. Be sure to talk to your instructor before purchasing a dry suit for this course, " +
            "as there are many different types of dry suits.  Your instructor will be able to help you select the " +
            "best dry suit for your needs."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "PADI eLearning makes it easy to fit scuba lessons into a busy schedule. " +
            "Learn about dry suit construction, undergarments, argon inflation systems, safety and handling emergencies, " +
            "maintenance, etc., wherever it's convenient for you. " +
            "It's your course on your time. Study offline, or online using a computer or mobile device. " +
            "Normally 2-4 hours of self study. " +
            "This is done at your own pace, but should be completed before in-water activities with your instructor.",

    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option.",
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "You'll conduct one confined water dive in a pool or pool-like conditions.  " +
            "Here you will practice getting in and out of your dry suit, determining proper weighting, " +
            "and mastering buoyancy control before moving on to open water."
    },
    {
        title: 'Ocean',
        body: "There are two dives required for this course, typically conducted in one day."
    }
]

const progressionCourseCodes = ['PADI-PSD', 'PADI-MSD'];

const requirements = [
    '10 years old',
    'PADI Open Water Diver, or in conjunction with your PADI Open Water Diver course'
];

const PadiDrySuit = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-DRY"
        imageUrl='/images/PADI/DrySuitDiver-Gallery-89u2U_E1.jpg'
        courseSlug='/courses/padi-dry-suit'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Dry%20Suit%20Diver%20Course"
    />
);

export default PadiDrySuit;
