import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {selectAnswers1, saveAnswers1} from '../../redux/diverMedicalFormAnswersSlice';
import {useNavigate} from "react-router-dom";

const DiverMedicalForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const answers = useSelector(selectAnswers1) || Array(10).fill(null);

    const handleButtonChange = (questionIndex, value) => {
        const newAnswers = [...answers];
        newAnswers[questionIndex] = value;
        dispatch(saveAnswers1(newAnswers));

        document.getElementById(`question-${questionIndex}`).classList.remove('border-red-500');
        document.getElementById(`question-${questionIndex}`).classList.add('border-gray-300')
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const firstUnansweredQuestionIndex = answers.findIndex(answer => answer === null);

        if (firstUnansweredQuestionIndex !== -1) {
            document.getElementById(`question-${firstUnansweredQuestionIndex}`).scrollIntoView({behavior: 'smooth'});
            document.getElementById(`question-${firstUnansweredQuestionIndex}`).classList.add('border-red-500');
        } else {
            dispatch(saveAnswers1(answers));
            navigate('/forms/diver-medical-form-2');
        }
    };

    const questions = [
        'I have had problems with my lungs, breathing, heart and/or blood affecting my normal physical or mental performance.',
        'I am over 45 years of age.',
        'I struggle to perform moderate exercise (for example, walk 1.6 kilometer/one mile in 14 minutes or swim 200 meters/yards without resting), OR ' +
        'I have been unable to participate in a normal physical activity due to fitness or health reasons within the past 12 months.',
        'I have had problems with my eyes, ears, or nasal passages/sinuses.',
        'I have had surgery within the last 12 months, OR I have ongoing problems related to past surgery.',
        'I have lost consciousness, had migraine headaches, seizures, stroke, significant head injury, or suffer from persistent neurologic ' +
        'injury or disease.',
        'I am currently undergoing treatment (or have required treatment within the last five years) for psychological problems, personality ' +
        'disorder, panic attacks, or an addiction to drugs or alcohol; or, I have been diagnosed with a learning or developmental ' +
        'disability.',
        'I have had back problems, hernia, ulcers, or diabetes.',
        'I have had stomach or intestine problems, including recent diarrhea.',
        'I am taking prescription medications (with the exception of birth control or anti-malarial drugs other than mefloquine (Lariam).'
    ];

    return (
        <>
            <section className="bg-gray-100 font-sans">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 lg:space-x-12 mb-8">
                        <div className="w-1/2 sm:w-1/3 md:w-1/6 mb-4">
                            <img src="/images/logos/DAN.png" alt="DAN" className="w-full"/>
                        </div>
                        <div className="w-1/2 sm:w-1/3 md:w-1/6 mb-4">
                            <img src="/images/logos/WRSTC.png" alt="WRSTC" className="w-full"/>
                        </div>
                        <div className="w-1/2 sm:w-1/3 md:w-1/6 mb-4">
                            <img src="/images/logos/RSTC.png" alt="RSTC" className="w-full"/>
                        </div>
                        <div className="w-1/2 sm:w-1/3 md:w-1/6 mb-4">
                            <img src="/images/logos/RSTC-Europe.png" alt="RSTC Europe" className="w-full"/>
                        </div>
                        <div className="w-1/2 sm:w-1/3 md:w-1/6 mb-4">
                            <img src="/images/logos/UHMS.png" alt="UHMS" className="w-full"/>
                        </div>
                        <div className="w-1/2 sm:w-1/3 md:w-1/6 mb-4">
                            <img src="/images/logos/CMAS.png" alt="CMAS" className="w-full"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 items-center gap-8">
                        <h2 className="text-2xl sm:text-3xl">
                            <span className="font-extrabold text-gray-900">Diver Medical</span>
                            <span className="text-gray-900 whitespace-nowrap"> | Participant Questionnaire</span>
                        </h2>
                        <p className="mt-4 text-lg text-gray-600 text-justify">
                            Recreational scuba diving and freediving requires good physical and mental health. There are
                            a few medical conditions which can
                            be hazardous while diving, listed below. Those who have, or are predisposed to, any of these
                            conditions, should be evaluated by a
                            physician. This Diver Medical Participant Questionnaire provides a basis to determine if you
                            should seek out that evaluation. If you
                            have any concerns about your diving fitness not represented on this form, consult with your
                            physician before diving. If you are feeling
                            ill, avoid diving. If you think you may have a contagious disease, protect yourself and
                            others by not participating in dive training and/
                            or dive activities. References to “diving” on this form encompass both recreational scuba
                            diving and freediving. This form is principally
                            designed as an initial medical screen for new divers, but is also appropriate for divers
                            taking continuing education. For your safety,
                            and that of others who may dive with you, answer all questions honestly.
                        </p>
                        <h2 className="text-2xl sm:text-3xl text-center">
                            <span className="font-extrabold text-gray-900">Directions</span>
                        </h2>
                        <p className="text-lg text-gray-600 text-justify">
                            <span className="font-bold">
                            Complete this questionnaire as a prerequisite to a recreational scuba diving or freediving
                            course.
                            </span>
                            <br/>
                            <span className="font-bold">Note to women:</span> If you are pregnant, or attempting to
                            become pregnant, do not dive.
                        </p>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            {questions.map((question, i) => (
                                <div key={i} id={`question-${i}`}
                                     className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                                    <div className="text-lg text-gray-700">{question}</div>
                                    <span className="isolate inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => handleButtonChange(i, 'yes')}
                                        className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                            answers[i] === 'yes' ? 'text-white bg-red-500' : 'text-gray-900 bg-white'
                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-l-md`}
                                    >
                                      Yes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleButtonChange(i, 'no')}
                                        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                            answers[i] === 'no' ? 'text-white bg-green-500' : 'text-gray-900 bg-white'
                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-r-md`}
                                    >
                                      No
                                    </button>
                                  </span>
                                </div>
                            ))}
                            <div className="text-right">
                                <button type="submit"
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DiverMedicalForm;
