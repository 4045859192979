import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import SignatureBlock from "../components/SignatureBlock";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const featureFlags = instrDetails?.enabled_features?.[0] || {
        show_calendar: false,
        show_contact: false,
        instructor_portal: false,
        manage_calendar: false,
        show_course_signup: false
    };

    const navigation =
        {
            courses: (instrDetails?.courses ?? []).map(course => ({
                name: course.course_name,
                to: '/courses/' + course.course_slug
            })),
            website: [
                {name: 'Support', to: '/support'},
                {
                    name: 'Instructor SignUp',
                    to: 'https://www.instructorofscuba.com/prospective-instructor',
                    external: true
                },
                {name: 'Privacy Policy', to: '/privacy-policy'},
                {
                    name: 'Instructor Directory',
                    to: 'https://www.instructorofscuba.com/instructor-directory',
                    external: true
                },
                {name: 'Outstanding Forms', to: '/outstanding-forms'},
                {name: 'Outstanding Charges', to: '/outstanding-charges'},
                {name: 'Clear Data', to: '/clear-data'},
            ],
            instructor: [
                ...(featureFlags.show_contact ? [
                    {name: 'Contact', to: '/contact'}
                ] : []),
                {name: 'About', to: '/about'},
            ],
            forms: [
                {name: 'Diver Medical Form', to: '/forms/diver-medical-form'},
                {name: 'Diver Activities', to: '/forms/diver-activities-form'},
                {name: 'Liability Release', to: '/forms/liability-release-form'},
                {name: 'CEAD', to: '/forms/cead-form'},
                {name: 'Safe Diving', to: '/forms/safe-diving-form'},
            ],
        }

    const ClearData = (event) => {
        event.preventDefault();
        localStorage.clear();

        const cookies = document.cookie.split("; ");
        for (let c of cookies) {
            const d = c.indexOf("=");
            const name = d > -1 ? c.substring(0, d) : c;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
        window.location.reload();
    };

    return (
        <footer className="bg-gray-800" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <SignatureBlock instrDetails={instrDetails} theme='dark'/>
                    <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold leading-6 text-white">Courses</h3>
                                <ul className="mt-6 space-y-4">
                                    {navigation.courses.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.to}
                                                  className="text-sm leading-6 text-gray-300 hover:text-white">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm font-semibold leading-6 text-white">Instructor</h3>
                                <ul className="mt-6 space-y-4">
                                    {navigation.instructor.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.to}
                                                  className="text-sm leading-6 text-gray-300 hover:text-white">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold leading-6 text-white">Website</h3>
                                <ul className="mt-6 space-y-4">
                                    {navigation.website.map((item) => (
                                        <li key={item.name}>
                                            {item.name === 'Clear Data' ? (
                                                <a href="#" onClick={ClearData}
                                                   className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </a>
                                            ) : item.external ? (
                                                <a href={item.to} target="_blank" rel="noopener noreferrer"
                                                   className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </a>
                                            ) : (
                                                <Link to={item.to}
                                                      className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </Link>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm font-semibold leading-6 text-white">Forms</h3>
                                <ul className="mt-6 space-y-4">
                                    {navigation.forms.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.to}
                                                  className="text-sm leading-6 text-gray-300 hover:text-white">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
                    <p className="text-xs leading-5 text-gray-400">&copy; {currentYear} Powered by <a
                        className="hover:text-blue-600 underline" href="https://www.instructorofscuba.com"
                    >Instructor Of Scuba</a></p>
                </div>
            </div>
        </footer>
    )
}

export default (Footer);
