import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Divemaster",
    description: "Learn how to lead underwater tours, assist with scuba classes and inspire others to care about " +
        "the ocean. Hone your skills and be the diver everyone admires. PADI Divemaster is the most popular and " +
        "most recognized professional scuba certification in the world. PADI Divemasters enjoy abundant career " +
        "opportunities, get paid to scuba dive and share their love for the ocean. "
}

const faqs = [
    {
        question: "How long does it take to become a divemaster?",
        answer: "If you’re at least 18 years old and meet all the prerequisites, it’s possible to become a " +
            "PADI Divemaster in two very intense weeks. Most divemaster candidates complete their training over " +
            "three weeks to six months."
    },
    {
        question: "What are the PADI Divemaster swim test requirements?",
        answer: "There are five waterskills exercises. You must earn a combined score of 15, allowing strengths in " +
            "one exercise to compensate for weakness in another.  The first swim is a 400 meter nonstop swim, " +
            "without swimming aids and using any stroke or combination of strokes.  8:40 to 11 minutes is 3 points, " +
            "over 13 minutes is one point.  The second event is a 15-minute tread with hands out of the water during " +
            "the last two minutes. The third event is an 800 meter snorkel swim, no arms, in 16:30 to 18:30 minutes " +
            "for 3 points.  Over 21 minutes earns 1 point.  The fourth event is a 100 meter tired diver tow, " +
            "with 3:15 to 4:20 minutes earning 3 points and 1 point if over 5:30 minutes. " +
            "The final event is an Equipment Exchange, where you and another diver exchange equipment underwater."
    },
    {
        question: "What are divemasters allowed to do?",
        answer: "PADI Divemasters can assist certified PADI Instructors with training and non-training diving activities, " +
            "guide certified scuba divers on fun dives, lead Discover Local Diving programs, assist with Discover Scuba " +
            "Diving programs, condcut the PADI ReActivate scuba refresher program, teach Discover Snorkeling and PADI " +
            "Advanced Snorkeler courses, and organize and lead conservation activities. There are additional courses " +
            "that divemasters can teach with additional training."
    },
    {
        question: "What's the Difference Between a Master Scuba Diver and a Divemaster?",
        answer: "A Master Scuba Diver is a non-professional certification and represents the highest level of " +
            "recreational diving. A Divemaster is a professional rating, and Divemasters can work in the diving " +
            "industry supervising recreational diving activities and assisting with diving instruction."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Interactive lessons cover divemaster-led programs and activities, diver safety and risk management, " +
            "the aquatic environment and more. Study online or offline using a computer or mobile device. " +
            "Connect with your instructor whenever you have a question. Additional training materials are required " +
            "which your instructor will cover in detail."
    },
    {
        title: "Instructor-led Training",
        body:
            "Gain practical experience giving dive briefings, assisting with classes and leading underwater tours. " +
            "Learn how to anticipate problems and provide assistance, create an Emergency Assistance Plan and " +
            "improve your navigation and problem-solving skills through practical workshops."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "You will conduct several workshops in a pool or pool-like conditions including demonstrating mastery " +
            "of the 24 basic scuba skills, the waterskills exercises details in the FAQs, Discover Scuba Diving (DSD) " +
            "program, and assessed working with real students earning their Open Water certification."
    },
    {
        title: 'Ocean',
        body: "You will conduct several workshops and practical application assessments in the open ocean including " +
            "a deep dive, diver rescue, dive site setup and management, a mapping project, 10-point dive briefing, " +
            "search and recovery scenario, ReActivate program, Advanced Snorkeler course, DSD Open Water dive, " +
            "and Discover Local Diving program.  You will also be assessed working with real students in the Open Water " +
            "Diver Course, as well as continuing education students and certified divers."

    }
]

const progressionCourseCodes = ['PADI-OWSI'];

const requirements = [
    '18 years old',
    '60 logged dives (40 to begin training)',
    'Completed EFR Primary and SEcondary Care training within 24 months',
    'PADI Advaned Open Water Diver',
    'PADI Rescue Diver',
    'Medical clearance signed by a physician within 12 months'
];

const PadiDivemaster = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-DM"
        imageUrl='/images/PADI/DM_AltImages-2_Artbo-IGtYMt6J.png'
        courseSlug='/courses/padi-divemaster'
        requirements={requirements}
        boolDiverMedical={false}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Dive%20Master"
    />
);

export default PadiDivemaster;
