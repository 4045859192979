import PadiCourse from './PadiCourse';
import React from "react";

const PadiScubaDiver = () => (
    <PadiCourse
        product={{
            title: "PADI Scuba Diver Course",
            description: "The PADI Scuba Diver course is a subset of the PADI Open Water Diver course. " +
                "If you're short on time but really want to become a diver, the PADI Scuba Diver rating " +
                'might be right for you - particularly if you expect to go scuba diving primarily with a dive guide. ' +
                'This course is an intermediate step for earning an Open Water Diver certification, if ' +
                "that's your ultimate goal. "}}
        faqs={[
            {
                question: 'How long does it all take?',
                answer:
                    'About 4-5 days.  Plan on 2-3 days for academics, 1 day for confined water, and 1 day for ocean dives.'
            },
            {
                question: 'Can people with physical challenges learn to dive?',
                answer:
                    "Yes. People with missing limbs, reduced mobility, or other physical challenges commonly earn the PADI Open Water Diver certification. " +
                    "Even individuals with more significant physical or mental challenges can still take part in scuba experiences." +
                    "Use the Inquire button to ask your instructor about your specific situation." +
                    "All student divers complete a brief scuba medical questionnaire that asks about medical conditions that could be a problem while diving and will determine if you need to be evaluated by a physician before being able to scuba dive. " +
                    "If the doctor approves, or none of the conditions applied to you, you're ready to start!",
            },
            {
                question: 'Does a PADI scuba certification expire?',
                answer: "No. Once you are certified, you are certified for life.  " +
                    "However, if you haven't been diving in a while and wish to refresh your scuba safety knowledge and skills, the PADI ReActivate course will get you back up to speed."
            },
            {
                question: 'What about sharks?',
                answer: 'When you’re lucky, you get to see a shark. ' +
                    'Although incidents with sharks occur, they are very rare and, with respect to diving, primarily involve spear fishing or feeding sharks, both of which trigger feeding behavior. ' +
                    'Most of the time, if you see a shark it’s just passing through and a rare sight to enjoy.'
            },
            {
                question: 'How deep do we go?',
                answer: 'With the necessary training and experience, the limit for recreational scuba diving is 40 metres/130 feet. ' +
                    'PADI Scuba Divers stay shallower, about 12 metres/40 feet. ' +
                    'Completing the full Open Water Diver certification will allow you to dive to 18 metres/60 feet. ' +
                    'Although these are the limits, some of the most popular diving is shallower than 12 metres/40 feet, where the water’s warmer and the colors are brighter.'
            }
        ]}
        academics={[
            {
                title: 'eLearning',
                body:
                    "PADI eLearning makes it easy to fit scuba lessons into a busy schedule. " +
                    "Learn about scuba diving principles and terminology whenever, wherever it's convenient for you. " +
                    "It's your course on your time. Study offline, or online using a computer or mobile device. " +
                    "You'll need to complete the first three of five sections of the Open Water Diver course. " +
                    "Normally 5-10 hours of self study (for the full Open Water course). " +
                    "This is done at your own pace, but should be completed before in-water activities with your instructor.",
            },
            {
                title: 'Instructor-led Training',
                body:
                    "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
                    "This is done in a classroom setting and can be done in one day, but is typically broken into two half-day sessions. " +
                    "Use the Inquire button if you are interested in this option.",
            },
        ]}
        dives={[
            {
                title: 'Confined Water',
                body: "Confined Water means a body of water with pool-like conditions, such as a swimming pool or calm ocean. " +
                    "You will conduct three of the five confined water dives in the PADI Open Water Diver course. " +
                    "This is typically completed in one day, but keep in mind PADI courses are performance based. " +
                    "This means different divers may take different amounts of time to complete. "
            },
            {
                title: 'Ocean',
                body: "You will conduct two of the four ocean dives from the PADI Open Water Diver course. " +
                    "During these dives you will demonstrate mastery of the skills you learned in the confined water dives. " +
                    "These can be conducted in one day with a short surface interval between dives, and may even be " +
                    "conducted on the same day as the confined water dives."
            }

        ]}
        courseCode="PADI-SCUBA"
        imageUrl='/images/PADI/padi-scuba-diver_0.jpg'
        courseSlug='/courses/padi-scuba-diver'
        requirements={['10 years old', 'Be able to tread water for 10 minutes']}
        boolDiverMedical={true}
        progressionCourseCodes={['PADI-OW', 'PADI-DLD']}
        urlText="PADI%20Scuba%20Diver%20Course"
    />
);

export default PadiScubaDiver;
