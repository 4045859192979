import { createSlice } from '@reduxjs/toolkit'

export const visitedCoursesSlice = createSlice({
    name: 'visitedCourses',
    initialState: [],
    reducers: {
        addVisitedCourse: (state, action) => {
            if (state[state.length -1] !== action.payload) {
                state.push(action.payload)
            }
        },
    },
})

export const { addVisitedCourse } = visitedCoursesSlice.actions

export default visitedCoursesSlice.reducer
