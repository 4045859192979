import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SignatureBlock from "../components/SignatureBlock";
import {getImageUrl} from "../utils/sanitizeFunctions";

const AboutInstructor = () => {
    const selectedInstructorId = useSelector((state) => state.instructors.selectedInstructorId);
    const instructors = useSelector((state) => state.instructors.instructors);
    const instr = instructors.find(instr => instr.instr_id === selectedInstructorId);

    const instrPremiumDomain = (instr) => {
        // console.log(instr);
        if (!instr?.domain_pks || instr.domain_pks.length === 0) {
            return null;  // No domains, this is free profile
        }
        const validDomains = instr.domain_pks.filter(domain => domain);
        if (validDomains.length === 0) {
            return null;  // No valid domains left after filtering
        }

        const nonStandardDomain = instr.domain_pks.find(domain => !domain.endsWith('instructorofscuba.com') && !domain.includes('localhost'));
        return nonStandardDomain || instr.domain_pks.find(domain => domain.endsWith('instructorofscuba.com') && !domain.includes('localhost')) || null;
    }

    const isValidDomain = (domain) => typeof domain === 'string' && domain.trim().length > 0;

    const getPrimaryProLevelInfo = (proLevelInfo) => {
        return proLevelInfo ? proLevelInfo.find(info => info.foremost) || {} : {};
    }

    const primaryProLevelInfo = getPrimaryProLevelInfo(instr?.pro_level_info);

    const instrWithAdditionalFields = {
        ...instr,
        organization: primaryProLevelInfo.organization,
        pro_level: primaryProLevelInfo.pro_level,
        member_number: primaryProLevelInfo.member_number,
    };

    const premiumDomain = instrPremiumDomain(instr);

    const instrOrgMemNum = primaryProLevelInfo ? `${primaryProLevelInfo.organization}-${primaryProLevelInfo.member_number}` : null;
    const [instrImage, setInstrImage] = useState('/images/portraits/unknown_male.png');
    const navigate = useNavigate();

    useEffect(() => {
        if (!instr || !instr.first_name || !instr.last_name) {
            navigate('/instructor-directory');
        }
    }, [instr, navigate]);

    useEffect(() => {
        if (instrOrgMemNum) {
            setInstrImage(getImageUrl(instrOrgMemNum + '.jpeg'));
        }
    }, [instrOrgMemNum])

    if (!instr) {
        return null;
    }

    return (
        <>
            <section className="bg-gray-100">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="col-span-2">
                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl pb-2">{instr.first_name} {instr.last_name}</h2>
                            <span className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                                {instr.pro_level_info.map((info, index) => (
                                    <p key={index} className="text-xl font-bold text-gray-800">
                                        {`${info.organization} ${info.pro_level} ${info.member_number ? '#' + info.member_number : ''}`}
                                    </p>
                                ))}
                            </span>
                            {instr && Array.isArray(instr.about_me) && instr.about_me.map((para, index) => (
                                <p key={index} className="mt-4 text-gray-600 text-lg">{para}</p>
                            ))}
                        </div>
                        <div className="col-span-1">
                            <div className="mb-8">
                                <img
                                    className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                                    src={instrImage}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "/images/portraits/unknown_male.png"
                                    }}
                                    alt="Instructor Portrait"
                                />
                            </div>
                            <div>
                                <SignatureBlock instrDetails={instrWithAdditionalFields} theme='light'/>
                            </div>
                            <div>
                                {isValidDomain(premiumDomain) && (
                                    <button
                                        onClick={() => window.open(`https://${premiumDomain}`, '_blank', 'noopener,noreferrer')}
                                        className="w-full mt-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Visit Website
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutInstructor;
