import { Carousel, Typography, Button } from "@material-tailwind/react";
import {useEffect, useState} from "react";

export default function HeroCarouselPADI() {
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        });
    }, []);

    return (
        <Carousel autoplay={false} autoplayDelay={10000} loop={true} className="rounded-xl">
            <div className="relative h-full w-full">
                <img
                    src={isPortrait ? "/images/PADI/hero-7p.jpeg" : "/images/PADI/hero-1.jpeg"}
                    alt="Divers underwater with school of fish"
                    className="h-full w-full object-cover blur-3xl"
                />
                <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/50">
                    <div className="w-full text-center md:w-2/4 md:text-left">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl font-padi blur-3xl"
                        >
                            Explore the Underwater World Together
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80 font-padi blur-3xl"
                        >
                            With your dive certification, you can explore the underwater world and open the door to unlimited adventure.  Change your life!  Become a diver.
                        </Typography>
                        {/*<div className="flex justify-center md:justify-start gap-2">*/}
                        {/*    <Button size="lg" color="white" className="font-padi">*/}
                        {/*        Sign Up*/}
                        {/*    </Button>*/}
                        {/*    <Button size="lg" color="white" variant="text" className="font-padi">*/}
                        {/*        Learn More*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src={isPortrait ? "/images/PADI/hero-1p.jpeg" : "/images/PADI/hero-7.jpeg"}
                    alt="Diver smiling on the beach"
                    className="h-full w-full object-cover blur-3xl"
                />
                <div className="absolute inset-0 grid h-full w-full items-center bg-black/50">
                    <div className="w-full text-center md:w-2/4 md:text-left md:pl-6 lg:pl-32 lg:pb-32">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl font-padi blur-3xl"
                        >
                            Find Your Next Course
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80 font-padi"
                        >
                            Dive deeper into blue planet exploration with advanced dive skills and specialty scuba certifications. Whether you want to expand your dive knowledge or experience a new scuba specialty, our courses will prepare you for underwater adventure.
                        </Typography>
                        <div className="flex justify-center md:justify-start gap-2 font-padi">
                            <Button size="lg" color="white" className="font-padi">
                                Sign Up
                            </Button>
                            <Button size="lg" color="white" variant="text" className="font-padi">
                                Learn More
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                    src={isPortrait ? "/images/PADI/hero-6p.jpeg" : "/images/PADI/hero-6.jpeg"}
                    alt="Scuba instructor helping out students"
                    className="h-full w-full object-cover blur-3xl"
                />
                <div className={`absolute inset-0 grid h-full w-full ${isPortrait ? 'place-items-center' : 'items-end'} bg-black/50`}>
                    <div className="w-full px-4 pb-12 md:w-4/6 md:pl-6 md:pb-20 lg:pl-32 lg:pb-32">
                        <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl font-padi blur-3xl"
                        >
                            Become a PADI Professional
                        </Typography>
                        <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80 font-padi blur-3xl"
                        >
                            There are many reasons to become a PADI® Divemaster or Scuba Instructor: to work and travel, to share your love of the underwater world with others, or simply for the personal challenge. Along the way, you’ll hone your scuba diving skills and emerge as a leader in the world’s largest community of divers bound together by a shared passion for adventure and ocean conservation.
                        </Typography>
                        <div className="flex justify-center md:justify-start gap-2 font-padi">
                            <Button size="lg" color="white" className="font-padi">
                                Sign Up
                            </Button>
                            <Button size="lg" color="white" variant="text" className="font-padi">
                                Learn More
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    );
}