export const myCustomAuthTheme = {
    name: 'myCustomAuthTheme',
    tokens: {
        colors: {
            font: {interactive: {value: '#4f46e5'}},
            brand: {
                primary: {
                    '10': '#a5b4fc',
                    '80': '#4f46e5',
                    '90': '#4338ca',
                },
            },
        },
        components: {
            tabs: {
                item: {
                    _focus: {color: {value: '#4f46e5'}},
                    _hover: {color: {value: '#4338ca'}},
                },
            },
            button: {
                background: {color: {value: '#4f46e5'}}
            }
        }
    }
};

export const debounce = (func, delay) => {
    let inDebounce;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
}

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) {
            resolve(null);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
    });
};

export const checkOrgExists = (instrDetails, org) => {
    return instrDetails.pro_level_info.some((info) => {
        return info.pro_level_organization === org;
    });
}




