import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import OrderSummary from "../components/OrderSummary";
import StripeCheckout from "./StripeCheckout";
import {fetchSubDiscountDetails} from "../redux/instrSubscriptionSlice";
import {useNavigate} from "react-router-dom";
import GenericModal from "../components/GenericModal";

const SubscriptionGateway = () => {
    const owner_instr_id = 1;
    const selectedPlan = useSelector(state => state.instrSubscription.selectedPlan);
    const selectedTierId = selectedPlan ? selectedPlan.id : null;
    const [errorInfo, setErrorInfo] = useState(null);
    const paymentGateway = useSelector(state => state.instrSubscription.paymentGateway);
    const discountCodes = paymentGateway.discountCodes;
    const discountCodeRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const products = paymentGateway.products;
    const discountStatus = paymentGateway.discountStatus;
    const [showModal, setShowModal] = useState(false);
    const modalContent = paymentGateway.modalContent;

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleApplyDiscount = async () => {
        const discount_code = discountCodeRef.current.value;
        const productIds = products.map(product => product.id);
        dispatch(fetchSubDiscountDetails({instr_id: owner_instr_id, code: discount_code, productIds}));
        discountCodeRef.current.value = '';
    };

    const handleRemoveClick = () => {
        navigate('/manage-subscription');
    }

    useEffect(() => {
        if (discountStatus === 'failed') {
            setShowModal(true);
        }
    }, [discountStatus]);

    useEffect(() => {
        if (errorInfo) {
            throw errorInfo;
        }
    }, [errorInfo]);

    const closeModal = () => {
        setShowModal(false);
    };

    const buttonActions = {
        close: closeModal
    };

    return (
        <>
            <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
                {showModal && (
                    <GenericModal
                        isOpen={showModal}
                        closeModal={closeModal}
                        modalContent={modalContent}
                        buttonActions={buttonActions}
                    />
                )}
                <h1 className="sr-only">Checkout</h1>
                <OrderSummary
                    gatewaySlice={paymentGateway}
                    currencyFormatter={currencyFormatter}
                    handleApplyDiscount={handleApplyDiscount}
                    handleRemoveClick={handleRemoveClick}
                    discountCodeRef={discountCodeRef}
                />
                <div className="relative mt-8">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200"/>
                    </div>
                </div>
                <StripeCheckout tierId={selectedTierId} discountCodes={discountCodes}/>
            </main>
        </>
    )
}

export default SubscriptionGateway;
