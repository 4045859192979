import React, {Fragment, useEffect, useState} from 'react';
import { Dialog, Transition } from '@headlessui/react';

const EditCertModal = ({ isOpen, closeModal, cert, onSave, onDelete, isEditing, courseOptions }) => {
    const [courseCode, setCourseCode] = useState(cert.course_code || '');
    const [certNumber, setCertNumber] = useState(cert.cert_number || '');
    const [certDate, setCertDate] = useState(cert.cert_date || '');
    const [equivalency, setEquivalency] = useState(cert.equivalency || false);
    const [verified, setVerified] = useState(cert.verified || false);

    useEffect(() => {
        if (cert) {
            setCourseCode(cert.course_code || '');
            setCertNumber(cert.cert_number || '');
            setCertDate(cert.cert_date || '');
            setEquivalency(cert.equivalency || false);
            setVerified(cert.verified || false);
        }
    }, [cert]);

    const handleSave = () => {
        const updatedCert = {
            ...cert,
            course_code: courseCode,
            cert_number: certNumber,
            cert_date: certDate,
            equivalency: equivalency,
            verified: verified
        };
        onSave(updatedCert);
        closeModal();
    };

    const handleDelete = () => {
        onDelete(cert);
        closeModal();
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            {isEditing ? 'Edit Certification' : 'Add Certification'}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <form className="space-y-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Course
                                                        Code</label>
                                                    {isEditing ? (
                                                        <input
                                                            type="text"
                                                            value={courseCode}
                                                            onChange={(e) => setCourseCode(e.target.value)}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            disabled
                                                        />
                                                    ) : (
                                                        <select
                                                            value={courseCode}
                                                            onChange={(e) => setCourseCode(e.target.value)}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        >
                                                            <option value="" disabled>Select Course Code</option>
                                                            {courseOptions.map((option) => (
                                                                <option key={option.value}
                                                                        value={option.value}>{option.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Certification
                                                        Number</label>
                                                    <input
                                                        type="text"
                                                        value={certNumber}
                                                        onChange={(e) => setCertNumber(e.target.value)}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Certification
                                                        Date</label>
                                                    <input
                                                        type="date"
                                                        value={certDate}
                                                        onChange={(e) => setCertDate(e.target.value)}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        className="block text-sm font-medium text-gray-700">Equivalency</label>
                                                    <input
                                                        type="checkbox"
                                                        checked={equivalency}
                                                        onChange={(e) => setEquivalency(e.target.checked)}
                                                        className="mt-1"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        className="block text-sm font-medium text-gray-700">Verified</label>
                                                    <input
                                                        type="checkbox"
                                                        checked={verified}
                                                        onChange={(e) => setVerified(e.target.checked)}
                                                        className="mt-1"
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:flex sm:items-center sm:justify-between">
                                    {isEditing ? (
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        <div className="w-20"></div>
                                    )}
                                    <div className="ml-auto sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default EditCertModal;
