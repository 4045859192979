import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "Emergency First Response Primary and Secondary Care",
    description: "Emergency First Response® primary and secondary care training teaches you what to do in the critical moments between when a life-threatening emergency occurs and when emergency medical services arrive."
}

const faqs = [
    {
        question: "What is covered in the Emergency First Response Primary and Secondary Care course?",
        answer: "This course covers basic training for cardiopulmonary resuscitation (CPR) and emergency care. It is a comprehensive program that teaches participants how to respond to life-threatening emergencies."
    },
    {
        question: "Who is the course designed for?",
        answer: "The course is intended for anyone interested in acquiring first aid and CPR skills. No prior medical training is required, making it suitable for the general public.  It is also a prerequisite for the PADI Rescue Diver course."
    },
    {
        question: "What practical skills will I learn in the course?",
        answer: "Participants will learn a variety of skills including how to perform CPR, use an automated external defibrillator (AED), manage shock, control bleeding, and apply bandages."
    },
    {
        question: "What is the difference between Primary Care and Secondary Care in this context?",
        answer: "Primary Care (CPR) deals with life-threatening emergencies that require immediate action, while Secondary Care (First Aid) focuses on injuries or illnesses that are not immediately life-threatening."
    }
]

const academics = [
    {
        title: 'eLearning',
        body: "Online training makes it easy to fit a CPR and first aid course into a busy schedule. Watch videos of different first aid techniques and learn what steps to take in an emergency. Study online or offline, using a computer or mobile device. Connect with your instructor if you have questions."
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "This is done in a classroom setting and typically takes 2-4 hours depending on how much you study in advance. " +
            "Use the Inquire button if you are interested in this option.",
    }
]

const dives = [
    {
        title: 'Not Applicable',
        body: "There are no dives for this course."
    }
]

const progressionCourseCodes = ['PADI-OXYGEN', 'EFR-I'];

const requirements = [
    'No minimum age requirement',
    'None, just a desire to help others'
];

const EfrPrimSecCare = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="EFR-PSCARE"
        imageUrl='/images/PADI/EFR-Gallery-Hero-1(2-UNG2ieFq.jpg'
        courseSlug='/courses/efr-primary-and-secondary-care'
        requirements={requirements}
        boolDiverMedical={false}
        progressionCourseCodes={progressionCourseCodes}
        urlText="EFR%20Primary%20and%20Secondary%20Care"
    />
);

export default EfrPrimSecCare;
