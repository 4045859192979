import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingSpinner} from "./LoadingSpinner";
import {useParams} from "react-router-dom";
import {fetchTransactions} from "../redux/transactionsSlice";
import OrderSummary from "../components/OrderSummary";
import {decode as base64Decode} from 'base-64';
import TransactionInstrForm from "./TransactionInstrForm";
import ResultModal from "./ResultModal";
import {post, get} from "aws-amplify/api";


const TransactionDetails = () => {
    const {id} = useParams();
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id);
    const dispatch = useDispatch();
    const {transactions, status, error} = useSelector((state) => state.transactions);
    const [transaction, setTransaction] = useState(null);
    const [subtotal, setSubtotal] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [discountCodes, setDiscountCodes] = useState('');
    const [total, setTotal] = useState(0);
    const [resultModal, setResultModal] = useState({show: false, type: '', title: '', body: ''});
    const discountCodeRef = useRef(null);

    const closeModal = () => {
        setResultModal({show: false, type: '', title: '', body: ''});
    };

    const normalizeTransaction = (transaction) => {
        return transaction.user_charges.map((charge) => ({
            id: `${charge.entity_type}-${charge.ref_id}`,
            charge_id: parseInt(base64Decode(transaction.invoice_number)),
            title: charge.title,
            price: charge.price,
            imageSrc: charge.imageSrc,
            imageAlt: charge.imageAlt,
            discount: charge.discount || {code: null, type: null, value: 0},
            shipping: charge.shipping,
            taxes: charge.taxes,
            total: charge.total,
            subtitle1: charge.subtitle1 || '',
            subtitle2: charge.subtitle2 || '',
        }));
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleFormSubmit = async (formData) => {
        const updatedTransaction = {
            ...transaction,
            payments: [...transaction.payments, formData]
        };

        try {
            const apiName = 'InstrOfScubaAPI'
            const path = '/save-transaction'
            const options = {
                method: 'POST',
                body: {
                    total_amt: formData.amountPaid,
                    total_products_amt: subtotal,
                    status: formData.paymentStatus,
                    payment_method: formData.paymentMethod,
                    beneficiary_instr_id: formData.instrId,
                    discounts: transaction.discount,
                    discounted_total: transaction.discounted_total,
                    shipping_amt: shipping,
                    tax_amt: taxes,
                    user_charge_ids: transaction.user_charge_ids,
                    product_ids: transaction.product_ids,
                    invoice_number: formData.invoiceNumber,
                    payment_dtg: formData.paymentDTG
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            if (data?.failed) {
                console.error(data?.failed)
            } else {
                setTransaction(updatedTransaction);
            }
        } catch (error) {
            console.error("Error updating transaction:", error);
        }
    };

    const handleRemoveClick = (productId) => {
        if (!transaction) return;

        const updatedProducts = transaction.user_charges.filter(product => {
            const productUniqueId = `${product.entity_type}-${product.ref_id}`;
            return productUniqueId !== productId;
        });

        const updatedTransaction = {
            ...transaction,
            user_charges: updatedProducts,
            total_products_amt: updatedProducts.reduce((acc, product) => acc + product.price, 0),
            tax_amt: updatedProducts.reduce((acc, product) => acc + (product.price * product.taxRate), 0),
            total_amt: updatedProducts.reduce((acc, product) => acc + product.price, 0) + taxes + shipping
        };
        setTransaction(updatedTransaction);
        updateTransactionTotals(updatedTransaction);
    };

    // useEffect(() => {
    //     console.log(transaction);
    // }, [transaction]);

    const totalDiscountValue = transaction ? transaction.user_charges.reduce((acc, product) => {
        if (product && product.discount) {
            if (product.discount.type === 'percentage') {
                const rate = parseFloat(product.discount.value);  // "0.90" -> 0.90
                if (!isNaN(rate)) {
                    return acc + (product.price * rate);
                }
            } else if (product.discount.type === 'fixed') {
                const amount = parseFloat(product.discount.amount);
                if (!isNaN(amount)) {
                    return acc + amount;
                }
            }
        }
        return acc;
    }, 0) : 0;

    const handleApplyDiscount = async () => {
        const discount_code = discountCodeRef.current.value;
        const productIds = transaction.user_charges.map(charge => `${charge.entity_type}-${charge.ref_id}`);

        try {
            const apiName = 'InstrOfScubaAPI'
            const path = '/validate-discount-code'
            const options = {
                method: 'GET',
                body: {discount_code: discount_code, instr_id: instr_id, product_ids: productIds},
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            const {body} = await get({apiName, path, options}).response;
            const data = await body.json();
            const {type, discount_value, discount_type, product_id, code} = data;
            if (type === 'success') {
                const updatedUserCharges = transaction.user_charges.map(charge => {
                    if (`${charge.entity_type}-${charge.ref_id}` === product_id) {
                        const discount_value_float = parseFloat(discount_value);
                        return {
                            ...charge,
                            discount: {
                                type: discount_type,
                                value: Math.round(discount_value_float * 100),
                                code: code
                            }
                        };
                    }
                    return charge;
                });

                const updatedTransaction = {
                    ...transaction,
                    user_charges: updatedUserCharges,
                    discount_codes: [...transaction.discount_codes, code],
                    discount_amt: totalDiscountValue.toFixed(2)
                };

                setTransaction(updatedTransaction);
                updateTransactionTotals(updatedTransaction);

            } else {
                setResultModal({
                    show: true,
                    type: 'error',
                    title: data.title,
                    body: data.message
                });
            }

            discountCodeRef.current.value = '';

        } catch (error) {
            setResultModal({
                show: true,
                type: 'error',
                title: 'Error Applying Discount',
                body: 'An error occurred while applying the discount code. Please try again.'
            });
        }
    };

    // const calculateDiscountedPrice = (product) => {
    //     let price = parseFloat(product.price);
    //
    //     if (product.discount) {
    //         if (product.discount.type === 'percentage') {
    //             price = price - (price * (parseFloat(product.discount.value) / 100));
    //         } else if (product.discount.type === 'fixed') {
    //             price = price - parseFloat(product.discount.value);
    //         }
    //     }
    //
    //     return price
    // };

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchTransactions());
        } else if (status === 'succeeded') {
            const foundTransaction = transactions.find(t => t.transaction_id === parseInt(id, 10));
            if (foundTransaction) {
                setTransaction(foundTransaction);
                updateTransactionTotals(foundTransaction);

                const discountCodes = Array.isArray(foundTransaction.discount_codes)
                    ? foundTransaction.discount_codes.join(', ') : '';
                setDiscountCodes(discountCodes);
            }
        }
    }, [status, dispatch, transactions, id]);

    useEffect(() => {
        if (status === 'failed') {
            throw new Error(`/get-transactions\n${error}`);
        }
    }, [status, error]);

    const updateTransactionTotals = (transaction) => {
        console.log('updateTransactionTotals');
        const updatedSubtotal = transaction.user_charges.reduce((acc, product) => acc + parseFloat(product.price), 0);
        const updatedTaxes = transaction.user_charges.reduce((acc, product) => acc + parseFloat(product.taxes || 0), 0);

        const calculatedTotalDiscountValue = transaction.user_charges.reduce((acc, product) => {
            if (product && product.discount) {
                if (product.discount.type === 'percentage') {
                    const rate = parseFloat(product.discount.value);
                    if (!isNaN(rate)) {
                        return acc + (product.price * rate);  // Use rate directly as it's already in decimal form
                    }
                } else if (product.discount.type === 'fixed') {
                    const amount = parseFloat(product.discount.value);  // Changed 'amount' to 'value' to match the field name
                    if (!isNaN(amount)) {
                        return acc + amount;
                    }
                }
            }
            return acc;
        }, 0);

        const updatedTotal = updatedSubtotal + updatedTaxes + parseFloat(transaction.shipping_amt) - calculatedTotalDiscountValue;

        setSubtotal(updatedSubtotal);
        setTaxes(updatedTaxes);
        setShipping(parseFloat(transaction.shipping_amt));
        setTotal(updatedTotal);
    };

    // useEffect(() => {
    //     console.log(updatedTotal)
    // }, [updatedTotal]);

    return (
        <div className="flex flex-col lg:flex-row lg:space-x-8">
            <div className="flex-1 bg-gray-50 rounded-2xl">
                <LoadingSpinner isLoading={status === 'loading'}/>
                <OrderSummary products={transaction ? normalizeTransaction(transaction) : []}
                              subtotal={subtotal}
                              taxes={taxes}
                              shipping={shipping}
                              discountCodes={discountCodes}
                              total={total}
                              currencyFormatter={currencyFormatter}
                              totalDiscountValue={totalDiscountValue}
                              handleApplyDiscount={handleApplyDiscount}
                              handleRemoveClick={handleRemoveClick}
                              discountCodeRef={discountCodeRef}
                />
            </div>
            <div className="flex-1 bg-gray-50 rounded-2xl">
                {transaction && (
                    <TransactionInstrForm
                        onSubmit={handleFormSubmit}
                        transactionId={transaction.transaction_id}
                        instrId={instr_id}
                        total={total}
                        currencyFormatter={currencyFormatter}
                        transaction={transaction}
                    />
                )}
            </div>
            {resultModal.show && <ResultModal result={resultModal} closeModal={closeModal} />}
        </div>
    );
};

export default TransactionDetails;
