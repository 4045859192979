import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// import EditCertModal from '../components/EditCertModal';
// import {fetchCourses} from "../redux/coursesSlice";
// import {post} from "aws-amplify/api";
import {LoadingSpinner} from "./LoadingSpinner";
import {fetchStudents} from "../redux/studentsSlice";
// import { FaRegEdit } from "react-icons/fa";


const StudentCourses = () => {
    const {id} = useParams();
    const student = useSelector((state) =>
        state.students.studentList.find((student) => student.id === parseInt(id))
    );
    const courses = student?.course_registrations;
    const studentsStatus = useSelector((state) => state.students.status);
    const [errorInfo, setErrorInfo] = useState(null);
    const dispatch = useDispatch();

    if (!student) {
        if (studentsStatus === 'loading') {
            return <LoadingSpinner isLoading={studentsStatus === 'loading'}/>
        } else if (studentsStatus === 'idle') {
            dispatch(fetchStudents());
        } else {
            return <div>Student not found</div>;
        }
    }

    const handleAddClick = () => {
        // setSelectedCert({
        //     course_code: '',
        //     cert_number: '',
        //     cert_date: '',
        //     equivalency: false,
        //     verified: false
        // });
        // setIsEditing(false);
        // setIsModalOpen(true);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard')
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    if (errorInfo) {
        console.log(errorInfo);
        throw errorInfo;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Course Enrollments</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        'Reg' refers to Registration, i.e. when they were signed up on your website. Payment is payment
                        from student to instructor/shop. Invoice refers to
                        the invoice you submit to your shop as an employee or independent contractor, not an invoice
                        between you and the student. The students payment status is shown in the 'Payment' column.
                        Pro-Tips: Click on the student's name or email to copy it to your clipboard. Copy a C-Card
                        number to the clipboard and click "Not Submitted" to paste it in. Click other fields to
                        toggle their value or display additional options.
                    </p>
                    <h1 className="mt-4 text-base font-semibold leading-6 text-gray-900">
                        <button
                            onClick={() => copyToClipboard(student?.first_name)}
                            className="mt-4 text-base font-semibold leading-6 text-gray-900"
                        >
                            {student?.first_name}
                        </button>
                        &nbsp;
                        {student?.middle_init ? student?.middle_init + ' ' : ''}
                        <button
                            onClick={() => copyToClipboard(student?.last_name)}
                            className="mt-4 text-base font-semibold leading-6 text-gray-900"
                        >
                            {student?.last_name}
                        </button>

                    </h1>
                    <button
                        onClick={() => copyToClipboard(student?.preferred_email)}
                        className="mt-2 text-sm text-gray-700"
                    >
                        {student?.preferred_email}
                    </button>
                    <p className="mt-2 text-sm text-gray-700">DOB: {student?.date_of_birth}</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        onClick={handleAddClick}
                        className="mb-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Enroll Course
                    </button>
                </div>
            </div>
            <div className="mx-1 mt-8 sm:-mx-0">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Course Code
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Reg Status
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                            Reg Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Payment
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Forms
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Academics
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Dives
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            C-Card
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Invoice
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                    {courses?.map((course) => (
                        <tr key={course.course_code + course.registration_date}>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                {course.course_code}
                                <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">Registration Status</dt>
                                    <dd className="mt-1 truncate text-gray-700">{course.registration_status}</dd>
                                    <dt className="sr-only sm:hidden">Registration Date</dt>
                                    <dd className="mt-1 truncate text-gray-500 sm:hidden">{course.registration_date}</dd>
                                </dl>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell">{course.registration_status}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell">{course.registration_date}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{course.payment_receipt_num ? course.payment_receipt_num : course.payment_status}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{course.forms_complete ? "Complete" : "Incomplete"}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell">
                                {course.academics_complete
                                    ? "Complete"
                                    : (course.academics_type
                                        ? "Incomplete (" + course.academics_type + ")"
                                        : "Method TBD")}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell">{course.dives_complete ? "Complete" : "Incomplete"}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{course.c_card_num ? course.c_card_num : course.c_card_status}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{course.invoice_number ? course.invoice_number : "Not Submitted"}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StudentCourses;
