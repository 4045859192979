import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import {useNavigate} from "react-router-dom";
import {setLastPage} from "../../redux/navSlice";
import {post} from "aws-amplify/api";
import ResultModal from "../ResultModal";

const CeadForm = () => {
    const navigate = useNavigate();
    const loginEmail = useSelector((state) => state.auth.loginEmail);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const [formData, setFormData] = useState({
        form_short_name: 'CEAD',
        diver_name: '',
        signature_date: ''
    });

    useEffect(() => {
        if (userDetails) {
            setFormData({
                ...formData,
                diver_name: userDetails.first_name + ' ' + userDetails.last_name,
            });
        }
    }, [userDetails, dispatch]);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const sigCanvas = useRef(null);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const checkboxElement = document.getElementById('eSignConsent');
        if (e.target.checked) {
            checkboxElement.classList.remove('border-red-500');
            checkboxElement.classList.add('border-gray-300');
        } else {
            checkboxElement.classList.add('border-red-500');
            checkboxElement.classList.remove('border-gray-300');
        }
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });

    const closeModal = () => {
        setShowModal(false);
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            document.getElementById(`sigCanvas`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`sigCanvas`).classList.add('border-red-500');
            document.getElementById(`sigCanvas`).classList.remove('border-gray-500');
            return;
        } else {
            document.getElementById(`sigCanvas`).classList.add('border-gray-500');
            document.getElementById(`sigCanvas`).classList.remove('border-red-500');
        }

        if (!formData.signature_date) {
            document.getElementById(`signature_date`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`signature_date`).classList.add('border-red-500');
            return;
        }

        if (!isCheckboxChecked) {
            document.getElementById(`eSignConsent`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`eSignConsent`).classList.add('border-red-500');
            return;
        }

        if (sigCanvas.current) {
            const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setIsSubmitting(true);

            const dataToSend = {
                formData,
                signature,
                instr_id: instrDetails.instr_id,
                instr_full_name: instrDetails.first_name + ' ' + instrDetails.last_name,
                instr_email: instrDetails.preferred_email,
                form_slug: "cead-form",
                student_login_email: loginEmail,
                student_preferred_email: userDetails.preferred_email,
                store_resort: instrDetails.store_resort
            }

            const apiName = 'InstrOfScubaAPI'
            const path = '/esign'
            const options = {
                method: 'POST',
                body: dataToSend,
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = JSON.parse(body);
                if (data.success) {
                    setResultModalObj({
                        type: 'success',
                        title: 'Document Submitted!',
                        body: 'Your document has been signed, sealed, and delivered.'
                    });
                    setShowModal(true);
                }
            } catch (error) {
                if (error.response && error.response.status === 504) {
                    // Handling specific 504 Gateway Timeout error
                    setResultModalObj({
                        type: 'error',
                        title: 'Timeout Error',
                        body: 'The process is taking longer than expected and may or may not complete successfully.  ' +
                            'Please check your email for confirmation.  If not received, please contact support.'
                    });
                    setShowModal(true);
                } else {
                    setErrorInfo('handleSubmit - /esign\n' + error);
                }
            }
            setIsSubmitting(false);
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                canvas.width = canvas.offsetWidth;
                canvas.height = canvas.offsetHeight;
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/signin', {state: {redirectPath: window.location.pathname}});
    };

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    useEffect(() => {
        console.log(errorInfo);
        if (errorInfo) {
            throw errorInfo;
        }
    }, [errorInfo])

    return (
        <>
            <section className="bg-gray-100 font-sans leading-normal">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <img src="/images/logos/padi-vert-color.png" alt="PADI Logo" className="float-left mr-4 mb-4 max-w-xs" />
                        <h2 className="text-2xl sm:text-2xl font-bold text-gray-900 mb-1">
                            Release of Liability / Assumption of Risk / Non-agency Acknowledgement Form
                        </h2>
                        <h1 className="text-3xl sm:text-3xl font-extrabold text-gray-900 mb-2">Continuing Education
                            Administrative Document</h1>
                        <p className="text-lg text-gray-600">NOTE: Also complete and attach the Diver Medical Form
                            (Product No. 10346)</p>
                    </div>
                    {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="mt-4 mb-4 text-lg text-gray-600 text-justify">
                            <p className="mb-4">
                                This is a statement in which you are informed of the established
                                safe diving practices for skin and scuba diving. These practices
                                have been compiled for your review and acknowledgment and
                                are intended to increase your comfort and safety in diving.
                                Your signature on this statement is required as proof that you
                                are aware of these safe diving practices. Read and discuss the
                                statement prior to signing it. If you are a minor, this form must
                                also be signed by a parent or guardian.
                            </p>
                            <p className="mb-4">
                                I, {formData.diver_name}, understand that as a diver I should:
                            </p>
                            <div>
                                <ol className="list-decimal list-inside space-y-2">
                                    <li>
                                        Maintain good mental and physical fitness for diving. Avoid being under the
                                        influence of alcohol or dangerous drugs when diving. Keep proficient in diving
                                        skills, striving to increase them through continuing education and reviewing
                                        them in controlled conditions after a period of diving inactivity, and refer to
                                        my course materials to stay current and refresh myself on important information.
                                    </li>
                                    <li>
                                        Be familiar with my dive sites. If not, obtain a formal diving orientation from
                                        a knowledgeable, local source. If diving conditions are worse than those in
                                        which I am experienced, postpone diving or select an alternate site with better
                                        conditions. Engage only in diving activities consistent with my training and
                                        experience. Do not engage in cave or technical diving unless specifically
                                        trained to do so.
                                    </li>
                                    <li>
                                        Use complete, well-maintained, reliable equipment with which I am familiar; and
                                        inspect it for correct fit and function prior to each dive. Have a buoyancy
                                        control device, low-pressure buoyancy control inflation system, submersible
                                        pressure gauge and alternate air source and dive planning/monitoring device
                                        (dive computer, RDP/dive tables—whichever you are trained to use) when scuba
                                        diving. Deny use of my equipment to uncertified divers.
                                    </li>
                                    <li>
                                        Listen carefully to dive briefings and directions and respect the advice of
                                        those supervising my diving activities. Recognize that additional training is
                                        recommended for participation in specialty diving activities, in other
                                        geographic areas and after periods of inactivity that exceed six months.
                                    </li>
                                    <li>
                                        Adhere to the buddy system throughout every dive. Plan
                                        dives – including communications, procedures for reuniting
                                        in case of separation and emergency procedures – with my
                                        buddy.
                                    </li>
                                    <li>Be proficient in dive planning (dive computer or dive table
                                        use). Make all dives no decompression dives and allow a
                                        margin of safety. Have a means to monitor depth and time
                                        underwater. Limit maximum depth to my level of training and
                                        experience. Ascend at a rate of not more than 18 metres/60
                                        feet per minute. Be a SAFE diver – Slowly Ascend From Every
                                        dive. Make a safety stop as an added precaution, usually at 5
                                        metres/15 feet for three minutes or longer.
                                    </li>
                                    <li>Maintain proper buoyancy. Adjust weighting at the surface
                                        for neutral buoyancy with no air in my buoyancy control
                                        device. Maintain neutral buoyancy while underwater. Be
                                        buoyant for surface swimming and resting. Have weights
                                        clear for easy removal, and establish buoyancy when in
                                        distress while diving. Carry at least one surface signaling
                                        device (such as signal tube, whistle, mirror).
                                    </li>
                                    <li>Breathe properly for diving. Never breath-hold or skipbreathe
                                        when breathing compressed air, and avoid excessive
                                        hyperventilation when breath-hold diving. Avoid overexertion
                                        while in and underwater and dive within my limitations.
                                    </li>
                                    <li>Use a boat, float or other surface support station, whenever
                                        feasible.
                                    </li>
                                    <li>Know and obey local dive laws and regulations, including
                                        fish and game and dive flag laws.I have read the above
                                        statements and have had any questions answered to my
                                        satisfaction.
                                    </li>
                                </ol>
                                <p className="mb-4">
                                    I understand the importance and purposes of these established
                                    practices. I recognize they are for my own safety and well-being,
                                    and that failure to adhere to them can place me in jeopardy
                                    when diving.
                                </p>
                            </div>
                            <hr className="mb-4"/>
                            <h3 className="text-lg text-gray-900 font-bold mb-2 text-center">NON-AGENCY DISCLOSURE AND
                                ACKNOWLEDGEMENT AGREEMENT</h3>
                            <p className="mb-4">
                                I understand and agree that PADI Members (“Members”), including {instrDetails.store_resort},
                                and/or any individual PADI Instructors and Divemasters associated with the program in
                                which I am participating, are licensed to use various PADI Trademarks and to conduct
                                PADI training, but are not agents, employees or franchisees of PADI Americas, Inc., or
                                its parent, subsidiary and affiliated corporations (“PADI”). I further understand that
                                Member business activities are independent, and are neither owned nor operated by PADI,
                                and that while PADI establishes the standards for PADI diver training programs, it is
                                not responsible for, nor does it have the right to control, the operation of the
                                Members’ business activities and the day-to-day conduct of PADI programs and supervision
                                of divers by the Members or their associated staff. I further understand and agree on
                                behalf of myself, my heirs and my estate that in the event of an injury or death during
                                this activity, neither I nor my estate shall seek to hold PADI liable for the actions,
                                inactions or negligence of {instrDetails.store_resort} and/or the instructors and
                                divemasters associated with the activity.
                            </p>
                            <hr className="mb-4"/>
                            <h2 className="text-lg text-gray-900 font-bold mb-2 text-center">
                                LIABILITY RELEASE AND ASSUMPTION OF RISK AGREEMENT
                            </h2>
                            <p className="mb-1">
                                I, {formData.diver_name}, hereby
                                affirm that I am aware that skin and scuba diving have inherent
                                risks which may result in serious injury or death. I understand
                                that diving with compressed air involves certain inherent risks;
                                including but not limited to decompression sickness, embolism
                                or other hyperbaric/air expansion injury that require treatment
                                in a recompression chamber. I further understand that the
                                open water diving trips which are necessary for training and
                                for certification may be conducted at a site that is remote,
                                either by time or distance or both, from such a recompression
                                chamber. I still choose to proceed with such dives in spite of
                                the possible absence of a recompression chamber in proximity
                                to the dive site.
                            </p>
                            <p className="mb-1">
                                I understand this Liability Release and Assumption of Risk
                                Agreement (Agreement) hereby encompasses and applies
                                to all diver training activities and courses in which I choose to
                                participate. These activities and courses may include, but are not
                                limited to, altitude, boat, cavern, AWARE, deep, enriched air,
                                photography/videography, diver propulsion vehicle, drift, dry
                                suit, ice, multilevel, night, peak performance buoyancy, search
                                & recovery, rebreather, underwater naturalist, navigator, wreck,
                                adventure diver, rescue diver and other distinctive specialties
                                (hereinafter “Programs”).
                            </p>
                            <p className="mb-1">
                                I understand and agree that neither my instructor(s),
                                divemasters(s),the facility which provides the Programs {instrDetails.store_resort}, nor
                                PADI Americas, Inc., nor its affiliate and subsidiary corporations, nor any of
                                their respective employees, officers, agents, contractors or
                                assigns (hereinafter referred to as “Released Parties”) may be
                                held liable or responsible in any way for any injury, death or
                                other damages to me, my family, estate, heirs or assigns that
                                may occur as a result of my participation in the Programs or as
                                a result of the negligence of any party, including the Released
                                Parties, whether passive or active.
                            </p>
                            <p className="mb-1">
                                In consideration of being allowed to participate in the
                                Programs, I hereby personally assume all risks of the Programs,
                                whether foreseen or unforeseen, that may befall me while I
                                am a participant in the Programs including, but not limited to,
                                the academics, confined water and/or open water activities. I
                                further release, exempt and hold harmless said Programs and
                                Released Parties from any claim or lawsuit by me, my family,
                                estate, heirs or assigns, arising out of my enrollment and
                                participation in this program including both claims arising
                                during the program or after I receive my certification(s).
                            </p>
                            <p className="mb-1">
                                I understand that past or present medical conditions may be
                                contraindicative to my participation in the Programs. I declare
                                that I am in good mental and physical fitness for diving, and
                                that I am not under the influence of alcohol, nor am I under
                                the influence of any drugs that are contraindicated to diving.
                                If I am taking medication, I declare that I have seen a physician
                                and have approval to dive while under the influence of the
                                medication/drugs. I affirm it is my responsibility to inform my
                                instructor of any and all changes to my health condition at
                                any time during my participation in the Programs and agree to
                                accept responsibility for my failure to do so.
                            </p>
                            <p className="mb-1">
                                I also understand that skin diving and scuba diving are
                                physically strenuous activities and that I will be exerting myself
                                during this program, and that if I am injured as a result of heart
                                attack, panic, hyperventilation, drowning or any other cause,
                                that I expressly assume the risk of said injuries and that I will
                                not hold the Released Parties responsible for the same.
                            </p>
                            <p className="mb-1">
                                I further state that I am of lawful age and legally competent
                                to sign this Liability Release and Assumption of Risk Agreement,
                                or that I have acquired the written consent of my parent or
                                guardian. I understand the terms herein are contractual and
                                not a mere recital, and that I have signed this Agreement of
                                my own free act and with the knowledge that I hereby agree
                                to waive my legal rights. I further agree that if any provision of
                                this Agreement is found to be unenforceable or invalid, that
                                provision shall be severed from this Agreement. The remainder
                                of this Agreement will then be construed as though the
                                unenforceable provision had never been contained herein.
                            </p>
                            <p className="mb-1">
                                I hereby state and agree this Agreement will be effective for
                                all activities associated with the Programs in which I participate
                                within one year from the date on which I sign this Agreement.
                            </p>
                            <p className="mb-1">
                                I understand and agree that I am not only giving up my right
                                to sue the Released Parties but also any rights my heirs, assigns,
                                or beneficiaries may have to sue the Released Parties resulting
                                from my death. I further represent I have the authority to do
                                so and that my heirs, assigns, or beneficiaries will be estopped
                                from claiming otherwise because of my representations to the
                                Released Parties.
                            </p>
                            <p className="mb-4 uppercase">
                                I, {formData.diver_name}, HAVE COMPLETED THE ATTACHED DIVER MEDICAL FORM (10346) AND I
                                AFFIRM IT IS MY RESPONSIBILITY TO INFORM MY INSTRUCTOR OF ANY AND ALL CHANGES TO MEDICAL
                                HISTORY AT ANY TIME DURING
                                MY PARTICIPATION IN SCUBA PROGRAMS. I AGREE TO ACCEPT RESPONSIBILITY FOR OMISSIONS
                                REGARDING MY FAILURE TO DISCLOSE
                                ANY EXISTING OR PAST HEALTH CONDITION, OR ANY CHANGES THERETO.
                            </p>
                            <p className="mb-4 uppercase">
                                I, {formData.diver_name} BY THIS INSTRUMENT AGREE TO EXEMPT AND RELEASE MY INSTRUCTORS,
                                DIVEMASTERS, THE FACILITY WHICH OFFERS THE PROGRAMS AND PADI AMERICAS, INC., AND ALL
                                RELATED ENTITIES AND RELEASED PARTIES
                                AS DEFINED ABOVE, FROM ALL LIABILITY OR RESPONSIBILITY WHATSOEVER FOR PERSONAL INJURY,
                                PROPERTY DAMAGE OR WRONGFUL
                                DEATH HOWEVER CAUSED, INCLUDING, BUT NOT LIMITED TO, THE NEGLIGENCE OF THE RELEASED
                                PARTIES, WHETHER PASSIVE OR ACTIVE.
                            </p>
                            <p className="mb-4">
                                I HAVE FULLY INFORMED MYSELF OF THE CONTENTS OF THIS NON-AGENCY DISCLOSURE AND ACKNOWLEDGMENT AGREEMENT,
                                LIABILITY RELEASE AND ASSUMPTION OF RISK AGREEMENT, DIVER MEDICAL AND STANDARD SAFE DIVING PRACTICES STATEMENT OF
                                UNDERSTANDING BY READING THEM BEFORE SIGNING BELOW ON BEHALF OF MYSELF AND MY HEIRS.
                            </p>
                        </div>
                        <div className="flex flex-col">
                            Sign here with your mouse, finger, or stylus:
                            <div id="sigCanvas" className="relative w-full h-48 border border-gray-300 rounded">
                                <SignatureCanvas ref={sigCanvas}
                                                 canvasProps={{className: 'signature-canvas w-full h-full'}}/>
                            </div>
                            <div className="flex justify-between mt-4 items-start">
                                <button type="button" onClick={clearSignature}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Clear
                                </button>
                                <input type="date" id="signature_date" name="signature_date" value={formData.signature_date}
                                       onChange={handleInputChange}
                                       className="border rounded-md py-0"/>
                            </div>
                        </div>
                        <div key="eSignConsent" id="eSignConsent"
                             className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                            <div className="text-lg text-gray-700">
                                By checking here, you are consenting to the use of your electronic signature in lieu
                                of an original signature on paper. You have the right to request that you sign a
                                paper copy instead. By checking here, you are waving that right. If you prefer to
                                print a paper copy,
                                please <a className="hover:underline text-blue-600"
                                          href="/files/10038_Continuing_Education_Administrative_Document_0.pdf"
                                          download>download the form here</a>. After consent, you
                                may, upon written request to us, obtain a paper copy of an electronic record. No fee
                                will be charged for such copy and no special hardware or software is required to
                                view it. Your agreement to use an electronic signature with us for any documents
                                will continue until such time as you notify us in writing that you no longer wish to
                                use an electronic signature. There is no penalty for withdrawing your consent. You
                                should always make sure that we have a current email address in order to contact you
                                regarding any changes, if necessary.
                            </div>
                            <div id="checkbox-question" className="isolate inline-flex rounded-md shadow-sm">
                                <input
                                    type="checkbox"
                                    id="checkboxEsignConsent"
                                    name="checkboxEsignConsent"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                        </div>
                        {isAuthenticated ? (
                            <button type="submit"
                                    disabled={isSubmitting}
                                    className="float-right rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >{isSubmitting ? 'Submitting...' : 'Agree & Submit'}
                            </button>
                        ) : (
                            <button type="button" onClick={handleLoginClick}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 float-right">
                                Please Login to E-Sign this Document
                            </button>
                        )}
                    </form>
                </div>
            </section>
        </>
    )
        ;
};

export default CeadForm;
