import React, {useRef} from "react";
import {fetchDiscountDetails, removeProduct} from "../redux/paymentGatewaySlice";
import {useDispatch, useSelector} from "react-redux";
import OrderSummary from "../components/OrderSummary";

const PaymentGatewayOrderSummary = ({instr_id, products}) => {
    const dispatch = useDispatch();
    const subtotal = useSelector((state) => state.paymentGateway.subtotal);
    const taxes = useSelector((state) => state.paymentGateway.taxes);
    const shipping = useSelector((state) => state.paymentGateway.shipping);
    const discountCodes = products.map(product => product.discount?.code).filter(Boolean).join(', ');
    const discountCodeRef = useRef(null);
    const total = useSelector((state) => state.paymentGateway.total);
    const totalDiscountValue = useSelector((state) => state.paymentGateway.totalDiscountValue);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleRemoveClick = (id) => {
        dispatch(removeProduct({id: id}));
    }

    const handleApplyDiscount = async () => {
        const code = discountCodeRef.current.value;
        const productIds = products.map(product => product.id);
        dispatch(fetchDiscountDetails({instr_id, code, productIds}))
            .then(() => {
                discountCodeRef.current.value = '';
            });
    };

    return (
        <OrderSummary products={products}
                      subtotal={subtotal}
                      taxes={taxes}
                      shipping={shipping}
                      discountCodes={discountCodes}
                      total={total}
                      currencyFormatter={currencyFormatter}
                      totalDiscountValue={totalDiscountValue}
                      handleApplyDiscount={handleApplyDiscount}
                      handleRemoveClick={handleRemoveClick}
                      discountCodeRef={discountCodeRef}
        />
    )
}

export default PaymentGatewayOrderSummary;