import {useDispatch, useSelector} from "react-redux";
import {hideModal} from "../redux/paymentGatewaySlice";
import React from "react";
import ResultModal from "./ResultModal";
import PaymentGatewayOrderSummary from "./PaymentGatewayOrderSummary";
import PaymentGatewayCheckoutFormDirect from "../components/PaymentGatewayCheckoutFormDirect";
import PaymentGatewayCheckoutFormStripe from "../components/PaymentGatewayCheckoutFormStripe";

const PaymentGateway = () => {
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id);
    const products = useSelector((state) => state.paymentGateway.products);
    const shipping = useSelector((state) => state.paymentGateway.shipping);
    const taxes = useSelector((state) => state.paymentGateway.taxes);
    const subtotal = useSelector((state) => state.paymentGateway.subtotal);
    const discountedTotal = useSelector(state => state.paymentGateway.discountedTotal);
    const total = useSelector((state) => state.paymentGateway.total);
    const totalDiscountValue = useSelector((state) => state.paymentGateway.totalDiscountValue);
    const dispatch = useDispatch();
    const resultModalObj = useSelector((state) => state.paymentGateway.resultModalObj);
    const stripeIntegration = useSelector((state) => state.instrDetails?.instrDetails.pmt_stripe_integration ?? false);
    const instrVenmoUsername = useSelector((state) => state.instrDetails?.instrDetails?.pmt_venmo_username ?? null);
    const instrZelleUsername = useSelector((state) => state.instrDetails?.instrDetails?.pmt_zelle_username ?? null);
    const instrZelleQrUrl = useSelector((state) => state.instrDetails?.instrDetails?.pmt_zelle_qr_url ?? null);
    const instrAppleUsername = useSelector((state) => state.instrDetails?.instrDetails?.pmt_apple_username ?? null);
    const instrCashAppUsername = useSelector((state) => state.instrDetails?.instrDetails?.pmt_cash_app_username ?? null);

    const discounts = products?.map((item) => ({
        code: item.discount.code,
        type: item.discount.type,
        rate: item.discount.rate,
        amount: item.discount.amount,
    }));

    const closeModal = () => {
        dispatch(hideModal());
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    // useEffect(() => {
    //     console.log(discountedTotal);
    // }, [discountedTotal]);

    return (
        <>
            <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
                {resultModalObj.show && <ResultModal result={resultModalObj} closeModal={closeModal}/>}
                <h1 className="sr-only">Checkout</h1>
                <PaymentGatewayOrderSummary instr_id={instr_id} products={products}/>
                {(instrVenmoUsername || instrZelleUsername || instrAppleUsername || instrCashAppUsername) && (
                    <PaymentGatewayCheckoutFormDirect
                        formattedTotal={currencyFormatter.format(total)}
                        total={total}
                        venmoUsername={instrVenmoUsername}
                        zelleQrUrl={instrZelleQrUrl}
                        zelleUsername={instrZelleUsername}
                        appleUsername={instrAppleUsername}
                        cashAppUsername={instrCashAppUsername}
                        products={products}
                        discounts={discounts}
                        instrId={instr_id}
                        shipping={shipping}
                        taxes={taxes}
                        subtotal={subtotal}
                        discountedTotal={discountedTotal}
                        totalDiscountValue={totalDiscountValue}
                    />
                )}

                {stripeIntegration && (
                    <>
                        <div className="relative mt-8">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t border-gray-200"/>
                            </div>
                            <div className="relative flex justify-center">
                                    <span className="px-4 text-sm font-medium text-gray-500">or</span>
                                </div>
                            </div>
                            <PaymentGatewayCheckoutFormStripe instr_id={instr_id} products={products}
                                                              formattedTotal={currencyFormatter.format(total)}/>
                        </>
                    )}
            </main>
        </>
)
}

export default PaymentGateway;
