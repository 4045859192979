import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Open Water Diver",
    description: "PADI Open Water Diver is the first scuba certification level.  " +
        "You will learn how to scuba dive in a relaxed, supportive learning environment.  " +
        "By the end of the course, you'll have the skills and knowledge to dive at home or abroad and be an " +
        "ambassador for the underwater world.",
    descTwo: "PADI Open Water Divers are qualified to:",
    descTwoList: [
        'Dive with a partner (without supervision) in environments with conditions equal to or better than those in which they trained to dive, to maximum depths of 18 metres/60 feet.',
        'Obtain air fills',
        'Rent or purchase scuba equipment',
        'Book excursions with dive boats and resorts'
    ]
};

const faqs = [
    {
        question: 'How long does it all take?',
        answer:
            'About 4-7 days.  Plan on 2-3 days for academics, 1-2 day(s) for confined water, and 2 days for ocean dives.'
    },
    {
        question: 'Can people with physical challenges learn to dive?',
        answer:
            "Yes. People with missing limbs, reduced mobility, or other physical challenges commonly earn the PADI Open Water Diver certification. " +
            "Even individuals with more significant physical or mental challenges can still take part in scuba experiences." +
            "Use the Inquire button to ask your instructor about your specific situation." +
            "All student divers complete a brief scuba medical questionnaire that asks about medical conditions that could be a problem while diving and will determine if you need to be evaluated by a physician before being able to scuba dive. " +
            "If the doctor approves, or none of the conditions applied to you, you're ready to start!",
    },
    {
        question: 'Does a PADI scuba certification expire?',
        answer: "No. Once you are certified, you are certified for life.  " +
            "However, if you haven't been diving in a while and wish to refresh your scuba safety knowledge and skills, the PADI ReActivate course will get you back up to speed."
    },
    {
        question: 'What about sharks?',
        answer: 'When you’re lucky, you get to see a shark. ' +
            'Although incidents with sharks occur, they are very rare and, with respect to diving, primarily involve spear fishing or feeding sharks, both of which trigger feeding behavior. ' +
            'Most of the time, if you see a shark it’s just passing through and a rare sight to enjoy.'
    },
    {
        question: 'How deep do we go?',
        answer: 'With the necessary training and experience, the limit for recreational scuba diving is 40 metres/130 feet. ' +
            'PADI Open Water divers stay shallower, about 18 metres/60 feet. ' +
            'Although these are the limits, some of the most popular diving is shallower than 12 metres/40 feet, where the water’s warmer and the colors are brighter.'
    }
];

const academics = [
    {
        title: 'eLearning',
        body:
            "PADI eLearning makes it easy to fit scuba lessons into a busy schedule. " +
            "Learn about scuba diving principles and terminology whenever, wherever it's convenient for you. " +
            "It's your course on your time. Study offline, or online using a computer or mobile device. " +
            "Normally 5-10 hours of self study. " +
            "This is done at your own pace, but should be completed before in-water activities with your instructor.",
    },
    {
        title: 'Instructor-led Training',
        body:
            "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "This is done in a classroom setting and can be done in one day, but is typically broken into two half-day sessions. " +
            "Use the Inquire button if you are interested in this option.",
    },
];

const dives = [
    {
        title: 'Confined Water',
        body: "Confined Water means a body of water with pool-like conditions, such as a swimming pool or calm ocean. " +
            "You will conduct five confined water 'mini dives' in the PADI Open Water Diver course. " +
            "These are typically completed over two days, but keep in mind PADI courses are performance based. " +
            "This means different divers may take different amounts of time to complete. "
    },
    {
        title: 'Ocean',
        body: "You will conduct four ocean dives. Two each day for two days. " +
            "During these dives you will demonstrate mastery of the skills you learned in confined water."
    }
];

const progressionCourseCodes = ['PADI-AOW', 'PADI-DLD', 'PADI-ADAPT', 'PADI-ALT', 'PADI-DEBRIS', 'PADI-SHARK',
    'PADI-BOAT', 'PADI-DSMB', 'PADI-DPV', 'PADI-DRIFT', 'PADI-DRY', 'PADI-EAN', 'PADI-FISH', 'PADI-FULL',
    'PADI-NIGHT', 'PADI-PPB', 'PADI-SIDE', 'PADI-NATURE', 'PADI-UN', 'PADI-PHOTO', 'PADI-VIDEO'];

const requirements = [
    '10 years old',
    'Be able to tread water for 10 minutes',
    'Complete 200m continuous surface swim (no time limit or stroke requirement)'
];

const PadiOpenWater = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-OW"
        imageUrl='/images/PADI/ow-card.jpg'
        courseSlug='/courses/padi-open-water'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Open%20Water%20Diver%20Course"
    />
);

export default PadiOpenWater;
