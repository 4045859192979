import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOutstandingData } from '../redux/outstandingDataSlice';

const InitOutstandingData = () => {
    const dispatch = useDispatch();
    const instr_id = useSelector(state => state.instrDetails?.instrDetails?.instr_id);
    const status = useSelector(state => state.outstandingData.status);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
        // Only fetch if the instructor ID is available and status is 'idle'
        if (instr_id && status === 'idle' && isAuthenticated) {
            dispatch(fetchOutstandingData(instr_id))
                .unwrap()
                .catch(error => {
                    console.error('Failed to fetch outstanding data:', error);
                });
        }
    }, [instr_id, status, dispatch, isAuthenticated]);

    return null;
};

export default InitOutstandingData;
