import PadiCourse from "./PadiCourse";
import React from "react";

const product = {
    title: "Emergency First Response",
    description: "Emergency First Response primary and secondary care training teaches you what to do in the critical " +
        "moments between when a life-threatening emergency occurs and when emergency medical services arrive. " +
        "Learn how to perform CPR, provide first aid that eases pain, assess a variety of injuries and illnesses and " +
        "practice bandaging and splinting."
}

const faqs = [
    {
        question: 'How long does it all take?',
        answer:
            "Two to four hours of eLearning, followed by a half day of practical training with your instructor."
    }
]

let academics = [
    {
        title: 'eLearning',
        body:
            "Online training makes it easy to fit a CPR and first aid course into a busy schedule.  Watch videos " +
            "of different first aid techniques and learn what steps to take in an emergency.  Study online or offline, " +
            "using a computer or mobile device. Connect with your instructor if you have questions."
    },
    {
        title: 'Instructor-led Training',
        body:
            "Once you have completed the eLearning portion, you will practice what you have learned with your " +
            "instructor.  You will assess a variety of injuries and illnesses, bandage and splint " +
            "different injuries, and perform CPR and use an AED.  You will also learn how to provide first aid that " +
            "eases pain and reduces the risk of further harm.  You will practice these skills until you feel " +
            "confident that you can use them in an emergency."
    }
]

const dives = [
    {
        title: 'Not Applicable',
        body: "There are no dives for this course."
    }
]

const progressionCourseCodes = ['PADI-RESCUE', 'PADI-EFRI', 'PADI-OXYGEN'];

const requirements = [
    'None',
];

const PadiEmergencyFirstResponse = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-EFR"
        imageUrl='/images/PADI/EFR-Gallery-Hero-1(2-UNG2ieFq.jpg'
        courseSlug='/courses/padi-emergency-first-response'
        requirements={requirements}
        boolDiverMedical={false}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Emergency%20First%20Responder%20Course"
    />
);

export default PadiEmergencyFirstResponse;
