import {Disclosure} from "@headlessui/react";
import {Link} from "react-router-dom";
import React from "react";

const OrderSummary = ({gatewaySlice, currencyFormatter, handleApplyDiscount, handleRemoveClick, discountCodeRef}) => {

    const products = gatewaySlice.products;
    const discountCodes = gatewaySlice.discountCodes;

    const subtotal = gatewaySlice.subTotal;
    const totalDiscounts = gatewaySlice.totalDiscounts;
    const taxes = gatewaySlice.taxesTotal;
    const shipping = gatewaySlice.shippingTotal;
    const total = gatewaySlice.totalTotal;

    return (
        <>
            {/*Mobile Order Summary*/}
            <section aria-labelledby="order-heading" className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden">
                <Disclosure as="div" className="mx-auto max-w-lg">
                    {({open}) => (
                        <>
                            <div className="flex items-center justify-between">
                                <h2 id="order-heading" className="text-lg font-medium text-gray-900">
                                    Your Order
                                </h2>
                                <Disclosure.Button
                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                    {open ? <span>Hide full summary</span> : <span>Show full summary</span>}
                                </Disclosure.Button>
                            </div>

                            <Disclosure.Panel>
                                <ul role="list"
                                    className="divide-y divide-gray-200 border-b border-gray-200">
                                    {products.map((product) => (
                                        <li key={product.id} className="flex space-x-6 py-6">
                                            <img
                                                src={product.imageSrc ? product.imageSrc : '/images/generic-event.webp'}
                                                alt={product.imageAlt ? product.imageAlt : 'Generic event'}
                                                className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                                            />
                                            <div className="flex flex-col justify-between space-y-4">
                                                <div className="space-y-1 text-sm font-medium">
                                                    <h3 className="text-gray-900">{product.title}</h3>
                                                    <p className="text-gray-900">{currencyFormatter.format(product.price)}</p>
                                                    {product.subtitle1 &&
                                                        <p className="text-gray-500">{product.subtitle1}</p>}
                                                    {product.subtitle2 &&
                                                        <p className="text-gray-500">{product.subtitle2}</p>}
                                                    {product.discount && product.discount.code && (
                                                        <div className="mt-2 bg-gray-100 p-2 rounded">
                                                                        <span
                                                                            className="text-xs font-semibold text-gray-600">Discount Code:</span>
                                                            <span
                                                                className="text-xs ml-2 text-indigo-600">{product.discount.code}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex space-x-4">
                                                    {product.id.startsWith('subscriptions-') && (
                                                        <Link
                                                            type="button"
                                                            className="text-sm border-r pr-4 border-gray-300 font-medium text-indigo-600 hover:text-indigo-500"
                                                            to='/manage-subscription'
                                                        >
                                                            Edit
                                                        </Link>
                                                    )}
                                                    <div className="flex">
                                                        <button
                                                            type="button"
                                                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                                            onClick={() => handleRemoveClick(product.id)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <div className="mt-10">
                                    <label htmlFor="discount-code-mobile"
                                           className="block text-sm font-medium text-gray-700">
                                        Discount code
                                    </label>
                                    <div className="mt-1 flex space-x-4">
                                        <input
                                            type="text"
                                            ref={discountCodeRef}
                                            id="discount-code-mobile"
                                            name="discount-code-mobile"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        <button
                                            type="button"
                                            className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                            onClick={() => handleApplyDiscount()}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>

                                <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                                    <div className="flex justify-between">
                                        <dt>Subtotal</dt>
                                        <dd className="text-gray-900">{currencyFormatter.format(subtotal)}</dd>
                                    </div>
                                    <div className="flex justify-between">
                                        <dt className="flex">
                                            Discount
                                            {discountCodes && discountCodes.length > 0 && (
                                                <div className="flex ml-2 space-x-2">
                                                    {discountCodes.map((code, index) => (
                                                        <span
                                                            key={index}
                                                            className="rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                                                            {code}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </dt>
                                        <dd className="text-gray-900">-{currencyFormatter.format(totalDiscounts)}</dd>
                                    </div>
                                    <div className="flex justify-between">
                                        <dt>Taxes</dt>
                                        <dd className="text-gray-900">{currencyFormatter.format(taxes)}</dd>
                                    </div>
                                    <div className="flex justify-between">
                                        <dt>Shipping</dt>
                                        <dd className="text-gray-900">{currencyFormatter.format(shipping)}</dd>
                                    </div>
                                </dl>
                            </Disclosure.Panel>

                            <p className="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
                                <span className="text-base">Total</span>
                                <span className="text-base">{currencyFormatter.format(total)}</span>
                            </p>
                        </>
                    )}
                </Disclosure>
            </section>

            {/*Order Summary*/}
            <section aria-labelledby="summary-heading"
                     className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex rounded-2xl">
                <h2 id="summary-heading" className="sr-only">
                    Order summary
                </h2>

                <ul role="list" className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6">
                    {products.map((product) => (
                        <li key={product.id} className="flex space-x-6 py-6">
                            <img
                                src={product.imageSrc ? product.imageSrc : '/images/generic-event.webp'}
                                alt={product.imageAlt ? product.imageAlt : 'Generic event'}
                                className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                            />
                            <div className="flex flex-col justify-between space-y-4">
                                <div className="space-y-1 text-sm font-medium">
                                    <h3 className="text-gray-900">{product.title}</h3>
                                    <p className="text-gray-900">${product.price}</p>
                                    <p className="text-gray-500">{product.subtitle1}</p>
                                    <p className="text-gray-500">{product.subtitle2}</p>
                                    {product.discount && product.discount.code && (
                                        <div className="mt-2 bg-gray-100 p-2 rounded">
                                                <span
                                                    className="text-xs font-semibold text-gray-600">Discount Code:</span>
                                            <span
                                                className="text-xs ml-2 text-indigo-600">{product.discount.code}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="flex space-x-4">
                                    {product?.id.startsWith('subscriptions-') && (
                                        <Link
                                            type="button"
                                            className="border-r pr-4 border-gray-300 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                            to='/manage-subscription'
                                        >
                                            Edit
                                        </Link>
                                    )}
                                    <div className="flex">
                                        <button
                                            type="button"
                                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                            onClick={() => handleRemoveClick(product.id)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>

                <div
                    className="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6 rounded-b-2xl">
                    <form>
                        <label htmlFor="discount-code" className="block text-sm font-medium text-gray-700">
                            Discount code
                        </label>
                        <div className="mt-1 flex space-x-4">
                            <input
                                type="text"
                                id="discount-code"
                                name="discount-code"
                                ref={discountCodeRef}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            <button
                                type="button"
                                className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                onClick={() => handleApplyDiscount()}
                            >
                                Apply
                            </button>
                        </div>
                    </form>

                    <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                        <div className="flex justify-between">
                            <dt>Subtotal</dt>
                            <dd className="text-gray-900">{currencyFormatter.format(subtotal)}</dd>
                        </div>
                        <div className="flex justify-between">
                            <dt className="flex">
                                Discount
                                {discountCodes && discountCodes.length > 0 && (
                                    <div className="flex ml-2 space-x-2">
                                        {discountCodes.map((code, index) => (
                                            <span
                                                key={index}
                                                className="rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                                                {code}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </dt>
                            <dd className="text-gray-900">-{currencyFormatter.format(totalDiscounts)}</dd>
                        </div>
                        <div className="flex justify-between">
                            <dt>Taxes</dt>
                            <dd className="text-gray-900">{currencyFormatter.format(taxes)}</dd>
                        </div>
                        <div className="flex justify-between">
                            <dt>Shipping</dt>
                            <dd className="text-gray-900">{currencyFormatter.format(shipping)}</dd>
                        </div>
                        <div
                            className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                            <dt>Total</dt>
                            <dd className="text-base">{currencyFormatter.format(total)}</dd>
                        </div>
                    </dl>
                </div>
            </section>
        </>
    )
}
export default OrderSummary;