import React from 'react';
import { BounceLoader } from "react-spinners";
import {Helmet} from "react-helmet-async";

export const LoadingSpinner = ({title, description, image, url, isLoading}) => {

    if (!isLoading) return null;

    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <Helmet>
                <meta property='og:title' content={title}/>
                <meta property='og:image' content={url + image}/>
                <meta property='og:description' content={description}/>
                <meta property='og:url' content={url}/>
            </Helmet>
            <div className="text-center space-y-2">
                <BounceLoader color={"#123abc"} size={60}/>
                <p className="text-white">Loading content...</p>
            </div>
        </div>
    );
};
