import React from 'react';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

export function BioPagination({bioVersions, currentVersionIndex, handleVersionChange, goToPreviousVersion, goToNextVersion}) {
    const totalPages = bioVersions.length;

    return (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <button
                    type="button"
                    disabled={currentVersionIndex === 0}
                    onClick={goToPreviousVersion}
                    className={`inline-flex items-center border-t-2 pr-1 pt-4 text-sm font-medium ${currentVersionIndex === 0 ? 'text-gray-300' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {Array.from({ length: totalPages }).map((_, idx) => (
                    <button
                        type="button"
                        key={idx}
                        onClick={() => handleVersionChange(idx)}
                        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${idx === currentVersionIndex ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
                    >
                        {idx + 1}
                    </button>
                ))}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    type="button"
                    disabled={currentVersionIndex === bioVersions.length - 1}
                    onClick={goToNextVersion}
                    className={`inline-flex items-center border-t-2 pl-1 pt-4 text-sm font-medium ${currentVersionIndex === bioVersions.length - 1 ? 'text-gray-300' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                    Next
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </nav>
    );
}
