import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Peak Performance Buoyancy",
    description: "Excellent buoyancy control is what defines skilled scuba divers. You've seen them underwater. " +
        "They glide effortlessly, use less air and ascend, descend or hover almost as if by thought. They more " +
        "easily observe aquatic life without disturbing their surroundings. You can achieve this, too. The PADI " +
        "Peak Performance Buoyancy Specialty course improves the buoyancy skills you learned as a new diver and " +
        "elevates them to the next level."
}

const faqs = [
    {
        question: 'Why is good buoyancy important?',
        answer: "Proper buoyancy control enhances your safety, conserves air, allows for more comfortable and " +
            "controlled dives, and helps protect the marine environment by preventing accidental damage to reefs " +
            "and other underwater structures."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "PADI eLearning makes it easy to fit scuba lessons into a busy schedule. Complete the independent study " +
            "part of your course whenever, wherever it’s convenient for you. Work at your own pace, online or offline, " +
            "using a computer or mobile device. Connect with your instructor for inwater training, or whenever you " +
            "have a question. This course typically takes about 2-4 hours to complete.",

    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are two dives required for this course, which are typically conducted in a single day. " +
            "During these dives, you will determine how much weight you need and where to place trim weights to " +
            "streamline your body position. During two dives you’ll fine-tune your weighting during buoyancy checks " +
            "and master neutral buoyancy with inwater practice."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '10 years old',
    'PADI Open Water Diver'
];

const PadiPeakPerformanceBuoyancy = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-PPB"
        imageUrl='/images/PADI/PPB1-TaeMHJYU.jpg'
        courseSlug='/courses/padi-peak-performance-buoyancy'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Peak%20Performance%20Buoyancy%20Course"
    />
);

export default PadiPeakPerformanceBuoyancy;
