import React, {Fragment, useEffect, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link, useNavigate} from "react-router-dom";
import {signOut as signOutAction} from "../redux/authSlice";
import {signOut} from 'aws-amplify/auth';
import {selectInstrDetailsStatus} from "../redux/instrDetailsSlice";
import {useSelector, useDispatch} from 'react-redux';
import {resetOutstandingData} from "../redux/outstandingDataSlice";
import InitOutstandingData from "./InitOutstandingData";
import NavItem from "../components/NavItem";
import {clearStudentList} from "../redux/studentsSlice";
import {clearTransactions} from "../redux/transactionsSlice";
import {post} from "aws-amplify/api";
import {getImageUrl} from "../utils/sanitizeFunctions";

function courseNames(...courses) {
    return courses.filter(Boolean).join(' ')
}

const Header = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const instrDetails = useSelector((state) => state.instrDetails?.instrDetails);
    const featureFlags = instrDetails?.enabled_features?.[0] || {
        show_calendar: false,
        show_contact: false,
        instructor_portal: false,
        manage_calendar: false,
        show_course_signup: false
    };
    const instrDetailsStatus = useSelector(selectInstrDetailsStatus);
    const isLoading = instrDetailsStatus === 'loading' || instrDetailsStatus === 'idle';
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.auth.userDetails);
    const studentId = userDetails?.id;
    const outstandingData = useSelector((state) => state.outstandingData);
    const hasOutstandingNotifications = outstandingData.outstandingForms.length > 0 || outstandingData.outstandingCharges.length > 0;
    const currentURL = useSelector((state) => state.url.currentURL);
    const instrDomains = userDetails && userDetails.instr_domains;
    const isInstrOnOwnSite = instrDomains && instrDomains.includes(currentURL);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [userPortrait, setUserPortrait] = useState('/images/portraits/unknown_male.png')

    const generateNotificationItems = () => {
        let items = [];
        if (outstandingData.outstandingForms.length > 0) {
            items.push({name: 'Outstanding Forms', key: 'outstanding-forms', to: '/outstanding-forms'});
        }

        if (outstandingData.outstandingCharges.length > 0) {
            items.push({name: 'Outstanding Charges', key: 'outstanding-charges', to: '/outstanding-charges'});
        }

        return items;
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const notificationItems = generateNotificationItems();

    const handleSignOut = async (event) => {
        event.preventDefault();
        await signOut();
        dispatch(signOutAction());
        dispatch(resetOutstandingData());
        dispatch(clearStudentList());
        dispatch(clearTransactions());
        navigate('/');
    }

    const navigation = [
        { name: 'Home', to: '/' },
        { name: 'Courses', to: '/courses' },
        ...(featureFlags.show_calendar ? [{ name: 'Calendar', to: '/calendar' }] : []),
        ...(featureFlags.show_contact ? [{ name: 'Contact', to: '/contact' }] : []),
        { name: 'About', to: '/about' }
    ];

    const userNavigation = isAuthenticated ? (
        isInstrOnOwnSite ? [
            {name: 'Student Profile', to: '/profile'},
            {name: 'Instructor Profile', to: '/instructor-profile'},
            {name: 'Manage Subscription', to: '/manage-subscription'},
            ...(featureFlags.manage_calendar ? [
                {name: 'Manage Calendar', to: '/manage-calendar'}
            ] : []),
            {name: 'Sign out', onClick: (event) => handleSignOut(event)},
                ...(featureFlags.instructor_portal ? [
                    {name: 'divider1', type: 'divider'},
                    {name: 'Student List', to: '/student-list'},
                    {name: 'Transactions', to: '/transactions'}
                ] : [])
        ] : [
            // Current user is an instructor, but they are on a different instructors site
            {name: 'Student Profile', to: '/profile'},
            {name: 'Instructor Profile', to: '/instructor-profile'},
            {name: 'Enrollments', href: '#'},
            {name: 'Sign out', onClick: (event) => handleSignOut(event)}
        ]
    ) : [
        {name: 'Sign In', to: '/signin'},
    ]

    let header_logo = '';
    let fontClass;

    if (instrDetails.hasOwnProperty('organization') && instrDetails.organization !== '') {
        header_logo = '/images/' + instrDetails.organization + '/logo-header.png';
        fontClass = 'font-' + instrDetails.organization.toLowerCase();
    } else {
        header_logo = '/images/other/logo-header.png';
        fontClass = '';
    }

    useEffect(() => {
        if (isAuthenticated && studentId && instrDetails.instr_id) {
            setUserPortrait(getImageUrl('userPortrait-' + studentId + '.jpeg'));

            // Ensure student_instr_mapping is updated
            const apiName = 'InstrOfScubaAPI'
            const path = '/save-student-instr-mapping'
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            post({apiName, path, options});  // async, no need to await
        }
    }, [dispatch, isAuthenticated, instrDetails]);

    return (
        <>
            <InitOutstandingData/>
            <div className="min-h-full">
                <Disclosure as="nav" className="bg-gray-800" open={isOpen} onChange={(isOpen) => setIsOpen(isOpen)}>
                    {({open}) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-8"
                                                src={header_logo}
                                                alt="Logo"
                                            />
                                        </div>
                                        {/* Desktop */}
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <NavItem key={item.name} item={item}
                                                             closeMenu={() => setIsOpen(false)}/>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Desktop */}
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {/*Desktop Notification bell with red dot*/}
                                            <Menu as="div" className="relative ml-3">
                                                <div>
                                                    <Menu.Button
                                                        className={classNames(
                                                            "relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800",
                                                            {"ring-2 ring-red-500": hasOutstandingNotifications}
                                                        )}
                                                    >
                                                        <span className="sr-only">View notifications</span>
                                                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                                        {hasOutstandingNotifications && (
                                                            <span
                                                                className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500"></span>
                                                        )}
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                        {notificationItems.length === 0 ? (
                                                            <div className="px-4 py-2 text-sm text-gray-700">No
                                                                notifications</div>
                                                        ) : (
                                                            notificationItems.map((item) => (
                                                                <Menu.Item key={item.key}>
                                                                    {({focus}) => (
                                                                        <Link
                                                                            to={item.to}
                                                                            className={classNames(
                                                                                focus ? 'bg-gray-100' : '',
                                                                                'block px-4 py-2 text-sm text-gray-700'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </Link>
                                                                    )}
                                                                </Menu.Item>
                                                            ))
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>

                                            {/* Desktop Profile dropdown */}
                                            <Menu as="div" className="relative ml-3">
                                                <div>
                                                    <Menu.Button
                                                        className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 text-gray-400 hover:text-white">
                                                        <span className="sr-only">Open user menu</span>
                                                        {isAuthenticated ? (
                                                            <img
                                                                src={userPortrait}
                                                                alt="User Thumbnail" className="h-6 w-6 rounded-full"
                                                                onError={(e) => {
                                                                    if (e.target.src !== '/images/portraits/unknown_male.png') {
                                                                        e.target.src = '/images/portraits/unknown_male.png';
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            <Bars3Icon
                                                                className="h-6 w-6 rounded-full"/>
                                                        )}
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map((item) => (
                                                            item.type === 'divider' ? (
                                                                <div key={item.name}
                                                                     className="border-b border-gray-400 my-1"></div>
                                                            ) : (
                                                                <Menu.Item key={item.name}>
                                                                    {({focus: isFocused, close}) => (
                                                                        <Link
                                                                            to={item.to}
                                                                            onClick={(event) => {
                                                                                if (item.onClick) item.onClick(event);
                                                                                close();
                                                                            }} className={courseNames(
                                                                            isFocused ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                        >
                                                                            {item.name}
                                                                        </Link>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>

                                    {/*Mobile Menu Button*/}
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : hasOutstandingNotifications ? (
                                                // BellIcon with red dot when there are notifications
                                                <span className="relative">
                                                       <BellIcon className="block h-6 w-6" aria-hidden="true"/>
                                                        <span
                                                            className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500"></span>
                                                        </span>
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            {/*Mobile Menu*/}
                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        <NavItem key={item.name} item={item} closeMenu={() => setIsOpen(false)} mobile={true}/>
                                    ))}
                                </div>
                                <div className="border-t border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            {isAuthenticated ? (
                                                <img src={userPortrait}
                                                     alt="User Thumbnail" className="h-6 w-6 rounded-full"
                                                     onError={(e) => {
                                                         if (e.target.src !== '/images/portraits/unknown_male.png') {
                                                             e.target.src = '/images/portraits/unknown_male.png';
                                                         }
                                                     }}
                                                />
                                            ) : (
                                                <Bars3Icon className="h-6 w-6 rounded-full"/>
                                            )}
                                        </div>
                                    </div>

                                    {/* Mobile Notification Bell and Dropdown */}
                                    <div className="mt-3 space-y-1 px-2">
                                        {notificationItems.length > 0 && (
                                            notificationItems.map((item) => (
                                                <NavItem key={item.name} item={item} closeMenu={() => setIsOpen(false)}
                                                         notification={true} mobile={true}/>
                                            ))
                                        )}
                                        {userNavigation.map((item) => (
                                            <NavItem key={item.name} item={item} closeMenu={() => setIsOpen(false)} mobile={true}/>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                <header className="bg-white shadow">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        <h1 className={`text-3xl min-h-[40px] font-bold tracking-tight text-gray-900 ${fontClass} ${!isLoading ? 'animate-[fade-in-right_1s_ease-in-out]' : ''}`}>
                            {instrDetails.first_name} {instrDetails.last_name} {instrDetails.organization !== '' ? '-' : ''} {instrDetails.organization} {instrDetails.pro_level}</h1>
                    </div>
                </header>
            </div>
        </>
    )
}

export default (Header);
