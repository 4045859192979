import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Enriched Air (Nitrox) Diver",
    description: "Enriched air, also known as nitrox or EANx, contains less nitrogen than regular air. " +
        "Breathing less nitrogen means you can enjoy longer dives and shorter surface intervals. " +
        "No wonder Enriched Air Diver is the most popular PADI® specialty."
}

const faqs = [
    {
        question: 'What is Enriched Air Nitrox?',
        answer:
            "Enriched Air Nitrox (EANx) is a breathing gas that has a higher oxygen content and lower nitrogen " +
            "content compared to regular air. It can provide longer no-decompression limits, shorter surface " +
            "intervals, and may enhance diver safety."
    },
    {
        question: 'Do I need special equipment for nitrox diving?',
        answer: "Most modern dive equipment is compatible with nitrox, but you will need scuba tanks that have been " +
            "specially cleaned and certified for use with enriched air. You'll also need a nitrox-compatible dive " +
            "computer or dive watch. Your instructor will teach you how to analyze your tank and set your computer " +
            "for nitrox. Analyzers should be available for use at any dive shop offering nitrox fills or rentals, but " +
            "may also be purchased for convenient personal use."
    },
    {
        question: 'Is Nitrox diving safe?',
        answer: "Nitrox diving is considered safe when conducted within the proper guidelines and training. " +
            "It's essential to understand the potential risks, including oxygen toxicity, and how to manage them."
    },
    {
        question: 'How Deep Can I Dive with Nitrox?',
        answer: 'The maximum depth depends on the specific oxygen concentration in your Nitrox mix. ' +
            'Your training will include how to calculate the appropriate depth limits for different Nitrox blends.'
    },
    {
        question: 'Will I conduct any dives during the course?',
        answer: "At minimum, you'll meet with your instructor after completing the knowledge development " +
            "portion of the course. You'll learn how to analyze a tank, fill out an enriched air log, " +
            "and set your dive computer for nitrox. There are two optional dives available for this course."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Online learning makes it easy to fit scuba lessons into a busy schedule. " +
            "Complete the knowledge development section of your course whenever, wherever it's convenient for you. " +
            "Work at your own pace, online or offline, using a computer or mobile device. " +
            "After finishing your independent study, you'll learn how to analyze a tank, fill out an enriched air log, " +
            "and set your dive computer for nitrox."
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "This is done in a classroom setting and typically takes 2-4 hours depending on how much you study in advance. " +
            "Use the Inquire button if you are interested in this option.",
    }
]

const dives = [
    {
        title: 'Ocean',
        body: "There are two optional dives available for this course. Let your instructor know if you are interested " +
            "in the academics only version of the course or if you would like to complete two nitrox dives under the " +
            "supervision of your instructor."
    }
]

const progressionCourseCodes = ['PADI-SCRB', 'PADI-TEC40'];

const requirements = [
    '12 years old',
    'PADI Open Water Diver, or in conjunction with your PADI Open Water Diver course'
];

const PadiNitrox = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-EAN"
        imageUrl='/images/PADI/Eax-Gallery-hero-1-pYvUsPuz.jpg'
        courseSlug='/courses/padi-enriched-air-nitrox'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Enriched%20Air%20Nitrox"
    />
);

export default PadiNitrox;
