import React from 'react';
import useStripeCheckout from "../hooks/useStripeCheckout";

const StripeCheckout = ({tierId, discountCodes}) => {
    const {isBusy, errorInfo, handleCheckout} = useStripeCheckout();

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <section
            aria-labelledby="payment-heading"
            className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
        >
            <h2 id="payment-heading" className="sr-only">
                Setup Payment
            </h2>

            <div className="mx-auto max-w-lg lg:pt-16">
                <a href="#"
                   onClick={() => handleCheckout(tierId, discountCodes)}
                   className="flex w-full items-center justify-center rounded-md border border-transparent"
                >
                    <button
                        type="button"
                        className={`flex w-full items-center justify-center rounded-md border border-transparent py-2 text-white font-bold ring-1 ring-black focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 ${isBusy ? 'bg-indigo-300 cursor-not-allowed hover:bg-indigo-300' : 'bg-indigo-500 hover:bg-indigo-700'}`}
                    >{isBusy ? 'Connecting to payment service...' : 'Setup Payment'}
                        <span className="sr-only">Setup Payment</span>
                    </button>
                </a>
            </div>
        </section>
    );
};

export default StripeCheckout;
