import React from 'react';
import {BsLinkedin, BsWhatsapp} from "react-icons/bs";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FaFacebook, FaInstagram, FaYoutube} from "react-icons/fa";

const SignatureBlock = ({instrDetails, theme}) => {
    const textColor = theme === 'dark' ? 'text-gray-300' : 'text-gray-600';
    const linkColor = theme === 'dark' ? 'hover:text-blue-300' : 'hover:text-blue-600';

    let header_logo;
    if (instrDetails.organization === '') {
        header_logo = '/images/other/logo-header.png';
    } else {
        header_logo = '/images/' + instrDetails.organization + '/logo-' + theme + '.png';
    }

    const social =
        [
            {
                name: 'Facebook',
                href: instrDetails?.facebook_link ? 'https://facebook.com/' + instrDetails?.facebook_link : '#',
                icon: () => (
                    <div className="flex items-center justify-center mt-0.5">
                        <FaFacebook className="h-5 w-5"/>
                    </div>
                ),
            },
            {
                name: 'Instagram',
                href: instrDetails?.instagram_link ? 'https://instagram.com/' + instrDetails?.instagram_link : '#',
                icon: () => (
                    <div className="flex items-center justify-center mt-0.5">
                        <FaInstagram className="h-5 w-5"/>
                    </div>
                ),
            },
            {
                name: 'X',
                href: instrDetails?.x_link ? 'https://x.com/' + instrDetails?.x_link : '#',
                icon: () => (
                    <div className="flex items-center justify-center mt-0.5">
                        <FontAwesomeIcon icon={faXTwitter} className="h-5 w-5"/>
                    </div>
                ),
            },
            {
                name: 'WhatsApp',
                href: instrDetails?.whatsapp_link ? 'https://wa.me/' + instrDetails?.whatsapp_link : '#',
                icon: () => (
                    <div className="flex items-center justify-center mt-0.5">
                        <BsWhatsapp className="h-5 w-5"/>
                    </div>
                ),
            },
            {
                name: 'LinkedIn',
                href: instrDetails?.linkedin_link ? 'https://linkedin.com/in/' + instrDetails?.linkedin_link : '#',
                icon: () => (
                    <div className="flex items-center justify-center mt-0.5">
                        <BsLinkedin className="h-5 w-5"/>
                    </div>
                ),
            },
            {
                name: 'YouTube',
                href: instrDetails?.youtube_link ? 'https://youtube.com/@' + instrDetails?.youtube_link : '#',
                icon: () => (
                    <div className="flex items-center justify-center mt-0.5">
                        <FaYoutube className="h-5 w-5"/>
                    </div>

                ),
            },
        ]

    return (
        <div className={`space-y-8 ${textColor}`}>
            <img
                className="h-12 md:h-20"
                src={header_logo}
                alt="Organization Logo"
            />
            <p className={`leading-6 ${textColor}`}>
                <span>{instrDetails.first_name} {instrDetails.last_name}<br/>
                    {instrDetails.organization} {instrDetails.pro_level}&nbsp;
                    {instrDetails.organization === 'PADI' ? (
                        <a href="https://apps.padi.com/scuba-diving/pro-chek" target="_blank"
                           rel="noopener noreferrer"
                           className="hover:text-blue-600 hover:underline">#{instrDetails.member_number}</a>
                    ) : (
                        <span>#{instrDetails.member_number}</span>
                    )}
                    <br/>
                    <a className={`hover:underline ${linkColor}`} href={`sms:${instrDetails.phone}`}
                       target="_blank" rel="noopener noreferrer">{instrDetails.phone_display}</a><br/>
                    <a className="hover:text-blue-600 hover:underline"
                       href={`mailto:${instrDetails.preferred_email}`} target="_blank"
                       rel="noopener noreferrer">{instrDetails.preferred_email}</a><br/>
                </span>
            </p>
            <div className="flex space-x-6">
                {social.filter(item => item.href !== '#').map((item) => (
                    <a key={item.name} href={item.href} target='_blank' rel="noopener noreferrer"
                       className={theme === 'dark' ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}
                    >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true"/>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default SignatureBlock;
