import '@aws-amplify/ui-react/styles.css';
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import withAuth from "./withAuth";

const SignInPage = () => {
    const lastPage = useSelector((state) => state.navigation.lastPage) || '/';
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userDetails = useSelector((state) => state.auth.userDetails);

    useEffect(() => {
        if (isAuthenticated) {
            const requireUserDetails = location.state?.requireUserDetails;
            const redirectPath = location.state?.redirectPath;

            if (requireUserDetails && !userDetails?.id) {
                navigate('/profile', {state: {redirectPath}});
            } else {
                if (redirectPath) {
                    navigate(redirectPath);
                } else {
                    navigate(lastPage);
                }
            }
        }
    }, [isAuthenticated, userDetails, lastPage, navigate, location.state]);

    return null;
};

export default withAuth(SignInPage);