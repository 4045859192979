import {Fragment, useEffect} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {BiError, BiQuestionMark} from "react-icons/bi";
import {MdAssignmentInd, MdEventAvailable, MdEventBusy, MdSchool} from "react-icons/md";
import {useSelector} from "react-redux";
import {setModalContent} from "../redux/eventsSlice";
import {useDispatch} from "react-redux";
import {LoadingOverlay} from '../containers/LoadingOverlay'
import {FaHourglassHalf} from "react-icons/fa";

export default function RsvpModal({
                                      modalContent,
                                      closeModal,
                                      onCancelEventAttendee,
                                      isRSVPModalOpen,
                                      onNavigateToCoursePage,
                                      onPreRequisiteClick,
                                      onSignMeUpClick,
                                      isBusy,
                                      onFormsAndPaymentsClick
                                  }) {
    const isRegistered = modalContent?.type === 'registered';
    const isConfirmed = modalContent?.type === 'confirmed';
    const isWaitlisted = modalContent?.type === 'waitlisted';
    const isCancelled = modalContent?.type === 'cancelled';
    const buttonText = isConfirmed || isRegistered || isCancelled || isWaitlisted ? 'Ok' : 'Cancel';

    let buttonColorClass;
    if (isRegistered || isConfirmed) {
        buttonColorClass = 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600';
    } else if (isCancelled) {
        buttonColorClass = 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600';
    } else if (isWaitlisted) {
        buttonColorClass = 'bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600';
    } else {
        buttonColorClass = 'bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600';
    }

    const outstandingData = useSelector((state) => state.outstandingData);
    const hasOutstandingNotifications = outstandingData.outstandingForms.length > 0 || outstandingData.outstandingCharges.length > 0;
    const dispatch = useDispatch();

    const getBackground = (type) => {
        const bgColors = {
            'registered': 'bg-green-100',
            'confirmed': 'bg-green-100',
            'error': 'bg-red-100',
            'role': 'bg-blue-100',
            'course': 'bg-blue-100',
            'unrsvp': 'bg-red-100',
            'cancelled': 'bg-red-100'
        };

        return bgColors[type] || 'bg-gray-100';
    }

    const getIcon = (type) => {
        switch (type) {
            case 'confirmed':
            case 'registered':
                return <MdEventAvailable className="h-6 w-6" aria-hidden="true"/>;
            case 'waitlisted':
                return <FaHourglassHalf className="h-6 w-6" aria-hidden="true"/>;
            case 'error':
                return <BiError className="h-6 w-6 text-red-600" aria-hidden="true"/>;
            case 'role':
                return <MdAssignmentInd className="h-6 w-6" aria-hidden="true"/>;
            case 'course':
                return <MdSchool className="h-6 w-6" aria-hidden="true"/>;
            case 'unrsvp':
            case 'cancelled':
                return <MdEventBusy className="h-6 w-6" aria-hidden="true"/>;
            default:
                return <BiQuestionMark className="h-6 w-6 bg-gray-600" aria-hidden="true"/>;
        }
    }

    // This handles modal button clicks.  CalendarPage.js may directly call save directly without having to prompt the user.
    // This could occur is there is only one possible customer_type / course_code combination valid for this user.
    const handleActualClick = (option) => {
        if (modalContent.handleClickType === 'handleUnRSVPClick') {
            onCancelEventAttendee(modalContent.eventId);
            closeModal();
        } else if (modalContent.handleClickType === 'navigateToCoursePage') {
            onNavigateToCoursePage(option.uniqueId);
        } else if (modalContent.handleClickType === 'handlePreRequisiteClick') {
            onPreRequisiteClick(option, modalContent.selectedCriteria);
        } else if (modalContent.handleClickType === 'closeModal') {
            closeModal();
        } else if (modalContent.handleClickType === 'handleSignMeUpClick') {
            onSignMeUpClick(option);
        } else if (modalContent.handleClickType === 'handleFormsAndPaymentsClick') {
            onFormsAndPaymentsClick(option);
        }
    }

    useEffect(() => {
        if (hasOutstandingNotifications && modalContent.type === 'registered') {
            let options = [];
            let message = '';

            if (outstandingData.outstandingForms && outstandingData.outstandingForms.length > 0) {
                options.push({
                    text: "Sign Forms Now",
                    uniqueId: 'sign_forms',
                });
                message += "Please take a moment to complete the necessary forms";
            }

            if (outstandingData.outstandingCharges && outstandingData.outstandingCharges.length > 0) {
                options.push({
                    text: "Pay Now",
                    uniqueId: 'pay_now',
                });
                if (message === '') {
                    message += "Please take a moment to complete your payment. "
                } else {
                    message += ", and make a payment. "
                }
                message += "Whenever forms or payments are due, you'll see a red dot by the notification bell in the " +
                    "upper right corner of the screen. ";
            }

            if (options.length > 0) {
                message = "Almost finished!  If you can't complete this now, you can always come back later using the " +
                    "notification bell in the upper right corner of the screen."
            }

            dispatch(setModalContent({
                message: message,
                options: options,
            }));
        }
    }, [outstandingData]);

    if (!isRSVPModalOpen) {
        return null;
    }

    return (
        <Transition.Root show={modalContent !== ''} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                {isBusy && <LoadingOverlay/>}
                                <div>
                                    <div
                                        className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${getBackground(modalContent?.type)}`}>
                                        {getIcon(modalContent?.type)}
                                    </div>

                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            {modalContent?.title}
                                        </Dialog.Title>
                                        {modalContent?.message && (
                                            <div className="mt-2 text-sm text-gray-600">
                                                {modalContent?.message}
                                            </div>
                                        )}
                                        <div className="mt-5 sm:mt-6">
                                            {modalContent?.options?.map((option, index) => (
                                                <button
                                                    key={option.uniqueId}
                                                    type="button"
                                                    disabled={isBusy}
                                                    className={`mb-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${isBusy ? 'bg-gray-600 cursor-not-allowed' : 'bg-indigo-600 '} ${isBusy ? 'opacity-75' : 'text-white'}`}
                                                    // className="mb-2 w-full inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    onClick={() => handleActualClick(option, modalContent)}
                                                >
                                                    {option.text}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${buttonColorClass}`}
                                        onClick={closeModal}
                                        // disabled={isBusy}
                                    >
                                        {buttonText}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
