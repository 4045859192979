import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "ReActivate",
    description: "The ReActivate Scuba Refresher Program is the engaging, efficient way to refresh  your scuba skills. " +
        "Move quickly through topics you know well, dive deeper on topics where your knowledge may have lapsed."
}

const faqs = [
    {
        question: 'How long does it all take?',
        answer:
            "eLearning takes 2-3 hours and may be followed by a pool and/or ocean session.  The pool session " +
            "and/or ocean session will likely be completed in a single day."
    }
]

let academics = [
    {
        title: 'eLearning',
        body:
            "It's easy to fit a scuba refresher course into your busy schedule. Using a computer or mobile device, " +
            "watch videos of how to perform basic scuba skills and review dive planning and safety procedures - " +
            "whenever, wherever it's convenient for you."
    },
    {
        title: 'Instructor-led Training',
        body:
            "eLearning is required to earn an updated certification card, however, your instructor may offer an " +
            "informal scuba refresher to go over gear setup, essential knowledge and skills, followed by a " +
            "supervised practice session in the pool and/or an ocean dive."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "You may choose to complete a pool session to practice your skills before heading to the ocean."
    },
    {
        title: 'Ocean',
        body: "You'll conduct one or more dives with your instructor to practice your skills and get comfortable " +
            "underwater again."
    }
]

const progressionCourseCodes = ['PADI-AOW'];

const requirements = [
    '10 years old',
    'PADI Scuba Diver or proof of entry-level' +
    ' diver certification from another training organization with four open water dives'
];

const PadiReActivate = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-REACT"
        imageUrl='/images/PADI/Reactivate-Gallery-h-w3FDETyh.jpg'
        courseSlug='/courses/padi-reactivate'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20ReActivate"
    />
);

export default PadiReActivate;
