/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBio = /* GraphQL */ `
  subscription OnCreateBio(
    $filter: ModelSubscriptionBioFilterInput
    $owner: String
  ) {
    onCreateBio(filter: $filter, owner: $owner) {
      id
      content
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBio = /* GraphQL */ `
  subscription OnUpdateBio(
    $filter: ModelSubscriptionBioFilterInput
    $owner: String
  ) {
    onUpdateBio(filter: $filter, owner: $owner) {
      id
      content
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBio = /* GraphQL */ `
  subscription OnDeleteBio(
    $filter: ModelSubscriptionBioFilterInput
    $owner: String
  ) {
    onDeleteBio(filter: $filter, owner: $owner) {
      id
      content
      status
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
