import {CheckIcon} from '@heroicons/react/20/solid'
import {useDispatch, useSelector} from 'react-redux';
import {
    setSelectedPlan,
    fetchSubscriptionDetails,
    updateSubscriptionDetails
} from "../redux/instrSubscriptionSlice";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import GenericModal from "./GenericModal";

const ProspectiveInstrPricingSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const subscriptionDetails = useSelector((state) => state.instrSubscription.subscriptionDetails);
    const selectedPlan = useSelector((state) => state.instrSubscription.selectedPlan);
    const currentSubscriptionId = subscriptionDetails.subscription_id ? subscriptionDetails.subscription_id : null;
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [errorInfo, setErrorInfo] = useState(null);

    const closeModal = () => {
        setShowModal(false);
        setModalContent({});
    };

    function formatDate(date, increment) {
        const localDate = new Date(date.getTime());
        localDate.setDate(localDate.getDate() + increment);  // Expiration is last day of paid term. Following day is when it renews.
        return localDate.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    }

    function getSubscriptionLabel(tier) {
        // tier is the tier object from the tiers array: id: 'subscriptions-#'
        // subscriptionDetails is the current users active subscription: subscription_exp, subscription_id, id_at_expiry

        if (!currentSubscriptionId) return null;
        if (!subscriptionDetails.subscription_id_at_expiry) return null;

        const tierId = parseInt(tier.id.replace('subscriptions-', ''));
        const subExpDate = new Date(subscriptionDetails.subscription_exp * 1000);
        if (currentSubscriptionId === tierId && currentSubscriptionId === subscriptionDetails.subscription_id_at_expiry) {
            return {label: `Renews ${formatDate(subExpDate, 0)}`};
        } else if (currentSubscriptionId === tierId) {
            return {label: `Ends ${formatDate(subExpDate, 0)}`};
        } else if (subscriptionDetails.subscription_id_at_expiry === tierId) {
            return {label: `Begins ${formatDate(subExpDate, 0)}`};
        }
        return null;
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const setAlreadySubscribedModal = (tier) => {
        setModalContent({
            type: 'info',
            title: 'Tier Confirmation',
            body: `You are already subscribed to the ${tier.name} tier.`,
            buttons: [
                {
                    text: 'Close',
                    action: 'close',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                }
            ]
        });
        setShowModal(true);
    };

    const setAlreadyPendingTierChangeModal = (tier) => {
        setModalContent({
            type: 'error',
            title: 'Tier Confirmation',
            body: `You already have a pending change coming up. Please wait until this change takes effect to make another change, or contact us for assistance.`,
            buttons: [
                {
                    text: 'Close',
                    action: 'close',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                },
                {
                    text: 'Contact Support',
                    action: 'contactSupport',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                }
            ]
        });
        setShowModal(true);
    }

    const setInitialFreeTierModal = (tier) => {
        // tier = 'subscriptions-#' where # is the integer corresponding to the tier.id in subscriptions table.
        const tierId = parseInt(tier.id.split('-')[1]);
        dispatch(updateSubscriptionDetails({post_body: {new_tier_id: tierId, action: 'update_local_db'}}));
        setModalContent({
            type: 'success',
            title: 'Tier Confirmation',
            body: `You have successfully subscribed to the ${tier.name} tier.`,
            buttons: [
                {
                    text: 'Close',
                    action: 'close',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                }
            ]
        });
        setShowModal(true);
    };

    const setDowngradeFromAnnualToFreeModal = (tier) => {
        setModalContent({
            type: 'options',
            title: 'Tier Confirmation',
            body: "If you cancel immediately, the months you were active revert to the higher monthly price " +
                "before the refund is calculated. If you cancel at the end of the term, future billing " +
                "will stop and you will revert to the free tier at the end of the current term. You can also " +
                "retain your website with limited functionality for $9/month? We call this the Barnacle Tier.",
            buttons: [
                {
                    text: 'Keep Current Tier',
                    action: 'close',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                },
                {
                    text: 'View Barnacle Tier Details',
                    action: 'barnacleTier',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                },
                {
                    text: 'Switch at End of Term',
                    action: 'paidToFreeAtExp',
                    className: 'bg-orange-600 hover:bg-orange-500 focus-visible:outline-orange-600'
                },
                {
                    text: 'Switch Immediately',
                    action: 'paidToFreeWithRefund',
                    className: 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600'
                },

            ]
        });
        setShowModal(true);
    };

    const setDowngradeFromMonthlyToFreeModal = (tier) => {
        setModalContent({
            type: 'options',
            title: 'Tier Confirmation',
            body: `You are about to downgrade to the ${tier.name} tier. This will remove your website completely and you will lose access to all features, retaining only the free profile in the instructor directory. Would you like to retain your website with limited functionality for $9/month? We call this the Barnacle Tier.`,
            buttons: [
                {
                    text: 'View Barnacle Tier Info',
                    action: 'barnacleTier',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                },
                {
                    text: 'Delete Website',
                    action: 'paidToFreeAtExp',
                    className: 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600'
                }
            ]
        });
        setShowModal(true);
    };

    const setDowngradeFromLimitedToFreeModal = (tier) => {
        // Downgrade from Barnacle to free is different as we won't offer the barnacle tier.
        setModalContent({
            type: 'pending',
            title: 'Tier Confirmation',
            body: `You are about to downgrade to the ${tier.name} tier. This will remove your website completely and you will lose access to all features, retaining only the free profile in the instructor directory.`,
            buttons: [
                {
                    text: 'Keep Current Tier',
                    action: 'close',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                },
                {
                    text: 'Delete Website',
                    action: 'paidToFreeAtExp',
                    className: 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600'
                },
            ]
        });
        setShowModal(true);
    };

    const setDowngradeFromAnnualToMonthlyModal = (tier) => {
        const subExpDate = new Date(subscriptionDetails.subscription_exp * 1000);
        setModalContent({
            type: 'options',
            title: 'Tier Confirmation',
            body: `You can change your auto-renew to monthly billing of $${tier.price} + tax on ${formatDate(subExpDate, 1)}. 
                or you can change it immediately and the months you were active revert to the higher monthly price before the refund is calculated.`,
            buttons: [
                {
                    text: 'Keep Current Tier',
                    action: 'close',
                    className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                },
                {
                    text: 'Switch at End of Term',
                    action: 'newMonthlyAtExp',
                    className: 'bg-orange-600 hover:bg-orange-500 focus-visible:outline-orange-600\n'
                },
                {
                    text: 'Switch Immediately',
                    action: 'annualToMonthlyImmediately',
                    className: 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600'
                },
            ]
        });
        setShowModal(true);
    };

    const handleSelectPlan = async (tier) => {
        //  Initial null to free Coral: setInitialFreeTierModal
        //  null/Coral to Dolphin/Sea Turtle: setNavigateToGateway
        //  Dolphin to Coral: setDowngradeFromAnnualToFreeModal (paidToFreeAtExp, barnacleTier)
        //  TODO: Dolphin to Sea Turtle: setDowngradeFromAnnualToMonthlyModal (newMonthlyAtExp, annualToMonthlyImmediately)
        //  TODO: Dolphin to Barnacle end of term: newMonthlyAtExp
        //  Sea Turtle to Coral: setDowngradeFromMonthlyToFreeModal
        //  TODO: Sea Turtle to Dolphin: navigate to <SubscriptionGateway/>
        //  TODO: Dolphin to Barnacle: Only path is Dolphin to Coral, which prompts limited barnacle tier: setDowngradeFromAnnualToFreeModal
        //  TODO: Sea Turtle to Barnacle: Only path is Sea Turtle to Coral, ...: setDowngradeFromMonthlyToFreeModal
        //  TODO: Delete Account Data: No web option. User would have to email support. Need to bury this in options somewhere. Along with export data.

        const newTierPrice = parseFloat(tier.price);

        dispatch(setSelectedPlan({
            id: tier.id,
            name: tier.name,
            price: tier.price,
            subtotal: tier.price,
            tax_rate: tier.tax_rate,
            frequency: tier.frequency,
            imageSrc: tier.imageSrc,
            imageAlt: tier.imageAlt,
            description: tier.description,
            features: tier.features,
            discount: {code: null, type: null, rate: 0, amount: 0}
        }));

        if (subscriptionDetails.subscription_id !== subscriptionDetails.subscription_id_at_expiry) {
            return setAlreadyPendingTierChangeModal(tier);
        }

        if ("subscriptions-" + subscriptionDetails.subscription_id === tier.id) {
            // User is already subscribed to this tier
            return setAlreadySubscribedModal(tier);
        }

        if (newTierPrice === 0) {  // User selected free tier
            if (!currentSubscriptionId) {  // This is the initial selection
                return setInitialFreeTierModal(tier);
            }

            // User must be on paid tier as we already checked for initial selection and selected same tier currently on.
            //  There is only one free tier.
            if (subscriptionDetails.frequency === 'Annually') {
                // User is downgrading from a paid annual tier to free monthly tier. e.g. Dolphin to Coral.
                //  Ask if they want to switch (cancel) at end of term, or immediately and receive a prorated refund.
                //   Used months revert to the monthly price (Sea Turtle). Or move to Barnacle tier.
                return setDowngradeFromAnnualToFreeModal(tier);
            } else if (subscriptionDetails.frequency === 'Monthly') {
                // User on paid monthly plan
                if (subscriptionDetails.name === 'Barnacle') {
                    // User downgrading from Barnacle to free monthly tier. e.g. Barnacle to Coral.
                    return setDowngradeFromLimitedToFreeModal(tier);
                } else {
                    // User is downgrading from a paid monthly tier to free monthly tier. e.g. Sea Turtle to Coral.
                    return setDowngradeFromMonthlyToFreeModal(tier);
                }
            }
        }

        if (subscriptionDetails.frequency === 'Annually' && tier.frequency === 'Monthly') {
            // User is downgrading from a paid annual tier to a paid monthly tier. e.g. Dolphin to Sea Turtle
            // We need to ride out the current subscription, cancel auto-renew, and start the new subscription at the end of the term.
            return setDowngradeFromAnnualToMonthlyModal(tier);
        }

        navigate('/subscription-gateway');
    };

    // It's extremely rare for this to change, so hard-coding for speed and to avoid unnecessary API calls
    // MUST BE UPDATED TO MATCH DATABASE!
    const tiers = [
        {
            name: 'Coral',
            id: 'subscriptions-3',  // these are hard-coded to match subscriptions.id column in the database
            price: 0,
            // taxes: 0.00,
            tax_rate: 0.04712,
            frequency: 'One-Time',
            imageSrc: '/images/underwater_scene_showcasing_vibrant_coral_reefs.png',
            imageAlt: 'underwater scene showcasing vibrant coral reefs',
            description: 'Just like coral serves as the foundational building block of marine life, start with a basic profile to establish your presence and attract new students. A simple way to get your fins wet.',
            features: [
                'Profile Photo with Location in Search Results',
                'Dynamic Distance Calculation for Prospective Students',
                'Course-Specific Instructor Filtering',
                "Detailed 'About Me' Page",
                'Comprehensive Contact Information',
                'Social Media Links'],
            bestValue: false,
        },
        {
            name: 'Dolphin',
            id: 'subscriptions-2',
            frequency: 'Annually',
            imageSrc: '/images/graceful_dolphin_gliding_through_the_clear_blue_waters.png',
            imageAlt: 'graceful dolphin gliding through the clear blue waters',
            price: 240,
            // taxes: 11.31,  // 4.712% GET
            tax_rate: 0.04712,
            description: 'Dolphins are known for their intelligence and social skills. Dive into the full feature set and make waves in your career with annual savings. This tier is your playground for maximizing earnings and automating tasks.',
            features: [
                'All Coral Features',
                'All Sea Turtle Features',
                'Save 20% with Annual Billing'
            ],
            bestValue: true,
        },
        {
            name: 'Sea Turtle',
            id: 'subscriptions-1',
            price: 25,
            // taxes: 1.18,  // 4.712% GET
            tax_rate: 0.04712,
            frequency: 'Monthly',
            imageSrc: '/images/majestic_sea_turtle_gracefully_navigating_the_underwater.png',
            imageAlt: 'majestic sea turtle gracefully navigating the underwater',
            description: 'Sea Turtles take their time but are incredibly versatile and resilient. Enjoy the same full feature set as Dolphin but at your own pace, paying monthly. Slow and steady can also win the race.',
            features: [
                'All Coral Features',
                'Full-Fledged Personalized Instructor Website',
                'Comprehensive Course Listing with easy Sign-Up',
                'Robust Instructor Portal for Tracking Student Progress',
                'Automated Digital Forms and Paperwork',
                'Digital Wallet Integration',
                'Invoicing Tools Tailored for Employer Requirements'
            ],
            bestValue: false,
        },
    ]

    const barnacle_tier =
        {
            name: 'Barnacle',
            id: 'subscriptions-4',  // these are hard-coded to match subscriptions.id column in the database
            price: 9,
            // taxes: 0.42,  // 4.712% GET
            tax_rate: 0.04712,
            frequency: 'One-Time',
            imageSrc: '/images/barnacle-tier.webp',
            imageAlt: 'barnacle and scuba tanks',
            description: 'Just like coral serves as the foundational building block of marine life, start with a basic profile to establish your presence and attract new students. A simple way to get your fins wet.',
            features: [
                'Profile Photo with Location in Search Results',
                'Dynamic Distance Calculation for Prospective Students',
                'Course-Specific Instructor Filtering',
                "Detailed 'About Me' Page",
                'Comprehensive Contact Information',
                'Social Media Links'],
            bestValue: false,
        }

    useEffect(() => {
        dispatch(fetchSubscriptionDetails());
    }, [dispatch]);

    const buttonActions = {
        barnacleTier: () => handleBarnacleTier(),
        paidToFreeAtExp: () => handlePaidToFreeAtExp(),
        paidToFreeWithRefund: () => handlePaidToFreeWithRefund(),
        newMonthlyAtExp: () => handleNewMonthlyAtExp(),
        annualToMonthlyImmediately: () => handleAnnualToMonthlyImmediately(),
        contactSupport: () => handleContactSupport(),
        close: closeModal
    };

    const handleContactSupport = () => {
        navigate('/support')
    };

    const handleAnnualToMonthlyImmediately = () => {
        // Dolphin to Sea Turtle at the end of current month. Should get refund of unused months after price reverts to monthly.
        //      New subscription starts 30 days from end of current cycle date.
        const new_tier_id = parseInt(selectedPlan.id.split('-')[1]);
        const post_body = {
            new_tier_id,
            cancel_current: 'end_of_30_day_cycle',
            new_subscription_start: 'end_of_current'
        };
        console.log(post_body);
        dispatch(updateSubscriptionDetails({post_body}));
        closeModal();
    };

    const handleNewMonthlyAtExp = async () => {
        // Dolphin to Sea Turtle at end of term, Dolphin to Barnacle, Sea Turtle to Barnacle
        // 1. Cancel auto-renew, 2. Start new subscription at end of term
        const new_tier_id = parseInt(selectedPlan.id.split('-')[1]);
        const post_body = {
            new_tier_id,
            cancel_current: 'at_period_end',
            new_subscription_start: 'end_of_current'
        };
        console.log(post_body);
        dispatch(updateSubscriptionDetails({post_body}));
        closeModal();
    };

    const handleBarnacleTier = () => {
        navigate('/barnacle-tier');
        closeModal();
    };

    const handlePaidToFreeAtExp = async () => {
        const new_tier_id = parseInt(selectedPlan.id.split('-')[1]);
        dispatch(updateSubscriptionDetails({post_body: {new_tier_id, cancel_current: 'at_period_end'}}));
        closeModal();
    };

    const handlePaidToFreeWithRefund = () => {
        console.log('Paid to Free with Refund')
        closeModal();
    };

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div id="pricing" className="py-24 sm:pt-48">
            {showModal && (
                <GenericModal
                    isOpen={showModal}
                    closeModal={closeModal}
                    modalContent={modalContent}
                    buttonActions={buttonActions}
                />
            )}
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Dive More, Grind Less
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Unlock the full potential of your professional scuba career with tools that maximize your earnings
                    and streamline your operations. Forge direct connections with customers to fill empty seats and
                    boost your income, while automating administrative tasks to focus on what you do best—teaching and
                    diving.
                </p>
                <div
                    className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {tiers.map((tier, tierIdx) => {
                        const labelInfo = getSubscriptionLabel(tier);
                        return (
                            <div
                                key={tier.id}
                                className={classNames(
                                    tier.bestValue ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                                    tierIdx === 0 ? 'lg:rounded-r-none' : '',
                                    tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                                    'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
                                )}
                            >
                                <div>
                                    {labelInfo && (
                                        <p className="rounded-full px-2.5 py-1 mb-2 text-xs text-center font-semibold leading-5 bg-gray-200 text-gray-800">
                                            {labelInfo.label}
                                        </p>
                                    )}
                                    <div className="flex items-center justify-between gap-x-4">
                                        <h3
                                            id={tier.id}
                                            className={classNames(
                                                tier.bestValue ? 'text-indigo-600' : 'text-gray-900',
                                                'text-lg font-semibold leading-8'
                                            )}
                                        >
                                            {tier.name}
                                        </h3>
                                        {tier.bestValue ? (
                                            <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                                                Best Value
                                            </p>
                                        ) : null}
                                    </div>
                                    <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                                        ${tier.price}
                                    </span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">
                                            {tier.frequency === 'Annually' ? `/year ($${tier.price / 12}/month)` : "/month"}
                                    </span>
                                    </p>
                                    <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                        {tier.features.map((feature) => (
                                            <li key={feature} className="flex gap-x-3">
                                                <CheckIcon className="h-6 w-5 flex-none text-indigo-600"
                                                           aria-hidden="true"/>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button
                                    onClick={() => handleSelectPlan(tier)}
                                    aria-describedby={tier.id}
                                    className={classNames(
                                        tier.bestValue
                                            ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                                            : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                                        'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                    )}
                                >
                                    Select plan
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default ProspectiveInstrPricingSection;