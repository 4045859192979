import React, { useState } from 'react';

const TransactionListButtons = ({ onFilterChange }) => {
    const buttons = [
        "Initiated",
        "Verified",
        "Refunded",
        "Cancelled",
        "Venmo",
        "Zelle",
        "Apple Cash",
        "Cash App",
        "Stripe"
    ];

    const [activeButtons, setActiveButtons] = useState({});

    const handleButtonClick = (button) => {
        const isActive = !!activeButtons[button];
        const newActiveButtons = { ...activeButtons };

        if (isActive) {
            delete newActiveButtons[button];
        } else {
            const isStatusFilter = ["Initiated", "Verified", "Refunded", "Cancelled"].includes(button);
            const isMethodFilter = ["Venmo", "Zelle", "Apple Cash", "Cash App", "Stripe"].includes(button);

            // Clear previous status or method filters if a new one is selected
            if (isStatusFilter) {
                Object.keys(newActiveButtons).forEach((key) => {
                    if (["Initiated", "Verified", "Refunded", "Cancelled"].includes(key)) {
                        delete newActiveButtons[key];
                    }
                });
            } else if (isMethodFilter) {
                Object.keys(newActiveButtons).forEach((key) => {
                    if (["Venmo", "Zelle", "Apple Cash", "Cash App", "Stripe"].includes(key)) {
                        delete newActiveButtons[key];
                    }
                });
            }

            newActiveButtons[button] = true;
        }

        setActiveButtons(newActiveButtons);
        onFilterChange(Object.keys(newActiveButtons));
    };

    return (
        <div className="flex flex-wrap mt-1 mx-1">
            {buttons.map((button, index) => {
                const isFirst = index === 0;
                const isLast = index === buttons.length - 1;
                const isActive = !!activeButtons[button];

                return (
                    <button
                        key={index}
                        type="button"
                        onClick={() => handleButtonClick(button)}
                        className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                            isFirst ? 'rounded-l-md' : ''
                        } ${isLast ? 'rounded-r-md' : ''} ${!isFirst ? '-ml-px' : ''} ${
                            isActive ? 'bg-blue-500 text-white' : 'bg-white text-gray-900'
                        }`}
                    >
                        {button}
                    </button>
                );
            })}
        </div>
    );
};

export default TransactionListButtons;
