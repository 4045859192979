import { createSlice } from "@reduxjs/toolkit";

const urlSlice = createSlice({
    name: "url",
    initialState: {
        currentURL: ''
    },
    reducers: {
        setCurrentURL: (state, action) => {
            state.currentURL = action.payload;
        }
    }
});

export const { setCurrentURL } = urlSlice.actions;

export default urlSlice.reducer;
