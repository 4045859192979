import React from 'react';
import { BiLogoFacebookCircle, BiLogoInstagram, BiLogoWhatsapp, BiLogoYoutube, BiLogoLinkedinSquare } from 'react-icons/bi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

const SocialMediaForm = ({ formData, handleChange }) => {
    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
                Social Media
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Enter only the username, handle, or number for each social media
                platform. We'll automatically generate the link for you.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2 sm:col-start-1">
                    <label htmlFor="facebook_link"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <BiLogoFacebookCircle className="mr-1"/>Facebook
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://facebook.com/</span>
                            <input
                                type="text"
                                name="facebook_link"
                                value={formData.facebook_link || ''}
                                onChange={handleChange}
                                id="facebook_link"
                                autoComplete="text"
                                placeholder="username"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="instagram_link"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <BiLogoInstagram className="mr-1"/>Instagram
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://instagram.com/</span>
                            <input
                                type="text"
                                name="instagram_link"
                                value={formData.instagram_link || ''}
                                onChange={handleChange}
                                id="instagram_link"
                                autoComplete="text"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="x_link"
                           className="block text-sm font-medium leading-6 text-gray-900">
                        <FontAwesomeIcon icon={faXTwitter}/>
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://x.com/</span>
                            <input
                                type="text"
                                name="x_link"
                                value={formData.x_link || ''}
                                onChange={handleChange}
                                id="x_link"
                                autoComplete="text"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-1">
                    <label htmlFor="whatsapp_link"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <BiLogoWhatsapp className="mr-1"/>WhatsApp
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://wa.me/</span>
                            <input
                                type="text"
                                name="whatsapp_link"
                                value={formData.whatsapp_link || ''}
                                onChange={handleChange}
                                id="whatsapp_link"
                                autoComplete="text"
                                placeholder="number"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="youtube_link"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <BiLogoYoutube className="mr-1"/>YouTube Channel
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://youtube.com/@</span>
                            <input
                                type="text"
                                name="youtube_link"
                                value={formData.youtube_link || ''}
                                onChange={handleChange}
                                id="youtube_link"
                                autoComplete="text"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="linkedin_link"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <BiLogoLinkedinSquare className="mr-1"/>LinkedIn
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://linkedin.com/in/</span>
                            <input
                                type="text"
                                name="linkedin_link"
                                value={formData.linkedin_link || ''}
                                onChange={handleChange}
                                id="linkedin_link"
                                autoComplete="text"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialMediaForm;
