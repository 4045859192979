import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {get} from "aws-amplify/api";

export const fetchEventTemplates = createAsyncThunk('eventTemplates/fetchEventTemplates', async ({instr_id}, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-event-templates';
        const options = {
            queryParams: {instr_id}
        }
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});

const eventTemplatesSlice = createSlice({
    name: 'eventTemplates',
    initialState: {
        eventTemplates: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventTemplates.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchEventTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTemplates = action.payload;
                state.error = null;
            })
            .addCase(fetchEventTemplates.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default eventTemplatesSlice.reducer;
