import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import EditCertModal from '../components/EditCertModal';
import {fetchCourses} from "../redux/coursesSlice";
import {post} from "aws-amplify/api";
import {LoadingSpinner} from "./LoadingSpinner";
import {fetchStudents} from "../redux/studentsSlice";
import { FaRegEdit } from "react-icons/fa";


const Certifications = () => {
    const {id} = useParams();
    const student = useSelector((state) =>
        state.students.studentList.find((student) => student.id === parseInt(id))
    );
    const studentsStatus = useSelector((state) => state.students.status);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCert, setSelectedCert] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [errorInfo, setErrorInfo] = useState(null);
    const courseOptions = useSelector((state) => state.courses.courses);
    const memoizedCourseOptions = useMemo(() => {
        // Map and sort the course options
        return courseOptions
            .map(course => ({
                value: course.code,
                label: `${course.name} (${course.code})`
            }))
            .sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            });
    }, [courseOptions]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourses());
    }, [dispatch]);

    if (!student) {
        if (studentsStatus === 'loading') {
            return <LoadingSpinner isLoading={studentsStatus === 'loading'}/>
        } else if (studentsStatus === 'idle') {
            dispatch(fetchStudents());
        } else {
            return <div>Student not found</div>;
        }
    }

    const certs = student?.certifications;

    const handleClipboard = () => {
        navigator.clipboard.readText().then(clipboard => {
            const options = {
                method: 'POST',
                body: {clipboard, studentId: student.id},
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            saveCertification(options).then(() => {
                dispatch(fetchStudents());
            });
        });
    };

    const handleAddClick = () => {
        setSelectedCert({
            course_code: '',
            cert_number: '',
            cert_date: '',
            equivalency: false,
            verified: false
        });
        setIsEditing(false);
        setIsModalOpen(true);
    };

    const handleEditClick = (cert) => {
        setSelectedCert(cert);
        setIsEditing(true);
        setIsModalOpen(true);
    };

    const handleSave = (updatedCert) => {
        const options = {
            method: 'POST',
            body: {cert: updatedCert, studentId: student.id},
            headers: {
                'Content-Type': 'application/json',
            }
        };

        saveCertification(options).then(() => {
            dispatch(fetchStudents());
        });
        setIsModalOpen(false);
    };

    const saveCertification = async (options) => {
        const apiName = 'InstrOfScubaAPI'
        const path = '/save-certification'

        try {
            const {body} = await post({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            setErrorInfo('Certifications-handleSave-/save-certification\n' + error);
            return null;
        }
    };

    const handleDelete = (cert) => {
        const options = {
            method: 'POST',
            body: {cert, studentId: student.id, delete: true},
            headers: {
                'Content-Type': 'application/json',
            }
        };

        saveCertification(options).then(() => {
            dispatch(fetchStudents());
        });
        setIsModalOpen(false);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard')
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    if (errorInfo) {
        console.log(errorInfo);
        throw errorInfo;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Certifications</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Students can self-report their own certifications. Verify them here and add any additional certs
                        you locate to tailor their experience on your website. Adding a core cert (OW, AOW, Rescue,
                        etc.)
                        will automatically add the lower level prerequisite certs. Equivalency is used to indicate that
                        the
                        certification was issued by a different agency but meets the same standards. <a
                        href='https://pro.padi.com/me/divechek'
                        target='_blank'
                        className='text-blue-500 underline'
                        rel='noopener noreferrer'>PADI Dive Chek</a>
                    </p>
                    <h1 className="mt-4 text-base font-semibold leading-6 text-gray-900">
                        <button
                            onClick={() => copyToClipboard(student?.first_name)}
                            className="mt-4 text-base font-semibold leading-6 text-gray-900"
                        >
                            {student?.first_name}
                        </button>
                        &nbsp;
                        {student?.middle_init ? student?.middle_init + ' ' : ''}
                        <button
                            onClick={() => copyToClipboard(student?.last_name)}
                            className="mt-4 text-base font-semibold leading-6 text-gray-900"
                        >
                            {student?.last_name}
                        </button>

                    </h1>
                    <button
                        onClick={() => copyToClipboard(student?.preferred_email)}
                        className="mt-2 text-sm text-gray-700"
                    >
                        {student?.preferred_email}
                    </button>
                    <p className="mt-2 text-sm text-gray-700">DOB: {student?.date_of_birth}</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        onClick={handleAddClick}
                        className="mb-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Add Cert
                    </button>
                    <button
                        type="button"
                        onClick={handleClipboard}
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Process Clipboard
                    </button>
                </div>
            </div>
            <div className="mx-1 mt-8 sm:-mx-0">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Course Code
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Cert Number
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                            Cert Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Equivalency
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Verified
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                    {certs?.map((cert) => (
                        <tr key={cert.course_code}>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                {cert.course_code}
                                <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">Cert Number</dt>
                                    <dd className="mt-1 truncate text-gray-700">{cert.cert_number}</dd>
                                    <dt className="sr-only sm:hidden">Cert Date</dt>
                                    <dd className="mt-1 truncate text-gray-500 sm:hidden">{cert.cert_date}</dd>
                                </dl>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{cert.cert_number}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{cert.cert_date}</td>
                            <td className="px-3 py-4 text-sm text-gray-500">{cert.equivalency ? "True" : "False"}</td>
                            <td className="px-3 py-4 text-sm text-gray-500">{cert.verified ? "True" : "False"}</td>
                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <button
                                    onClick={() => handleEditClick(cert)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                >
                                    <FaRegEdit/><span className="sr-only">, {cert.course_code}</span>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {selectedCert && (
                <EditCertModal
                    isOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    cert={selectedCert}
                    onSave={handleSave}
                    onDelete={handleDelete}
                    isEditing={isEditing}
                    courseOptions={memoizedCourseOptions}
                />
            )}
        </div>
    );
};

export default Certifications;
