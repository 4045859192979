import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {updateSubscriptionDetails} from "../redux/instrSubscriptionSlice";
import {fetchInstrDetails} from "../redux/instrDetailsSlice";

export default function SubscriptionSuccess() {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const navigate = useNavigate();
    // console.log(sessionId);

    useEffect(() => {
        if (sessionId) {
            dispatch(updateSubscriptionDetails({post_body: {sessionId, action: 'subscription_success'}}));
            // Website conditionally renders based on featureFlags retrieved from instrDetails.
            //  Need to refresh instrDetails to get the latest featureFlags.
            dispatch(fetchInstrDetails());
            navigate('/manage-subscription');
        }
    }, [sessionId, dispatch]);

    if (errorInfo) {
        throw errorInfo;
    }

    return null;
}
