import PadiCourse from "./PadiCourse";
import React from "react";

const product = {
    title: "PADI Adventure Diver",
    description: "The PADI Adventure Diver course is a subset of the PADI Advanced Open Water Diver course. " +
        "Have you always wanted to try night diving, drift diving, or improve your navigation or buoyancy skills? " +
        "There's a long list of scuba adventures you can take part in during this program.  " +
        "Complete three Adventure Dives and you earn the Adventure Diver certification. " +
        "It's a great opportunity to work with your instructor to build your scuba skills and gain more confidence."
}

const faqs = [
    {
        question: 'How long does it all take?',
        answer:
            "About 1-2 days, not including the Academics/eLearning. Two dives per day is typical, but it is possible to " +
            "complete all three dives in one day. "
    },
    {
        question: 'There are so many adventures to choose from, how do I choose?',
        answer:
            "Your instructor will help you choose three Adventure Dives based on the groups shared interest, or may " +
            "have a pre-planned set of dives that are ideal for the local environment. If you do have a specific " +
            "interest, let your instructor know and they will do their best to accommodate. "
    }
]

let academics = [
    {
        title: 'eLearning',
        body:
            "You can use the Advanced Open Water Diver eLearning to learn about the Adventure Dives you will be " +
            "conducting. This is a self-paced online course. The following " +
            "Adventures are available through eLearning: Underwater Navigation, Deep Diving, Altitude Diving, " +
            "Boat Diving, Digital Underwater Imaging, Drift Diving, Dry Suit Diving, Fish Identification, " +
            "Night Diving, Peak Performance Buoyancy, Search and Recovery, Underwater Naturalist, and Wreck Diving. " +
            "Additional Adventures are available through Instructor-led Training."
    },
    {
        title: 'Paper-based Self Study',
        body:
            "Alternatively, you can use the Adventures in Diving manual to learn about the Adventure Dives you will " +
            "be conducting. This can be done at your own pace.  Your instructor " +
            "will verify that you have completed the required knowledge reviews and will review any questions you " +
            "may have. There is no dedicated classroom time for this option, as the reviews typically take place " +
            "at the dive site."
    },
    {
        title: 'Instructor-led Training',
        body:
            "For any Adventure Dives not available through eLearning or Paper-based Self Study, your instructor " +
            "will teach you everything you need to know before conducting a knowledge review. " +
            "This is typically done at the dive site, but may be " +
            "completed in a classroom setting if the dive site does not have a suitable location. "
    }
]

const dives = [
    {
        title: 'Ocean',
        body: "You will conduct any three Adventure Dives from the Advanced Open Water Diver course. " +
            "Two dives per day is typical, but it is possible to complete all three dives in one day. " +
            "Talk to your instructor if this is something you are interested in."
    }
]

const progressionCourseCodes = ['PADI-AOW', 'PADI-RESCUE'];

const requirements = [
    '10 years old',
    'PADI Open Water Diver',
];

const PadiAdventure = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-ADVENTURE"
        imageUrl='/images/PADI/adventure-diver_0.jpg'
        courseSlug='/courses/padi-adventure-diver'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Adventure%20Diver%20Course"
    />
);

export default PadiAdventure;
