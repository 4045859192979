import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';

const initialState = {
    outstandingForms: [],
    outstandingCharges: [],
    lastFetchedForms: null,
    lastFetchedCharges: null,
    status: 'idle',
    error: null
};

export const fetchOutstandingData = createAsyncThunk(
    // fetchOutstandingBalance and fetchOutstandingForms are combined into one API call
    // These retrieve outstanding forms and charges for the currently logged-in user
    // and matches it to the websites instructor via instr_id.

    'outstandingData/fetchOutstandingData',
    async (instr_id, {rejectWithValue}) => {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-outstanding-data';
        const options = {
            queryParams: {instr_id}
        }
        try {
            const {body} = await get({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const outstandingDataSlice = createSlice({
    name: 'outstandingData',
    initialState,
    reducers: {
        setOutstandingForms: (state, action) => {
            state.outstandingForms = action.payload;
            state.lastFetchedForms = Date.now();
        },
        setOutstandingCharges: (state, action) => {
            state.outstandingCharges = action.payload;
            state.lastFetchedCharges = Date.now();
        },
        resetOutstandingData: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOutstandingData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOutstandingData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.outstandingForms = action.payload.outstanding_forms;
                state.lastFetchedForms = Date.now();
                state.outstandingCharges = action.payload.outstanding_charges;
                state.lastFetchedCharges = Date.now();
            })
            .addCase(fetchOutstandingData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const {setOutstandingForms, setOutstandingCharges, resetOutstandingData} = outstandingDataSlice.actions;
export default outstandingDataSlice.reducer;
