import React from 'react';
import {post} from "aws-amplify/api";
import {MyFallbackComponent} from "./MyFallbackComponent";
import store from "../redux/store";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, errorInfo: null};
        this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    }

    resetErrorBoundary() {
        this.setState({hasError: false, errorInfo: null});
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    async componentDidCatch(error, errorInfo) {
        this.setState({errorInfo});

        const reduxState = store.getState();
        const prettyReduxState = JSON.stringify(reduxState, null, 2);

        const apiName = 'InstrOfScubaAPI'
        const path = '/log-frontend-error'
        const options = {
            method: 'POST',
            body: {
                error: error.toString(),
                errorInfo: errorInfo.componentStack,
                reduxState: prettyReduxState
            },
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            await post({apiName, path, options});
        } catch (error) {
            console.log(error);
        } finally {
            console.log('Error logged to backend');
        }
    }

    render() {
        if (this.state.hasError) {
            return <MyFallbackComponent
                errorInfo={this.state.errorInfo}
                resetErrorBoundary={this.resetErrorBoundary}
            />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
