import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {LoadingSpinner} from "./LoadingSpinner";
import {Link} from "react-router-dom";
import ContactIcons from "../components/ContactIcons";
import {fetchTransactions} from "../redux/transactionsSlice";
import TransactionListButtons from "../components/TransactionListButtons";
import {getImageUrl} from "../utils/sanitizeFunctions";

export default function Transactions() {
    // TODO: add ability to validate payments, enter manual payments, cash payments, and refund payments.
    // Should be able to search by digital wallet usernames as well.
    // Should be able to add discount codes to transactions of any status and refund the difference.
    // Should be able to click student name to go to student details.

    const dispatch = useDispatch();
    const {transactions, status, error} = useSelector((state) => state.transactions);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedMethod, setSelectedMethod] = useState('');

    const matchesSearchTerm = (transaction, searchTerm) => {
        // Search by total_amt, invoice_number, payment_method, status, student.first_name, student_last_name,
        // student.email, student.phone
        const lowerSearchTerm = searchTerm.toLowerCase();
        const matchesText = (text) => text && text.toLowerCase().includes(lowerSearchTerm);

        return (
            matchesText(transaction.first_name) ||
            matchesText(transaction.last_name) ||
            matchesText(transaction.invoice_number) ||
            matchesText(transaction.total_amt.toString())
        );
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);

        const statusFilters = ["Initiated", "Verified", "Refunded", "Cancelled"];
        const methodFilters = ["Venmo", "Zelle", "Apple Cash", "Cash App", "Stripe"];

        const selectedStatusFilter = newFilters.find(filter => statusFilters.includes(filter)) || '';
        const selectedMethodFilter = newFilters.find(filter => methodFilters.includes(filter)) || '';

        setSelectedStatus(selectedStatusFilter);
        setSelectedMethod(selectedMethodFilter);
    };

    const filteredTransactions = transactions.filter((transaction) => {
        if (searchTerm && !matchesSearchTerm(transaction, searchTerm)) {
            return false;
        }

        if (selectedStatus && transaction.status && transaction.status.toLowerCase() !== selectedStatus.toLowerCase()) {
            return false;
        }

        if (selectedMethod && transaction.payment_method && transaction.payment_method.toLowerCase() !== selectedMethod.toLowerCase()) {
            return false;
        }

        return filters.every((filter) => {
            switch (filter) {
                case "Initiated":
                    return transaction.status.toLowerCase() === "initiated";
                case "Verified":
                    return transaction.status.toLowerCase() === "verified";
                case "Refunded":
                    return transaction.status.toLowerCase() === "refunded";
                case "Cancelled":
                    return transaction.status.toLowerCase() === "cancelled";
                case "Venmo":
                    return transaction.payment_method.toLowerCase() === "venmo";
                case "Zelle":
                    return transaction.payment_method.toLowerCase() === "zelle";
                case "Apple Cash":
                    return transaction.payment_method.toLowerCase() === "apple cash";
                case "Cash App":
                    return transaction.payment_method.toLowerCase() === "cash app";
                case "Stripe":
                    return transaction.payment_method.toLowerCase() === "stripe";
                default:
                    return true;
            }
        });
    });

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchTransactions());
        }
    }, [status, dispatch]);

    useEffect(() => {
        if (status === 'failed') {
            throw new Error(`/get-transactions\n${error}`);
        }
    }, [status, error]);

    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
            case 'initiated':
                return 'bg-orange-50 text-orange-700 ring-orange-600/20';
            case 'verified':
                return 'bg-green-50 text-green-700 ring-green-600/20';
            case 'refunded':
                return 'bg-red-50 text-red-700 ring-red-600/20';
            case 'cancelled':
                return 'bg-red-50 text-red-700 ring-red-600/20';
            default:
                return 'bg-gray-50 text-gray-700 ring-gray-600/20';
        }
    };

    const capitalizeFirstLetter = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <>
            <LoadingSpinner isLoading={status === 'loading'}/>
            <div className="p-4">
                <div className="flex items-center space-x-2">
                    <input
                        type="search"
                        id="search"
                        name="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by invoice number, total, first name, or last name."
                        className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
            </div>
            <TransactionListButtons onFilterChange={handleFilterChange}/>
            <div className="mt-8 flow-root">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300 w-full">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Products
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Invoice #
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Method
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Subtotal
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Total
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {filteredTransactions.map((t) => {
                                const descriptionElements = t.user_charges.map((charge, index) => (
                                    <div key={index}>{charge.composite_title}</div>
                                ));
                                const capitalizedStatus = capitalizeFirstLetter(t.status);
                                const statusClass = getStatusClass(t.status);
                                const totalBreakdown = (
                                    <div>
                                        <div>Subtotal: ${t.total_products_amt}</div>
                                        <div>Discount: ${t.discount_amt} {t.discount_codes && t.discount_codes.length > 0 && `(${t.discount_codes.join(', ')})`}</div>
                                        <div>Shipping: ${t.shipping_amt}</div>
                                        <div>Tax: ${t.tax_amt}</div>
                                    </div>
                                );

                                return (
                                    <tr key={t.transaction_id}>
                                        <td className="whitespace-normal break-words py-5 pl-4 pr-3 text-sm sm:pl-0">
                                            <div className="flex items-center">
                                                <div className="h-11 w-11 flex-shrink-0">
                                                    <img className="h-11 w-11 rounded-full"
                                                         src={getImageUrl('userPortrait' + t.student_id + '.jpeg')}
                                                         alt="Portrait"/>
                                                </div>
                                                <div className="ml-4">
                                                    <div
                                                        className="font-medium text-gray-900">{t.first_name} {t.last_name}</div>
                                                    <div className="flex gap-2 mt-0.5">
                                                        <ContactIcons email={t.preferred_email} phone={t.phone}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-normal break-words px-3 py-5 text-sm text-gray-500">
                                            <div className="text-gray-900">{descriptionElements}</div>
                                        </td>
                                        <td className="whitespace-normal break-words px-3 py-5 text-sm">{t.invoice_number}</td>
                                        <td className="whitespace-normal break-words px-3 py-5 text-sm">{t.payment_method}</td>
                                        <td className="whitespace-normal break-words px-3 py-5 text-sm">{totalBreakdown}</td>
                                        <td className="whitespace-normal break-words px-3 py-5 text-sm">{currencyFormatter.format(t.total_amt)}</td>
                                        <td className="whitespace-normal break-words px-3 py-5 text-sm text-gray-500">
                                            <Link to={`/transactions/${t.transaction_id}`}
                                                  className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statusClass}`}>
                                                {capitalizedStatus}
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
