import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Self-Reliant Diver Course",
    description: "With proper training, equipment and the right attitude to accept the risks involved in independent " +
        "diving, an experience diver can responsibly engage in dives without a buddy. Self-reliant diving is an " +
        "adventure activity that is not for everyone, but does have its place. If you have the mental discipline " +
        "and commitment to learn and follow self-reliant diving techniques, you'll bolster your skills and " +
        "confidence when diving alone, in a dive pair or as part of a team."
}

const faqs = [
    {
        question: "Do I need special equipment?",
        answer: "Yes, you'll need a DSMB, redundant air supply, redundant dive computer, and redundant surface signaling " +
            "device.  You'll also need to carry a backup mask."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "eLearning does not yet exist for this course.",
    },
    {
        title: 'Instructor-led Training',
        body: "Learning how to compensate for situations you would normally handle with a buddy is the focus of the " +
            "Self-Reliant Diver course. This includes proper dive planning using air consumption rate calculations, " +
            "life support system redundancy, and independent management of dive emergencies."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are three dives required for this course, typically conducted in one or two days. " +
            "During these dives you'll practice performing an air consumption rate swim to gather information for later " +
            "calculation, switching to a redundant air supply system during simulated emergency situations, swimming " +
            "without a mask, navigating to various points, and deploying a DSMB from depth."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '18 years old',
    '100 logged dives',
    'PADI Advanced Open Water Diver',
    'Complete a skills assessment prior to the course'
];

const PadiSelfReliant = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-SR"
        imageUrl='/images/PADI/self reliant diver_0.jpg'
        courseSlug='/courses/padi-self-reliant'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Self-Reliant%20Diver%20Course"
    />
);

export default PadiSelfReliant;
