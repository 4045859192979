import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Rescue Diver",
    description: "The PADI Rescue Diver course will change the way you dive - in the best possible way. " +
        "Learn to identify and fix minor issues before they become big problems, gain a lot of confidence, and have " +
        "serious fun along the way."
}

const faqs = [
    {
        question: 'How long does it all take?',
        answer:
            "About 4-7 days, including 8-12 hours of eLearning.  The eLearning can be done at your own pace, " +
            "but must be completed before the in-water training.  The in-water training is typically done over " +
            "two days, one for rescue exercises and one for scenario practice.  You may have additional practice " +
            "in a pool before demonstrating your new skills in the open ocean."

    }
]

let academics = [
    {
        title: 'eLearning',
        body:
            "Online learning makes it easy to fit scuba lessons into a busy schedule.  Complete the knowledge " +
            "development portion of the course at your own pace, anytime, anywhere.  There are five sections " +
            "to complete, each with a knowledge review at the end.  You will need to complete all five sections " +
            "before you can sign up for the in-water training.  The eLearning typically takes 8-12 hours to complete."
    },
    {
        title: 'Instructor-led Training',
        body:
            "If classroom learning is more your style, you can complete the knowledge development portion of " +
            "the course with an instructor.  This is typically done over two days, with a knowledge review and " +
            "exam prior to beginning in-water training."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "Although not required, you may practice rescue exercises in a pool prior to demonstrating your skills in " +
            "the open ocean."
    },
    {
        title: 'Ocean',
        body: "The course includes ten rescue exercises and two rescue scenarios.  These must be spread out over " +
            "at least two days.  You will demonstrate your ability to handle a variety of rescue situations " +
            "including panicked divers, unresponsive divers, and missing divers.  You will also learn how to " +
            "prevent problems before they occur, and how to handle problems on the surface."
    }
]

const progressionCourseCodes = ['PADI-DM', 'PADI-PSD', 'PADI-MSD'];

const requirements = [
    '12 years old',
    'PADI Adventure Diver',
    'Completed Underwater Navigation Adventure Dive',
    'EFR Primary and Secondary Care training within 24 months'
];

const PadiRescue = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-RESCUE"
        imageUrl='/images/PADI/Rescue-Gallery-hero--lFaWM8k9.jpg'
        courseSlug='/courses/padi-rescue-diver'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20ReActivate"
    />
);

export default PadiRescue;
