import React, {useEffect, useState} from 'react';
import ContactIcons from "../components/ContactIcons";
import {getImageUrl} from "../utils/sanitizeFunctions";

const TransactionInstrForm = ({onSubmit, transactionId, instrId, total, currencyFormatter, transaction}) => {
    const [formData, setFormData] = useState({
        invoiceNumber: transaction.invoice_number,
        paymentDTG: new Date().toISOString().slice(0, 16),
        amountPaid: total,
        paymentMethod: transaction.payment_method,
        transactionId: transactionId,
        paymentNotes: '',
        paymentStatus: transaction.status,
        instrId: instrId || '',
        walletId: '',
        walletUsername: ''
    });

    // useEffect(() => {
    //     console.log(formData);
    //     console.log(transaction);
    // }, [formData])
    //

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            ...formData,
            amountPaid: parseFloat(formData.amountPaid),
        });
    };

    return (
        <section aria-labelledby="transaction-form-heading"
                 className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex rounded-2xl">
            <h2 id="transaction-form-heading" className="sr-only">
                Transaction Details Form
            </h2>

            <form onSubmit={handleSubmit} className="flex-auto overflow-y-auto px-6 bg-gray-50 rounded-2xl">
                <div className="flex flex-col space-y-6 py-6">
                    <div className="flex items-center space-x-4">
                        <div className="h-11 w-11 flex-shrink-0">
                            <img
                                className="h-11 w-11 rounded-full"
                                src={getImageUrl('userPortrait-' + transaction.student_id + '.jpeg')}
                                alt="Portrait"
                            />
                        </div>
                        <div>
                            <div className="font-medium text-gray-900">
                                {transaction.first_name} {transaction.last_name}
                            </div>
                            <div className="flex gap-2 mt-0.5">
                                <ContactIcons email={transaction.preferred_email} phone={transaction.phone}/>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="block text-gray-700 text-sm font-medium">Invoice</label>
                        <input
                            type="text"
                            name="invoiceNumber"
                            value={formData.invoiceNumber}
                            onChange={handleChange}
                            className="shadow appearance-none bg-gray-50 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                            disabled
                        />
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="block text-gray-700 text-sm font-medium">Amount Paid</label>
                        <input
                            type="number"
                            name="amountPaid"
                            value={formData.amountPaid}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                        />
                        <p className="text-sm text-gray-600">
                            {formData.amountPaid ? currencyFormatter.format(formData.amountPaid) : ''}
                        </p>
                    </div>

                    <div className="flex flex-col space-y-2">
                    <label className="block text-gray-700 text-sm font-medium">Payment Method</label>
                        <select
                            name="paymentMethod"
                            value={formData.paymentMethod}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                        >
                            <option value="">Select Method</option>
                            <option value="Venmo">Venmo</option>
                            <option value="Zelle">Zelle</option>
                            <option value="Apple Cash">Apple Cash</option>
                            <option value="Cash App">Cash App</option>
                            <option value="cash">Cash</option>
                        </select>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="block text-gray-700 text-sm font-medium">Payment Date-Time</label>
                        <input
                            type="datetime-local"
                            name="paymentDTG"
                            value={formData.paymentDTG}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                        />
                    </div>

                    {/*<div className="flex flex-col space-y-2">*/}
                    {/*    <label className="block text-gray-700 text-sm font-medium">Wallet ID</label>*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        name="walletId"*/}
                    {/*        value={formData.walletId}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className="flex flex-col space-y-2">*/}
                    {/*    <label className="block text-gray-700 text-sm font-medium">Wallet Username</label>*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        name="walletUsername"*/}
                    {/*        value={formData.walletUsername}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="flex flex-col space-y-2">
                        <label className="block text-gray-700 text-sm font-medium">Payment Notes</label>
                        <input
                            type="text"
                            name="paymentNotes"
                            value={formData.paymentNotes}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></input>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="block text-gray-700 text-sm font-medium">Payment Status</label>
                        <select
                            name="paymentStatus"
                            value={formData.paymentStatus}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                        >
                            <option value="initiated">Initiated</option>
                            <option value="verified">Verified</option>
                            <option value="refunded">Refunded</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-500 py-2 text-white  hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                    > Save
                    </button>
                </div>
            </form>
        </section>
    );
};

export default TransactionInstrForm;
