import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        lastPage: null,
    },
    reducers: {
        setLastPage: (state, action) => {
            state.lastPage = action.payload;
        },
    },
});

export const { setLastPage } = navigationSlice.actions;
export default navigationSlice.reducer;