import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import authMiddleware from "./authMiddleware";
import urlReducer from "./urlSlice";
import instrDetailsReducer from "./instrDetailsSlice";
import visitedCoursesReducer from "./visitedCoursesSlice";
import navigationReducer from "./navSlice";
import courseSignupInfoReducer from "./courseSignupInfoSlice";
import diverMedicalFormAnswersReducer from "./diverMedicalFormAnswersSlice";
import instructorsReducer from "./instructorsSlice";
import instrSubscriptionReducer from "./instrSubscriptionSlice";
import bioReducer from "./bioSlice";
import paymentGatewayReducer from "./paymentGatewaySlice";
import eventsReducer from "./eventsSlice";
import locationReducer from "./locationSlice";
import coursesReducer from "./coursesSlice";
import customerTypesReducer from "./customerTypesSlice";
import calendarReducer from "./calendarSlice";
import eventTemplatesReducer from "./eventTemplatesSlice";
import formsReducer from "./formsSlice";
import outstandingDataReducer from "./outstandingDataSlice";
import outstandingStudentDataReducer from "./outstandingStudentDataSlice";
import studentsSlice from "./studentsSlice";
import transactionsSlice from "./transactionsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        url: urlReducer,
        instrDetails: instrDetailsReducer,
        visitedCourses: visitedCoursesReducer,
        navigation: navigationReducer,
        courseSignupInfo: courseSignupInfoReducer,
        diverMedicalFormAnswers: diverMedicalFormAnswersReducer,
        instructors: instructorsReducer,
        instrSubscription: instrSubscriptionReducer,
        bio: bioReducer,
        paymentGateway: paymentGatewayReducer,
        events: eventsReducer,
        locations: locationReducer,
        courses: coursesReducer,
        customerTypes: customerTypesReducer,
        calendar: calendarReducer,
        eventTemplates: eventTemplatesReducer,
        forms: formsReducer,
        outstandingData: outstandingDataReducer,
        // outstandingStudentData: outstandingStudentDataReducer,
        students: studentsSlice,
        transactions: transactionsSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
});

export default store;