// Filename: PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-4">Effective date: August 24, 2023</p>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                <p className="mb-4">Welcome to BAMCIS LLC ("we", "our" or "us"). This Privacy Policy sets forth
                    the manner in which we collect, use, maintain, and disclose information collected from scuba
                    instructors and their customers who use our website located at www.instructorscuba.com, as well as
                    subdomains such as chris.instructorofscuba.com, john.instructorofscuba.com, or custom domains like
                    scubabychris.com ("Sites"). Please read this Privacy Policy carefully to understand our practices
                    regarding your information and how we will treat it.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
            </section>

            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-2">2.1 Personal Information</h2>
                <p className="mb-4">We may collect personal information including, but not limited to, the user's photo,
                    full name, email address, mailing address, date of birth, gender, scuba gear equipment sizes,
                    preferred payment methods, list of owned equipment, health information, etc., when users register on
                    our Site, fill out a form, or engage with other activities, services, features, or resources we make
                    available.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-2">2.3 Non-Personal Information</h2>
                <p className="mb-4">We may collect non-personal information about users whenever they interact with our
                    Site. Non-personal information may include browser name, device type, and technical information
                    about users' means of connection to our Site.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">3. Use of Google Analytics</h2>
                <p className="mb-4">We use Google Analytics to analyze the use of our Site and to gather demographic
                    information about our user base as a whole. Google Analytics collects only the IP address assigned
                    to you on the date you visit our Site, not your name or other identifying information. Google's
                    ability to use and share information collected by Google Analytics is restricted by the Google
                    Analytics Terms of Use and the Google Privacy Policy. You can prevent Google Analytics from
                    recognizing you on return visits to this Site by disabling cookies on your browser.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">4. How We Use Your Information</h2>
                <p className="mb-4">We use the information we collect in various ways, including to:</p>
                <ul className="list-inside list-disc pl-5">
                    <li>Provide, operate, and maintain our Site</li>
                    <li>Improve, personalize, and expand our Site</li>
                    <li>Understand and analyze how you use our Site</li>
                    <li>Develop new products, services, features, and functionality</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">5. Disclosure of Your Information</h2>
                <p className="mb-4">We do not sell any user information. Photo, first name, and last initial may be
                    shown to other users who are enrolled in the same or similar events. Photos will be shared with PADI
                    for printing on certification cards. All form data, including health questions from the Diver
                    Medical Questionnaire, are shared with your instructor via email and will remain available in their
                    instructor portal.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">6. Security</h2>
                <p className="mb-4">We employ appropriate security measures to protect your information. However, no
                    method of transmission over the Internet or method of electronic storage is 100% secure, and we
                    cannot guarantee its absolute security.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">7. Changes to This Privacy Policy</h2>
                <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any
                    changes by posting the new Privacy Policy on this page.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
                <p className="mb-4">If you have any questions or comments about this Privacy Policy, please contact us
                    at support@instructofscuba.com.</p>
                <address className="italic">
                    BAMCIS LLC<br/>
                    1050 Queen St #100<br/>
                    Honolulu, HI 96814
                </address>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
