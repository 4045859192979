import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {
    initializeAnswers2,
    saveAnswers2,
    selectAnswers1,
    selectAnswers2
} from '../../redux/diverMedicalFormAnswersSlice';

const DiverMedicalForm2 = () => {
    const answers1 = useSelector(selectAnswers1);
    const answers2 = useSelector(selectAnswers2);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (answers1 && answers1.some(answer => answer === null)) {
            navigate('/forms/diver-medical-form');
        }
    }, [answers1, navigate]);

    useEffect(() => {
        if (answers1[0] === 'no' &&
            answers1[1] === 'no' &&
            answers1[3] === 'no' &&
            answers1[5] === 'no' &&
            answers1[6] === 'no' &&
            answers1[7] === 'no' &&
            answers1[8] === 'no')
        {
            const defaultAnswers2 = Array(followUpQuestions.length).fill('no');
            dispatch(initializeAnswers2(defaultAnswers2.length));
            dispatch(saveAnswers2(defaultAnswers2));
            navigate('/forms/diver-medical-form-3', {replace: true});
        }
    }, [answers1, dispatch, navigate]);

    const handleButtonChange = (questionIndex, value) => {
        const newAnswers = [...answers2];
        newAnswers[questionIndex] = value;
        dispatch(saveAnswers2(newAnswers));

        document.getElementById(`question-${questionIndex}`).classList.remove('border-red-500');
        document.getElementById(`question-${questionIndex}`).classList.add('border-gray-300')
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const secondUnansweredQuestionIndex = answers2.findIndex(answer => answer === null);

        if (secondUnansweredQuestionIndex !== -1) {
            document.getElementById(`question-${secondUnansweredQuestionIndex}`).scrollIntoView({behavior: 'smooth'});
            document.getElementById(`question-${secondUnansweredQuestionIndex}`).classList.add('border-red-500');
        } else {
            dispatch(saveAnswers2(answers2));
            navigate('/forms/diver-medical-form-3');
        }
    };

    const followUpQuestions = [
        {
            text: 'Chest surgery, heart surgery, heart valve surgery, an implantable medical device (eg, stent, pacemaker, neurostimulator), pneumothorax, ' +
                'and/or chronic lung disease.', refIndex: 0
        },
        {
            text: 'Asthma, wheezing, severe allergies, hay fever or congested airways within the last 12 months that limits my physical activity/exercise.',
            refIndex: 0
        },
        {
            text: 'A problem or illness involving my heart such as: angina, chest pain on exertion, heart failure, immersion pulmonary edema, heart attack or stroke, ' +
                'OR am taking medication for any heart condition.', refIndex: 0
        },
        {
            text: 'Recurrent bronchitis and currently coughing within the past 12 months, OR have been diagnosed with emphysema.',
            refIndex: 0
        },
        {
            text: 'Symptoms affecting my lungs, breathing, heart and/or blood in the last 30 days that impair my physical or mental performance.',
            refIndex: 0
        },
        {text: 'I currently smoke or inhale nicotine by other means.', refIndex: 1},
        {text: 'I have a high cholesterol level.', refIndex: 1},
        {text: 'I have high blood pressure.', refIndex: 1},
        {
            text: 'I have had a close blood relative die suddenly or of cardiac disease or stroke before the age of 50, OR have a family history of heart disease ' +
                'before age 50 (including abnormal heart rhythms, coronary artery disease or cardiomyopathy).',
            refIndex: 1
        },
        {text: 'Sinus surgery within the last 6 months.', refIndex: 3},
        {text: 'Ear disease or ear surgery, hearing loss, or problems with balance.', refIndex: 3},
        {text: 'Recurrent sinusitis within the past 12 months.', refIndex: 3},
        {text: 'Eye surgery within the past 3 months.', refIndex: 3},
        {text: 'Head injury with loss of consciousness within the past 5 years.', refIndex: 5},
        {text: 'Persistent neurologic injury or disease.', refIndex: 5},
        {
            text: 'Recurring migraine headaches within the past 12 months, or take medications to prevent them.',
            refIndex: 5
        },
        {text: 'Blackouts or fainting (full/partial loss of consciousness) within the last 5 years.', refIndex: 5},
        {text: 'Epilepsy, seizures, or convulsions, OR take medications to prevent them.', refIndex: 5},
        {
            text: 'Behavioral health, mental or psychological problems requiring medical/psychiatric treatment.',
            refIndex: 6
        },
        {
            text: 'Major depression, suicidal ideation, panic attacks, uncontrolled bipolar disorder requiring medication/psychiatric treatment.',
            refIndex: 6
        },
        {
            text: 'Been diagnosed with a mental health condition or a learning/developmental disorder that requires ongoing care or special accommodation.',
            refIndex: 6
        },
        {text: 'An addiction to drugs or alcohol requiring treatment within the last 5 years.', refIndex: 6},
        {text: 'Recurrent back problems in the last 6 months that limit my everyday activity.', refIndex: 7},
        {text: 'Back or spinal surgery within the last 12 months.', refIndex: 7},
        {
            text: 'Diabetes, either drug or diet controlled, OR gestational diabetes within the last 12 months.',
            refIndex: 7
        },
        {text: 'An uncorrected hernia that limits my physical abilities.', refIndex: 7},
        {text: 'Active or untreated ulcers, problem wounds, or ulcer surgery within the last 6 months.', refIndex: 7},
        {text: 'Ostomy surgery and do not have medical clearance to swim or engage in physical activity.', refIndex: 8},
        {text: 'Dehydration requiring medical intervention within the last 7 days.', refIndex: 8},
        {
            text: 'Active or untreated stomach or intestinal ulcers or ulcer surgery within the last 6 months.',
            refIndex: 8
        },
        {text: 'Frequent heartburn, regurgitation, or gastroesophageal reflux disease (GERD).', refIndex: 8},
        {text: 'Active or uncontrolled ulcerative colitis or Crohn’s disease.', refIndex: 8},
        {text: 'Bariatric surgery within the last 12 months.', refIndex: 8}
    ];

    const questions = followUpQuestions.filter(q => answers1[q.refIndex] === 'yes');

    if (answers2 === null) {
        dispatch(initializeAnswers2(questions.length));
    }

    return (
        <>
            <section className="bg-gray-100 font-sans">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 items-center gap-8">
                        <h2 className="text-2xl sm:text-3xl">
                            <span className="font-extrabold text-gray-900">Diver Medical</span>
                            <span
                                className="text-gray-900 whitespace-nowrap"> | Participant Questionnaire Continued</span>
                        </h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            {questions.map((question, i) => (
                                <div key={i} id={`question-${i}`}
                                     className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                                    <div className="text-lg text-gray-700">{question.text}</div>
                                    {answers2 && (
                                        <span className="isolate inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => handleButtonChange(i, 'yes')}
                                        className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                            answers2[i] === 'yes' ? 'text-white bg-red-500' : 'text-gray-900 bg-white'
                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-l-md`}
                                    >
                                      Yes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleButtonChange(i, 'no')}
                                        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                            answers2[i] === 'no' ? 'text-white bg-green-500' : 'text-gray-900 bg-white'
                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-r-md`}
                                    >
                                      No
                                    </button>
                                  </span>
                                    )}
                                </div>
                            ))}
                            <div className="text-right">
                                <button type="submit"
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DiverMedicalForm2;
