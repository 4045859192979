import ProspectiveInstrHero from '../components/ProspectiveInstrHeroSection';
import ProspectiveInstrGoals from '../components/ProspectiveInstrGoalsSection';
import {useEffect} from "react";
import {Link} from "react-router-dom";

const ProspectiveInstrLandingPage = () => {
    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div>
            <div className="bg-white">
                <main className="isolate">
                    <ProspectiveInstrHero/>
                    <ProspectiveInstrGoals/>
                    <div className="bg-indigo-100 py-6">
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-2xl font-bold mb-4">Ready to Dive In?</h2>
                            <p className="text-lg mb-4">Join us and elevate your scuba instructing journey.</p>
                            <Link
                                to="/instructor-profile"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Get Started for Free
                            </Link>
                            <a href="https://bamcis.instructorofscuba.com" target="_blank" rel="noopener noreferrer"
                               className="text-sm font-semibold leading-6 text-gray-900 ml-4">
                                Live demo <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ProspectiveInstrLandingPage;
