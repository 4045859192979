import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import CeadForm from './forms/CeadForm';
import LiabilityReleaseForm from './forms/LiabilityReleaseForm';
import DiverActivitiesForm from "./forms/DiverActivitiesForm";
import DiverMedicalForm from "./forms/DiverMedicalForm";
import SafeDivingForm from "./forms/SafeDivingForm";
import {setLastPage} from "../redux/navSlice";
import {LoadingSpinner} from "./LoadingSpinner";
import withAuth from "./withAuth";

const OutstandingForms = () => {
    const outstandingForms = useSelector(state => state.outstandingData.outstandingForms);
    const [currentFormIndex, setCurrentFormIndex] = useState(0);
    const status = useSelector(state => state.outstandingData.status);
    const formComponents = {
        'liability-release-form': LiabilityReleaseForm,
        'cead-form': CeadForm,
        'diver-activities-form': DiverActivitiesForm,
        'diver-medical-form': DiverMedicalForm,
        'safe-diving-form': SafeDivingForm
    };
    const FormComponent = outstandingForms.length > 0 ? formComponents[outstandingForms[currentFormIndex].form_slug] : null;

    const handleFormCompletion = () => {
        // Logic to mark the current form as complete
        setCurrentFormIndex(currentFormIndex + 1);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    return (
        <div>
            <LoadingSpinner isLoading={status === 'loading'}/>
            {/* TODO: Modify below to use the same buttons we use in Transactions and Student List for button filtering */}
            {/*  When the user selects a button, it jumps to that form, when they complete the form, that button */}
            {/*  disappears and it automatically loads the next form */}

            {/* Show list of all forms, if there is more than 1 */}
            {/*{outstandingForms.length > 1 && (*/}
            {/*    outstandingForms.map(form => (*/}
            {/*        <div key={form.form_slug} className="shadow overflow-hidden sm:rounded-lg">*/}
            {/*            <div className="px-4 py-5 sm:px-6">*/}
            {/*                <h3 className="text-lg font-medium leading-6 text-gray-900">{form.form_short_name}</h3>*/}
            {/*                <p className="mt-1 max-w-2xl text-sm text-gray-500">{form.course_code}</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ))*/}
            {/*)}*/}

            {FormComponent && <FormComponent onComplete={handleFormCompletion}/>}
            {currentFormIndex >= outstandingForms.length && (
                <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Surface
                            Interval Over!</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">You've surfaced through the sea of forms.
                            Gear up, your underwater adventure awaits!
                        </p>
                    </div>
                </main>
            )}
        </div>
    );
};

export default withAuth(OutstandingForms);
