import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Search and Recovery",
    description: "The next time someone loses an item underwater, you can be the hero that finds the missing object. " +
        "In the PADI® Search and Recovery course, you’ll learn how to find lost objects underwater. " +
        "You’ll practice different types of underwater search patterns and learn how to use a lift bag as you plan " +
        "and execute mock search operations."
}

const faqs = [
    {
        question: "Are there legal considerations?",
        answer: "Yes, laws regarding the recovery of lost or sunken property vary by jurisdiction. The course will " +
            "cover legal considerations, but always consult local authorities if you're planning a real recovery operation."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Complete the independent study part of your course whenever, wherever it’s convenient for you. " +
            "Work at your own pace, online or " +
            "offline, using a computer or mobile device. Connect with your instructor for inwater training, or " +
            "whenever you have a question.  The eLearning typically takes 2-4 hours to complete.",
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are four dives required for this course that must be conducted over at least two days."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '12 years old',
    'PADI Advanced Open Water Diver OR Underwater Navigation specialty certification'
];

const PadiSearchRecovery = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-SERE"
        imageUrl='/images/PADI/SERE1-v62AB2Hv.jpg'
        courseSlug='/courses/padi-search-and-recovery'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Search%20and%20Recovery%20Diver%20Course"
    />
);

export default PadiSearchRecovery;
