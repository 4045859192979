import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
    initializeFacilityName,
    saveAnswers3,
    selectAnswers1,
    selectAnswers2,
    selectAnswers3
} from '../../redux/diverMedicalFormAnswersSlice';
import SignatureCanvas from 'react-signature-canvas';
import {useNavigate} from "react-router-dom";
import {setLastPage} from "../../redux/navSlice";
import {post} from "aws-amplify/api";
import ResultModal from "../ResultModal";

const DiverMedicalForm3 = () => {
    const answers1 = useSelector(selectAnswers1);
    const answers2 = useSelector(selectAnswers2);
    const answers3 = useSelector(selectAnswers3);
    const navigate = useNavigate();
    const loginEmail = useSelector((state) => state.auth.loginEmail);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const store_resort = instrDetails?.store_resort;

    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);

    const getPrimaryProLevelInfo = (proLevelInfo) => {
        return proLevelInfo ? proLevelInfo.find(info => info.foremost) || {} : {};
    }

    const primaryProLevelInfo = getPrimaryProLevelInfo(instrDetails?.pro_level_info);

    useEffect(() => {
        if (store_resort) {
            dispatch(initializeFacilityName(store_resort));
        }
    }, [dispatch, store_resort]);

    useEffect(() => {
        if (answers1 === null || answers2 === null) {
            navigate('/forms/diver-medical-form');
        }
    }, [answers1, answers2, navigate]);

    useEffect(() => {
        const userDetailsChanged = userDetails && (
            userDetails.last_name !== answers3.lastName ||
            userDetails.first_name !== answers3.firstName ||
            userDetails.middle_init !== answers3.middleInitial ||
            userDetails.date_of_birth !== answers3.birthday ||
            userDetails.email !== answers3.email
        );

        if (userDetailsChanged) {
            console.log(userDetails);
            dispatch(saveAnswers3({
                lastName: userDetails.last_name,
                firstName: userDetails.first_name,
                middleInitial: userDetails.middle_init,
                birthday: userDetails.date_of_birth,
                email: userDetails.email
            }));
        }
    }, [userDetails, dispatch, answers3.lastName, answers3.firstName, answers3.middleInitial, answers3.birthday, answers3.email]);


    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const requiresApproval = answers1 && answers2 && (answers1[2] === 'yes' || answers1[4] === 'yes' || answers1[9] === 'yes' || answers2.includes('yes'));
    const sigCanvas = useRef(null);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const checkboxElement = document.getElementById('eSignConsent');
        if (e.target.checked) {
            checkboxElement.classList.remove('border-red-500');
            checkboxElement.classList.add('border-gray-300');
        } else {
            checkboxElement.classList.add('border-red-500');
            checkboxElement.classList.remove('border-gray-300');
        }
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });

    const closeModal = () => {
        setShowModal(false);
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            document.getElementById(`sigCanvas`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`sigCanvas`).classList.add('border-red-500');
            document.getElementById(`sigCanvas`).classList.remove('border-gray-500');
            return;
        } else {
            document.getElementById(`sigCanvas`).classList.add('border-gray-500');
            document.getElementById(`sigCanvas`).classList.remove('border-red-500');
        }

        if (!answers3.signatureDate) {
            document.getElementById(`signatureDate`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`signatureDate`).classList.add('border-red-500');
            return;
        }

        if (!answers3.firstName) {
            document.getElementById(`firstName`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`firstName`).classList.add('border-red-500');
            return;
        }

        if (!answers3.lastName) {
            document.getElementById(`lastName`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`lastName`).classList.add('border-red-500');
            return;
        }

        if (!answers3.birthday) {
            document.getElementById(`birthday`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`birthday`).classList.add('border-red-500');
            return;
        }

        if (!isCheckboxChecked) {
            document.getElementById(`eSignConsent`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`eSignConsent`).classList.add('border-red-500');
            return;
        }

        if (sigCanvas.current) {
            const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setIsSubmitting(true);

            const dataToSend = {
                answers1,
                answers2,
                answers3,
                signature,
                instr_id: instrDetails.instr_id,
                instr_full_name: instrDetails.first_name + ' ' + instrDetails.last_name,
                instr_email: instrDetails.preferred_email,
                form_slug: "diver-medical-form",
                student_login_email: loginEmail,
                student_preferred_email: userDetails.preferred_email,
                store_resort: instrDetails.store_resort,
                formData: {
                    form_short_name: 'Medical',
                }
            }

            const apiName = 'InstrOfScubaAPI'
            const path = '/esign'
            const options = {
                method: 'POST',
                body: dataToSend,
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = await body.json();
                if (data.success) {
                    setResultModalObj({
                        type: 'success',
                        title: 'Document Submitted!',
                        body: 'Your document has been signed, sealed, and delivered.'
                    });
                    setShowModal(true);
                }
            } catch (error) {
                if (error.response && error.response.status === 504) {
                    // Handling specific 504 Gateway Timeout error
                    setResultModalObj({
                        type: 'error',
                        title: 'Timeout Error',
                        body: 'The process is taking longer than expected and may or may not complete successfully.  ' +
                            'Please check your email for confirmation.  If not received, please contact support.'
                    });
                    setShowModal(true);
                } else {
                    setErrorInfo('handleSubmit - /esign\n' + error);
                }
            }
            setIsSubmitting(false);
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                canvas.width = canvas.offsetWidth;
                canvas.height = canvas.offsetHeight;
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/signin', {state: {redirectPath: window.location.pathname}});
    };

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        dispatch(saveAnswers3({ [name]: value} ));
    };

    useEffect(() => {
        async function emailMedicalForm() {
            if (requiresApproval && userDetails) {
                const apiName = 'InstrOfScubaAPI'
                const path = '/email-medical-form'
                const options = {
                    method: 'POST',
                    body: {
                        studentDetails: {
                            firstName: answers3.firstName,
                            lastName: answers3.last_name,
                            login_email: loginEmail,
                            preferred_email: userDetails.email
                        },
                        instrDetails: {
                            email: instrDetails.email,
                            first_name: instrDetails.first_name,
                            last_name: instrDetails.last_name,
                            organization: primaryProLevelInfo.pro_level_organization,
                            pro_level: primaryProLevelInfo.pro_level,
                            member_number: primaryProLevelInfo.member_number,
                            phone_display: instrDetails.phone_display,
                        }
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                };

                try {
                    await post({apiName, path, options});
                } catch (error) {
                    setErrorInfo('emailMedicalForm - /email-medical-form\n' + error);
                }
            }
        }

        emailMedicalForm();
    }, [requiresApproval, instrDetails])

    useEffect(() => {
        if (errorInfo) {
            throw errorInfo;
        }
    }, [errorInfo])

    return (
        <>
            <section className="bg-gray-100 font-sans">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 items-center gap-8">
                        <h2 className="text-2xl sm:text-3xl">
                            <span className="font-extrabold text-gray-900">Diver Medical</span>
                            <span
                                className="text-gray-900 whitespace-nowrap"> | Participant Questionnaire Continued</span>
                        </h2>
                    </div>
                    {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                    {requiresApproval ? (
                        <div>
                            <p className="mt-4">
                                Based on your answers, participation in a diving course requires your physician's
                                approval.
                                The questionnaire and Medical Examiner's Evaluation Form have been emailed to you, and
                                may also be <a className="hover:underline text-blue-600"
                                               href="/files/10346E_Diver_Medical_Participant_Questionnaire_2022_02_1.pdf"
                                               download>downloaded here</a>.
                                Please print and take them to your physician for completion.
                                Once completed, email them back to your instructor in order to continue your training.
                            </p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <p className="mt-4 mb-4 text-lg text-gray-600 text-justify">
                                <span>
                                Based on your answers, a medical evaluation is not required. Please read and agree to
                                the participant statement below by signing and dating it.</span>
                                <br/><br/>
                                <span className="font-bold">
                                Participant Statement:</span> I have answered all questions honestly, and understand
                                that I accept responsibility for any consequences resulting from any questions I may
                                have
                                answered inaccurately or for my failure to disclose any existing or past health
                                conditions.
                            </p>
                            <div className="flex flex-col">
                                Sign here with your mouse, finger, or stylus:
                                <div id="sigCanvas" className="relative w-full h-48 border border-gray-300 rounded">
                                    <SignatureCanvas ref={sigCanvas}
                                                     canvasProps={{className: 'signature-canvas w-full h-full'}}/>
                                </div>
                                <div className="flex justify-between mt-4 items-start">
                                    <button type="button" onClick={clearSignature}
                                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Clear
                                    </button>
                                    <input type="date" id="signatureDate" name="signatureDate" value={answers3.signatureDate}
                                           onChange={handleInputChange}
                                           className="border rounded-md py-0"/>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-0">
                                    <label className="block text-sm font-medium text-gray-700">Participant Name</label>
                                    <div className="grid grid-cols-3 gap-2">
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={answers3?.firstName || ''}
                                            onChange={handleInputChange}
                                            placeholder="First Name"
                                            className="w-full border rounded-md"
                                        />
                                        <input
                                            type="text"
                                            name="middleInitial"
                                            value={answers3?.middleInitial || ''}
                                            onChange={handleInputChange}
                                            placeholder="M.I."
                                            className="w-full border rounded-md"
                                        />
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={answers3.lastName || ''}
                                            onChange={handleInputChange}
                                            placeholder="Last Name"
                                            className="w-full border rounded-md"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="birthday" className="block text-sm font-medium text-gray-700">
                                        Date of Birth
                                    </label>
                                    <input
                                        type="date"
                                        id="birthday"
                                        name="birthday"
                                        value={answers3.birthday || ''}
                                        onChange={handleInputChange}
                                        placeholder="Birthday"
                                        className="w-full rounded-md"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="instructorName" className="block text-sm font-medium text-gray-700">
                                        Instructor Name
                                    </label>
                                    <input
                                        type="text"
                                        id="instructorName"
                                        name="instructorName"
                                        value={instrDetails ? instrDetails.first_name + ' ' + instrDetails.last_name : ''}
                                        onChange={handleInputChange}
                                        placeholder="Instructor Name"
                                        className="w-full rounded-md"
                                        disabled
                                    />
                                </div>
                                <div>
                                    <label htmlFor="facilityName" className="block text-sm font-medium text-gray-700">
                                        Facility Name
                                    </label>
                                    <input
                                        type="text"
                                        id="facilityName"
                                        name="facilityName"
                                        value={answers3.facilityName}
                                        onChange={handleInputChange}
                                        placeholder="Facility Name (optional)"
                                        className="w-full rounded-md"
                                    />
                                </div>
                            </div>

                            <div key="eSignConsent" id="eSignConsent"
                                 className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                                <div className="text-lg text-gray-700">
                                    By checking here, you are consenting to the use of your electronic signature in lieu
                                    of an original signature on paper. You have the right to request that you sign a
                                    paper copy instead. By checking here, you are waving that right. If you prefer to
                                    print a paper copy,
                                    please <a className="hover:underline text-blue-600"
                                              href="/files/10346E_Diver_Medical_Participant_Questionnaire_2022_02_1.pdf"
                                              download>download the form here</a>. After consent, you
                                    may, upon written request to us, obtain a paper copy of an electronic record. No fee
                                    will be charged for such copy and no special hardware or software is required to
                                    view it. Your agreement to use an electronic signature with us for any documents
                                    will continue until such time as you notify us in writing that you no longer wish to
                                    use an electronic signature. There is no penalty for withdrawing your consent. You
                                    should always make sure that we have a current email address in order to contact you
                                    regarding any changes, if necessary.
                                </div>
                                <div id="checkbox-question" className="isolate inline-flex rounded-md shadow-sm">
                                    <input
                                        type="checkbox"
                                        id="checkboxEsignConsent"
                                        name="checkboxEsignConsent"
                                        checked={isCheckboxChecked}
                                        onChange={handleCheckboxChange}
                                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                            </div>
                            {isAuthenticated ? (
                                <button type="submit"
                                        disabled={isSubmitting}
                                        className="float-right rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >{isSubmitting ? 'Submitting...' : 'Agree & Submit'}
                                </button>
                            ) : (
                                <button type="button" onClick={handleLoginClick}
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 float-right">
                                    Please Login to E-Sign this Document
                                </button>
                            )}
                        </form>
                    )}
                </div>
            </section>
        </>
    );
};

export default DiverMedicalForm3;
