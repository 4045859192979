import React from 'react';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/20/solid'


const CalendarHeader = ({ firstDateInDisplayedMonth, handlePrevMonth, handleNextMonth }) => {
    return (
        <div className="flex items-center text-gray-900">
            <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handlePrevMonth}
            >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
                {firstDateInDisplayedMonth ? firstDateInDisplayedMonth.toLocaleString('default', {
                    month: 'long',
                    year: 'numeric'
                }) : ''}
            </div>
            <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handleNextMonth}
            >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    );
};

export default CalendarHeader;
