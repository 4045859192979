import {Carousel, Typography, Button} from "@material-tailwind/react";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {setCourseSignupInfo} from "../redux/courseSignupInfoSlice";
import {useDispatch, useSelector} from "react-redux";

const courseDetails = {
    'PADI-OW': {
        image_url: '/images/PADI/ow-card.jpg',
        image_portrait_url: '/images/PADI/hero-1p.jpeg',
        title: 'PADI Open Water Diver',
        description: 'The PADI Open Water Diver course is the world’s most popular scuba course, and has introduced millions of people to the adventurous diving lifestyle. Start your scuba certification online.',
    },
    'PADI-AOW': {
        image_url: '/images/PADI/AOW-Gallery-hero-2-dsKTrUz_.jpg',
        image_portrait_url: '/images/PADI/hero-6p.jpeg',
        title: 'PADI Advanced Open Water Diver',
        description: 'Exploration, Excitement, Experiences. They’re what the PADI Advanced Open Water Diver course is all about. And no, you don’t have to be “advanced” to take it – it’s designed so you can go straight into it after the PADI Open Water Diver course.',
    },
    'PADI-RESCUE': {
        image_url: '/images/PADI/Rescue-Gallery-hero--lFaWM8k9.jpg',
        image_portrait_url: '/images/PADI/hero-7p.jpeg',
        title: 'PADI Rescue Diver',
        description: '“Challenging” and “rewarding” best describe the PADI Rescue Diver course. Building upon what you’ve already learned, this course expands on what you already know about how to prevent problems, and how to manage them if they occur.',
    },
    'PADI-DM': {
        image_url: '/images/PADI/hero-dm.jpg',
        image_portrait_url: '/images/PADI/hero-7p.jpeg',
        title: 'PADI Divemaster',
        description: 'Love scuba diving? Want to share it with others on a whole new level? Take the PADI Divemaster course and do what you love to do as a career. Scuba divers look up to divemasters because they are leaders who mentor and motivate others.',
    },
    'PADI-EAN': {
        image_url: '/images/PADI/Eax-Gallery-hero-1-pYvUsPuz.jpg',
        image_portrait_url: '/images/PADI/hero-7p.jpeg',
        title: 'PADI Enriched Air Nitrox',
        description: 'The PADI Enriched Air Diver course is PADI’s most popular specialty scuba diving course, and it’s easy to see why. Scuba diving with enriched air nitrox gives you more no decompression dive time. This means more time underwater, especially on repetitive scuba dives.',
    }
}

export default function HeroCarouselPADI() {
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const featureFlags = instrDetails?.enabled_features?.[0] || {
        show_calendar: false,
        show_contact: false,
        instructor_portal: false,
        manage_calendar: false,
        show_course_signup: false
    };
    const carouselCourses = instrDetails.courses.filter(course => course.home_carousel);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        });
    }, []);

    const dispatch = useDispatch();

    return (
        <Carousel autoplay={true} autoplayDelay={7000} loop={true} className={isPortrait ? "": "rounded-xl"}>
            {carouselCourses.map((course) => {
                const courseDetail = courseDetails[course.course_code];  // Look up the course details

                return (
                    <div key={course.course_code} className="relative h-full w-full">
                        <img
                            src={isPortrait ? courseDetail.image_portrait_url : courseDetail.image_url}
                            alt={courseDetail.alt_text}
                            className="h-full w-full object-cover"
                        />
                        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/50">
                            <div className="w-full text-center md:w-2/4 md:text-left">
                                <Typography
                                    variant="h1"
                                    color="white"
                                    className="mb-4 text-3xl md:text-4xl lg:text-5xl font-padi"
                                >
                                    {courseDetail.title}
                                </Typography>
                                <Typography
                                    variant="lead"
                                    color="white"
                                    className="mb-12 opacity-80 font-padi"
                                >
                                    {courseDetail.description}
                                </Typography>
                                <div className="flex justify-center md:justify-start gap-2">
                                    {featureFlags.show_course_signup ? (
                                        <>
                                            <Link
                                                onClick={() => {
                                                    dispatch(setCourseSignupInfo({
                                                        course_code: course.course_code,
                                                        course_name: course.course_name
                                                    }));
                                                }}
                                                to='/course-signup'>
                                                <Button size="lg" color="white" className="font-padi">
                                                    Sign Up
                                                </Button>
                                            </Link>
                                            <Link to={`/courses/${course.course_slug}`}>
                                                <Button size="lg" color="white" variant="text" className="font-padi">
                                                    Learn More
                                                </Button>
                                            </Link>
                                        </>
                                    ) : (
                                        <Link to={`/courses/${course.course_slug}`}>
                                            <Button size="lg" color="white" className="font-padi">
                                                Learn More
                                            </Button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Carousel>
    );
}