import {RadioGroup} from '@headlessui/react'

export default function SizesRadioGroup({sizes, selected, onSelectionChange}) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <RadioGroup value={selected} onChange={onSelectionChange}>
            <RadioGroup.Label className="sr-only">Sizes</RadioGroup.Label>
            <div className="relative -space-y-px rounded-md bg-white">
                {sizes.map((item, itemIdx) => (
                    <RadioGroup.Option
                        key={item.size}
                        value={item.size}
                        className={({checked}) =>
                            classNames(
                                itemIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                itemIdx === sizes.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                                'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid ',
                                item.waistImperial ? 'md:grid-cols-4' : 'md:grid-cols-3', 'md:pl-4 md:pr-6'
                            )
                        }
                    >
                        {({focus, checked}) => (
                            <>
                                                            <span className="flex items-center text-sm">
                  <span
                      className={classNames(
                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                          focus ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5"/>
                  </span>
                  <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'ml-3 font-medium')}
                  >
                    {item.size}
                  </RadioGroup.Label>
                </span>
                                <RadioGroup.Description as="span"
                                                        className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                  <span className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}>
                    {item.heightImperial}
                  </span><br/>
                                    <span
                                        className={checked ? 'text-indigo-700' : 'text-gray-500'}>({item.heightMetric})</span>
                                </RadioGroup.Description>

                                <RadioGroup.Description as="span"
                                                        className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                  <span className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}>
                    {item.weightImperial}
                  </span><br/>
                                    <span
                                        className={checked ? 'text-indigo-700' : 'text-gray-500'}>({item.heightMetric})</span>
                                </RadioGroup.Description>
                                {item.waistImperial && (


                                    <RadioGroup.Description as="span"
                                                            className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                  <span className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}>
                    {item.waistImperial}
                  </span><br/>
                                        <span
                                            className={checked ? 'text-indigo-700' : 'text-gray-500'}>({item.waistMetric})</span>
                                    </RadioGroup.Description>
                                )}
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
};