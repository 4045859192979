import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Night Diver",
    description: "The thought of dipping below the surface at night seems mysterious, yet so alluring. Although " +
        "you've been scuba diving at a site many times before, at night you drop into a whole new world and watch " +
        "it come to life under the glow of your dive light. The scene changes as day creatures retire and nocturnal " +
        "organisms emerge. If you've wondered what happens underwater after the sun goes down, sign up for the PADI " +
        "Night Diver Specialty course."
}

const faqs = [
    {
        question: 'What will I learn?',
        answer: "You'll learn how to find entry and exit points in the dark, communicate with your buddy using " +
            "light and hand signals, and how to navigate at night. You'll also learn about the nocturnal aquatic " +
            "life you'll encounter, and how to manage your equipment and avoid disorientation."
    },
    {
        question: "Do I need special equipment?",
        answer: "In addition to your standard scuba gear, you will need a primary and backup dive light (torch). " +
            "You may want additional exposure protection, such as a vest or hood. " +
            "Your instructor can help you choose the equipment that's right for you."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Complete the independent study part of your course whenever, wherever it’s convenient for you. " +
            "Work at your own pace, online or " +
            "offline, using a computer or mobile device. Connect with your instructor for inwater training, or " +
            "whenever you have a question.  The eLearning typically takes 2-4 hours to complete.",
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are three dives required for this course.  Technically these could be conducted in one night, " +
            "but most likely will occur over two or even three nights."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '12 years old',
    'PADI Open Water Diver'
];

const PadiNight = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-NIGHT"
        imageUrl='/images/PADI/Night1-9hOmPfBN.jpg'
        courseSlug='/courses/padi-night'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Night%20Diver%20Course"
    />
);

export default PadiNight;
