import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Boat Diver",
    description: "If you’re new to boat diving or want to learn more about diving from different types of boats, " +
        "enroll in the PADI® Boat Diver course. During two dives with an instructor you’ll learn nautical " +
        "terminology, boat diving etiquette and may practice using lines and/or deploy a surface marker buoy (SMB)."
}

const faqs = [
    {
        question: "Is boat diving different from shore diving?",
        answer: "Yes, boat diving involves specific procedures, such as communicating with the boat crew, " +
            "understanding boat safety protocols, and using particular entry and exit methods. The Boat Diver " +
            "course covers these unique aspects."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Complete the independent study part of your course whenever, wherever it’s convenient for you. " +
            "Work at your own pace, online or " +
            "offline, using a computer or mobile device. Connect with your instructor for inwater training, or " +
            "whenever you have a question.  The eLearning typically takes 2-4 hours to complete.",
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are two dives required for this course, typically conducted in the same day."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '10 years old',
    'PADI Open Water Diver'
];

const PadiBoat = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-BOAT"
        imageUrl='/images/PADI/BD3-GnkZglmN.jpg'
        courseSlug='/courses/padi-boat'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Boat%20Diver%20Course"
    />
);

export default PadiBoat;
