import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "Discover Local Diving",
    description: "You may remember your PADI Instructor saying, 'If you travel to a new location or visit an " +
        "unfamiliar dive environment, get a local orientation.' Its good advice and easy to do. Scuba diving " +
        "with an experienced local dive professional lets you relax and better enjoy the adventure. " +
        "For example, if all your diving has been in warm water on coral reefs and you travel to an area with " +
        "cooler water and kelp forests, Discover Local Diving is for you. Or, if the majority of your " +
        "experience is from dive boats, and you have the chance to do a shore dive with an entry through " +
        "mild surf, sign up for a Discover Local Diving experience."
}

const faqs = [
    {
        question: 'Is this a refresher course?',
        answer:
            "No.  These are a guided dives with a local dive professional.  You will not be practicing skills, " +
            "but you will be reminded of the basics of scuba diving.  If you have not been diving in a while, " +
            "we recommend you take a refresher course before booking a Discover Local Diving experience."
    }
]

const academics = [
    {
        title: 'Not Applicable',
        body:
            "Your experienced PADI Pro will brief you on the dive site conditions, hazards and points of interest, " +
            "plus describe the aquatic life you'll see and how to interact responsibly with local creatures. " +
            "If there are special procedures or techniques used locally, your dive professional will show you what " +
            "to do to make your scuba dive fun and hassle free. There are no academics associated with these dives."
    },
]

const dives = [
    {
        title: 'Ocean',
        body: "Whether it's two or three thrilling dives, we offer personalized diving experiences tailored " +
            "to your interests. Unsure about the options? Simply click the Inquiry button to message a PADI Pro. " +
            "They're here to answer your questions and guide you to the perfect " +
            "underwater adventure. Dive in and discover what awaits!"
    }
]

const progressionCourseCodes = ['PADI-AOW'];

const requirements = [
    '10 years old',
    'PADI Scuba Diver',
];

const PadiDiscoverLocalDiving = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-DLD"
        imageUrl='/images/PADI/discover-local-diving.jpg'
        courseSlug='/courses/padi-discover-local-diving'
        requirements={requirements}
        boolDiverMedical={false}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20ReActivate"
    />
);

export default PadiDiscoverLocalDiving;
