import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchEventById, resetAutoRsvp, rsvpChanged, setAutoRsvp, setModalContent} from "../redux/eventsSlice";
import {LoadingSpinner} from "./LoadingSpinner";
import {Helmet} from "react-helmet-async";
import {setCourseSignupInfo} from "../redux/courseSignupInfoSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {RadioGroup, Tab} from "@headlessui/react";
import {FaHandshake, FaParking} from "react-icons/fa";
import {MdHelpOutline, MdScubaDiving} from "react-icons/md";
import {setLastPage} from "../redux/navSlice";
import {post} from "aws-amplify/api";
import {fetchOutstandingData} from "../redux/outstandingDataSlice";
import RsvpModal from "../components/RsvpModal";
import {addUserCertification} from "../redux/authSlice";
import Tooltip from "../components/Tooltip";
import {formatEventTimeRange} from "../hooks/useCalendarLogic";
import {getImageUrl} from "../utils/sanitizeFunctions";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function validate(selectedParticipantCriteria) {
    let errors = {};
    if (Object.keys(selectedParticipantCriteria).length === 0) {
        errors.participantCriteria = 'You must choose a participant type';
    }

    return errors;
}

function EventDetails({eventId, eventDetails}) {
    const dispatch = useDispatch();
    const url = window.location.href;
    const encoded_url = encodeURIComponent(window.location.href);
    const [isRsvpButtonUpdating, setIsRsvpButtonUpdating] = useState(false);
    const navigate = useNavigate();
    const [isRSVPModalOpen, setRSVPModalOpen] = useState(false);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const modalContent = useSelector((state) => state.events.modalContent);
    const [errorInfo, setErrorInfo] = useState(null);
    const student_login_email = useSelector((state) => state.auth.loginEmail);
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const currentStudentId = userDetails?.id;
    const [isBusy, setIsBusy] = useState(false);
    const [lastUpdatedCertification, setLastUpdatedCertification] = useState(null);
    // const rsvpStatusChanged = useSelector(state => state.events.rsvpChanged);
    const [selectedParticipantCriteria, setSelectedParticipantCriteria] = useState({})
    const [isUserRSVPd, setIsUserRSVPd] = useState(false);
    const [currentUserCriteria, setCurrentUserCriteria] = useState(null);
    const autoRsvp = useSelector(state => state.events.autoRsvp);

    const [formErrors, setFormErrors] = useState({
        participation_criteria: ''
    });

    const participationCriteria = useMemo(() => {
        return eventDetails ? eventDetails.participation_criteria : [];
    }, [eventDetails]);

    useEffect(() => {
        // Check if the user has RSVP'd
        const hasRSVPd = eventDetails?.attendee_details?.some(attendee => attendee.student_id === currentStudentId);
        setIsUserRSVPd(hasRSVPd);

        // If the user has RSVP'd, find their current participation criteria
        if (hasRSVPd) {
            const epcId = eventDetails.attendee_details.find(attendee => attendee.student_id === currentStudentId)?.epc_id;
            const matchingCriteria = participationCriteria.find(pc => pc.epc_id === epcId);
            setCurrentUserCriteria(matchingCriteria);
        } else {
            setCurrentUserCriteria(null);
        }
    }, [eventDetails, currentStudentId, participationCriteria]);


    useEffect(() => {
        setFormErrors({
            participation_criteria: ''
        })
    }, [selectedParticipantCriteria])

    // useEffect(() => {
    //     if (!eventDetails || String(eventDetails.event_id) !== String(eventId)) {
    //         console.log(eventDetails, eventId);
    //         dispatch(fetchEventById({event_id: eventId}));
    //     }
    // }, [eventId, eventDetails, dispatch]);
    //
    // useEffect(() => {
    //     if (rsvpStatusChanged) {
    //         console.log(rsvpStatusChanged, eventId);
    //         dispatch(fetchEventById({event_id: eventId}));
    //     }
    // }, [rsvpStatusChanged, dispatch]);
    //
    // useEffect(() => {
    //     dispatch(setLastPage(window.location.pathname));
    // }, [dispatch]);
    //
    useEffect(() => {
        // If the user has just added a certification, we need to re-run the course checks.
        // Problem is, redux state updates are async, so we have to abandon the workflow and pick it back up here
        if (userDetails?.certifications) {
            if (userDetails.certifications.some(cert => cert.course_code === lastUpdatedCertification)) {
                setIsBusy(true);
                handleCourseChecks(modalContent.selectedCriteria, eventId)

                setLastUpdatedCertification(null);
            }
        }
    }, [userDetails?.certifications, lastUpdatedCertification]);
    
    const openModal = (content) => {
        dispatch(setModalContent(content));
        setRSVPModalOpen(true);
    };

    const closeModal = () => {
        setRSVPModalOpen(false);
    };

    const navigateToCoursePage = (courseSlug) => {
        if (courseSlug) {
            navigate(`/courses/${courseSlug}`)
        }
    };

    const showUnRSVPConfirmationModal = (eventId) => {
        const generatedContent = {
            type: 'unrsvp',
            title: 'Are you sure you want to give up your spot?',
            message: null,
            options: [
                {text: 'Yes', uniqueId: 'unrsvp_yes'},
            ],
            handleClickType: 'handleUnRSVPClick',
            eventId
        };
        openModal(generatedContent);
    };

    const handleSignMeUpClick = async (option) => {
        if (option.text === 'Sign me up!') {
            // console.log(eventDetails);
            const selectedParticipationCriteria = eventDetails?.participation_criteria?.filter(
                criteria => criteria.event_course_code === option.uniqueId
            );

            // Intentionally not waiting for response here.
            // We don't want to block the user from navigating to the course signup page (signup is executed in process)
            saveEventAttendee({
                customer_type: selectedParticipationCriteria[0].customer_type,
                course_code: option.uniqueId,
                event_id: eventId,
                epc_id: selectedParticipationCriteria[0].epc_id
            });

            dispatch(setCourseSignupInfo({
                course_code: option.uniqueId,
                course_name: option.courseName
            }));

            navigate('/course-signup')

        } else if (option.text === 'More Information') {
            navigateToCoursePage(option.uniqueId);
        }

    }

    const handleFormsAndPaymentsClick = async (option) => {
        if (option.text === 'Sign Forms Now') {
            navigate('/outstanding-forms')
        } else if (option.text === 'Pay Now') {
            navigate('/outstanding-charges')
        }
    }

    const handlePreRequisiteClick = async (option) => {
        if (option.text === 'I have this certification') {
            setIsBusy(true);
            const response = await saveCertification(option.uniqueId);
            setIsBusy(false);
            if (response) {
                if (response.message === 'success' || response.message === 'already_certified') {
                    dispatch(addUserCertification(option.uniqueId));
                    setLastUpdatedCertification(option.uniqueId);
                } else {
                    setErrorInfo('Unexpected response message from /save-certification: ' + response.message);
                    console.log(response);
                }
            }
        } else if (option.text === 'More Information') {
            closeModal();
            navigateToCoursePage(option.uniqueId);
        }
    }

    const saveCertification = async (course_code) => {
        const apiName = 'InstrOfScubaAPI'
        const path = '/save-certification'
        const options = {
            method: 'POST',
            body: {course_code},
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            const {body} = await post({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            setErrorInfo('EventDetails-saveCertification\n' + error);
            return null;
        }
    };

    const cancelEventAttendee = async () => {
        const apiName = 'InstrOfScubaAPI'
        const path = '/save-event-attendee'
        const options = {
            method: 'POST',
            body: {
                event_id: modalContent.eventId,
                action_type: 'cancel',
                student_login_email: student_login_email,
                url
            },
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            await post({apiName, path, options});
            // setIsUserImageUpdating(false);
            setIsRsvpButtonUpdating(false);
            dispatch(rsvpChanged());
        } catch (error) {
            setErrorInfo('EventDetails-cancelEventAttendee\n' + error);
        }
    };

    const showPrerequisiteModal = (courseName, courseCode, courseSlug, selectedCriteria, eventId) => {
        const content = {
            type: 'error',
            title: courseName + ' Certification Required',
            options: [
                {
                    text: "I have this certification",
                    uniqueId: courseCode,
                },
                {
                    text: "More Information",
                    uniqueId: courseSlug,
                }
            ],
            handleClickType: 'handlePreRequisiteClick',
            // eventId: modalContent.eventId,
            eventId,
            selectedCriteria
        };
        openModal(content);
    };

    const showActiveCourseModal = (courseName, courseCode, courseSlug, eventId) => {
        const content = {
            type: 'error',
            title: courseName + ' Enrollment Required',
            options: [
                {
                    text: "Sign me up!",
                    courseName: courseName,
                    uniqueId: courseCode,
                },
                {
                    text: "More Information",
                    uniqueId: courseSlug,
                }
            ],
            handleClickType: 'handleSignMeUpClick',
            // eventId: modalContent.eventId,
            eventId,
        };
        openModal(content);
    };

    // Handle the server response after saving the event attendee
    const handleSaveEventAttendeeResponse = (eventId, response) => {
        // TODO: left off here, handle confirmed vs registered based on outstanding forms, payments, cert verifications, etc.
        // /save-event-attendee returns message: registered, confirmed, wait list, already_registered, already_waitlisted, denied, or unauthorized
        let generatedContent;
        if (!response || !response.message) {
            setErrorInfo('/save-event-attendee returned invalid response from server.');
        }

        if (response.message === 'Registered') {
            generatedContent = {
                type: 'registered',
                title: 'Registration Successful',
                message: 'You have successfully registered for this event.',
                options: null,
                handleClickType: 'handleFormsAndPaymentsClick',
                eventId
            };
        } else if (response.message === 'Confirmed') {
            generatedContent = {
                type: 'confirmed',
                title: 'Registration Confirmed',
                message: "You have successfully confirmed your spot.",
                options: null,
                handleClickType: '',
                eventId
            };
        } else if (response.message === 'Wait list') {
            generatedContent = {
                type: 'waitlisted',
                title: 'Waitlisted',
                message: "You have been added to the waitlist. We will contact you if a spot becomes available.",
                options: null,
                handleClickType: '',
                eventId
            };
        } else if (response.message === 'already_registered') {
            generatedContent = {
                type: 'failure',
                title: 'Already Registered',
                message: "You were are already registered for this event. Try refreshing the page if you aren't showing up.",
                options: null,
                handleClickType: '',
                eventId
            };
        } else if (response.message === 'denied') {
            generatedContent = {
                type: 'failure',
                title: "Unable to Join Event",
                message: "Please contact us for more information.",
                options: null,
                handleClickType: '',
                eventId
            };
        } else if (response.message === 'event_cancelled') {
            generatedContent = {
                type: 'cancelled',
                title: "Event Cancelled",
                // message: "Please refresh the page for the latest information.",
                options: null,
                handleClickType: '',
                eventId
            };
        } else {
            setErrorInfo('Unexpected response message from /save-event-attendee:' + response.message);
        }
        dispatch(rsvpChanged());
        openModal(generatedContent);
    };

    const handleCourseChecks = (selectedCriteria, eventId) => {
        setIsBusy(true);
        setIsRsvpButtonUpdating(true);
        const {
            customer_type,
            epc_id,
            prerequisite_course_code,
            active_course_code,
            event_course_code,
            prerequisite_course_name,
            prerequisite_course_slug,
            active_course_name,
            active_course_slug
        } = selectedCriteria;

        if (prerequisite_course_code && !userDetails.certifications.some(cert => cert.course_code === prerequisite_course_code)) {
            showPrerequisiteModal(prerequisite_course_name, prerequisite_course_code, prerequisite_course_slug, selectedCriteria, eventId);
            setIsBusy(false);
            setIsRsvpButtonUpdating(false);
            return;
        }

        if (active_course_code && !userDetails.course_registrations.some(reg => reg.course_code === active_course_code)) {
            // console.log('active_course_name', active_course_name);
            showActiveCourseModal(active_course_name, active_course_code, active_course_slug, eventId);
            setIsBusy(false);
            setIsRsvpButtonUpdating(false);
            return;
        }

        // If all checks pass, proceed to save the attendee
        saveEventAttendee({
            customer_type: customer_type,
            course_code: event_course_code,
            event_id: eventId,
            epc_id: epc_id
        }).then((response) => {
            setIsRsvpButtonUpdating(false);
            setIsBusy(false);
            handleSaveEventAttendeeResponse(eventId, response);
        });
    };

    const handleRSVPClick = async (selectedParticipantCriteria) => {
        // Check for UnRSVP
        const isUnRSVP = eventDetails.attendee_details.some(attendee => attendee.student_id === currentStudentId);
        if (isUnRSVP) {
            setIsRsvpButtonUpdating(true);
            showUnRSVPConfirmationModal(eventId);
            return;
        }

        let errors = validate(selectedParticipantCriteria);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        if (!currentStudentId) {
            dispatch(setAutoRsvp({eventId, selectedParticipantCriteria}))
            navigate('/signin', {state: {redirectPath: window.location.pathname, requireUserDetails: true}});
        } else if (!userDetails?.id) {
            dispatch(setAutoRsvp({eventId, selectedParticipantCriteria}))
            navigate('/profile', {state: {redirectPath: window.location.pathname}});
        } else {
            setIsRsvpButtonUpdating(true);
            handleCourseChecks(selectedParticipantCriteria, eventDetails.event_id);
        }

    };

    const saveEventAttendee = async ({customer_type, course_code, event_id, epc_id}) => {
        const apiName = 'InstrOfScubaAPI'
        const path = '/save-event-attendee'
        const options = {
            method: 'POST',
            body: {
                event_id,
                customer_type,
                course_code,
                action_type: 'save',
                student_login_email,
                epc_id,
                url
            },
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            if (data) {
                if (data.failed) {
                    console.log('failed');
                    setErrorInfo(data.message);
                } else {
                    dispatch(fetchOutstandingData(instr_id))
                        .unwrap()
                        .catch((error) => {
                            setErrorInfo('EventDetails-saveEventAttendee-fetchOutstandingData\n' + error);
                        })
                }
                setRSVPModalOpen(false);
            }
            return data;
        } catch (error) {
            console.log(error);
            setErrorInfo('EventDetails-saveEventAttendee\n' + error);
            return null;
        }
    };

    if (errorInfo) {
        console.log(errorInfo);
        throw errorInfo;
    }

    // const eventImage = useSelector(state => {
    //     if (eventDetails?.image_url) {
    //         return state.imageCache[eventDetails.image_url]?.base64;
    //     } else {
    //         return '/images/generic-event.webp';
    //     }
    // });

    useEffect(() => {
        if (autoRsvp.enabled) {
            handleRSVPClick(autoRsvp.selectedParticipantCriteria).then(() => {
                dispatch(resetAutoRsvp());
            });
        }
    }, [autoRsvp]);

    useEffect(() => {
        // Automatically select the criteria if there's only one
        if (participationCriteria.length === 1) {
            setSelectedParticipantCriteria(participationCriteria[0]);
        }
    }, [participationCriteria, setSelectedParticipantCriteria]);

    if (!eventDetails) {
        return <LoadingSpinner isLoading={!eventDetails}/>
    }

    const eventImage = getImageUrl(eventDetails?.image_url);

    return (
        <>
            <div className="">
                <Helmet>
                    <meta property='og:title' content={eventDetails.title}/>
                    <meta property='og:image' content={origin + eventDetails.image_url}/>
                    <meta property='og:description' content={eventDetails.location_name}/>
                    <meta property='og:url' content={url}/>
                </Helmet>
                <div className="mx-auto px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                        <div className="lg:col-span-4 lg:row-end-1">
                            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                                <img
                                    src={eventImage || "/images/generic-event.webp"}
                                    alt={eventDetails?.title || 'Default Event'}
                                    className="object-cover object-center"
                                    onError={(e) => {
                                        if (e.target.src !== "/images/generic-event.webp") {
                                            e.target.onerror = null; // Prevents infinite loop in case of error with the fallback image
                                            e.target.src = "/images/generic-event.webp";
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                            <div className="flex flex-col-reverse">
                                <div className="mt-4 flex justify-between items-center">
                                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                                        {eventDetails.title}</h1>
                                    {/*<h2 className="text-2xl font-padi tracking-tight text-gray-700 sm:text-3xl">*/}
                                    {/*    ${course.cost}</h2>*/}
                                </div>
                                <h2 id="information-heading" className="sr-only">
                                    Product information
                                </h2>
                            </div>

                            <div className="prose mt-6 text-gray-700">
                                {eventDetails.description && eventDetails.description.map((item, index) => (
                                    <p key={index} className="ml-0">{item}</p>
                                ))}
                                {eventDetails.custom_msg && (
                                    <p key='custom_msg' className="ml-0">{eventDetails.custom_msg}</p>
                                )}
                            </div>

                            <div className="prose mt-6 text-gray-900">
                                <p className="ml-0">{formatEventTimeRange(eventDetails.start_time, eventDetails.end_time, eventDetails.timezone)}</p>
                            </div>

                            <div className="mt-6 gap-x-6 gap-y-4">
                                <RadioGroup value={currentUserCriteria || selectedParticipantCriteria}
                                            onChange={setSelectedParticipantCriteria}>
                                    <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                                    <div className="space-y-4">
                                        {participationCriteria.map((pc, index) => (
                                            <RadioGroup.Option
                                                key={`${pc.epc_id}`}
                                                value={pc}
                                                className={({focus: isFocused}) =>
                                                    classNames(
                                                        `relative block cursor-pointer rounded-lg border px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between`,
                                                        formErrors.participantCriteria
                                                            ? 'border-red-500 text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500'
                                                            : isFocused
                                                                ? 'border-indigo-600 ring-2 ring-indigo-600'
                                                                : 'border-gray-300 text-gray-900 ring-gray-300 placeholder:text-gray-400'
                                                    )
                                                }
                                            >
                                                {({focus: isFocused, checked}) => (
                                                    <>
                                                        <span className="flex items-center">
                                                          <span className="flex flex-col text-sm">
                                                            <RadioGroup.Label as="span"
                                                                              className="font-medium text-gray-900">
                                                                {pc.customer_type}
                                                            </RadioGroup.Label>
                                                            <RadioGroup.Description as="span" className="text-gray-500">
                                                                {pc.active_course_code && pc.event_course_code && pc.active_course_name === pc.event_course_name ? (
                                                                    <div className="flex items-center space-x-2">
                                                                        {pc.active_course_name}
                                                                        <Tooltip
                                                                            content="This is the course you will be participating in.  The course fee is not included in the price shown.">
                                                                            <MdHelpOutline
                                                                                className="h-5 w-5 ml-2 text-gray-500 hover:text-gray-700"/>
                                                                        </Tooltip>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {pc.active_course_code && (
                                                                            <div className="flex items-center space-x-2">
                                                                                Active: {pc.active_course_name}
                                                                                <Tooltip
                                                                                    content="You must be enrolled in this course to be eligible for this option.  The course fee is not included in the price shown.">
                                                                                    <MdHelpOutline
                                                                                        className="h-5 w-5 ml-2 text-gray-500 hover:text-gray-700"/>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {pc.event_course_code && (
                                                                            <div className="flex items-center space-x-2">
                                                                                Event: {pc.event_course_name}
                                                                                <Tooltip
                                                                                    content="This is the main event you will be participating in, and may or may not be the same as the active course.  For example, a Divemaster Candidate assisting an Open Water course as part of their training may have a prerequisite of Rescue Diver, active course of Divemaster, and event course of Open Water.">
                                                                                    <MdHelpOutline
                                                                                        className="h-5 w-5 ml-2 text-gray-500 hover:text-gray-700"/>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {pc.prerequisite_course_name && (
                                                                    <div className="flex items-center space-x-2">
                                                                        Pre-Req: {pc.prerequisite_course_name}
                                                                        <Tooltip
                                                                            content="You must have this prerequisite certification to be eligible for this option.">
                                                                            <MdHelpOutline
                                                                                className="h-5 w-5 ml-2 text-gray-500 hover:text-gray-700"/>
                                                                        </Tooltip>
                                                                    </div>
                                                                )}
                                                            </RadioGroup.Description>
                                                          </span>
                                                        </span>
                                                        <RadioGroup.Description
                                                            as="span"
                                                            className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                                                        >
                                                            <span
                                                                className="font-medium text-gray-900">${pc.price}</span>
                                                            {/*<span className="ml-1 text-gray-500 sm:ml-0">/mo</span>*/}
                                                        </RadioGroup.Description>
                                                        <span
                                                            className={classNames(
                                                                isFocused ? 'border' : 'border-2',
                                                                checked ? 'border-indigo-600' : 'border-transparent',
                                                                'pointer-events-none absolute -inset-px rounded-lg'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                                <button
                                    className={`${isRsvpButtonUpdating ? 'animate-pulse cursor-not-allowed' : ''} 
                                flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 
                                ${eventDetails.is_cancelled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-700'} 
                                text-white font-bold rounded-full`}
                                    onClick={() => {
                                        if (!eventDetails.is_cancelled && !isRsvpButtonUpdating) {
                                            handleRSVPClick(selectedParticipantCriteria);
                                        }
                                    }}
                                    disabled={eventDetails.is_cancelled || isRsvpButtonUpdating}
                                >
                                    {
                                        eventDetails.is_cancelled
                                            ? 'Cancelled'
                                            : (
                                                isUserRSVPd ? 'UnRSVP' : 'RSVP'
                                            )
                                    }
                                </button>
                                <Link
                                    to="/contact"
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium font-padi text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                >
                                    Inquire
                                </Link>
                            </div>

                            <div className="mt-10 border-t border-gray-200 pt-10">
                                <h3 className="text-sm font-medium text-gray-900">Share</h3>
                                <ul className="mt-4 flex items-center space-x-6">
                                    <li>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${encoded_url}`} target="_blank"
                                           rel="noreferrer"
                                           className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Share on Facebook</span>
                                            <svg className="h-5 w-5" aria-hidden="true" fill="currentColor"
                                                 viewBox="0 0 20 20">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`https://x.com/intent/tweet?text=${encoded_url}!&url=${encoded_url}`}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Share on X</span>
                                            <FontAwesomeIcon icon={faXTwitter}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                            <Tab.Group as="div">
                                <div className="border-b border-gray-200">
                                    <Tab.List className="-mb-px flex space-x-8">
                                        <Tab
                                            className={({selected}) =>
                                                classNames(
                                                    selected
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                                )
                                            }
                                        >
                                            Location
                                        </Tab>
                                        <Tab
                                            className={({selected}) =>
                                                classNames(
                                                    selected
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                                )
                                            }
                                        >
                                            Equipment
                                        </Tab>
                                        <Tab
                                            className={({selected}) =>
                                                classNames(
                                                    selected
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                                )
                                            }
                                        >
                                            Dives
                                        </Tab>
                                        <Tab
                                            className={({selected}) =>
                                                classNames(
                                                    selected
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                                                )
                                            }
                                        >
                                            FAQ
                                        </Tab>
                                    </Tab.List>
                                </div>

                                <Tab.Panels as={Fragment}>

                                    <Tab.Panel className="text-sm text-gray-500">
                                        <h3 className="sr-only">Location</h3>
                                        <dl>
                                            <Fragment key='location_name'>
                                                <dt className="mt-10 font-medium text-gray-900">{eventDetails.location_name}</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                    <p>{eventDetails.location_description}</p>
                                                </dd>
                                            </Fragment>
                                            <Fragment key='directions'>
                                                <dt className="mt-10 font-medium text-gray-900">Directions</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                    <p>{eventDetails.directions}</p>
                                                </dd>
                                            </Fragment>
                                            <Fragment key='apple-links'>
                                                <dt className="mt-10 font-medium text-gray-900 flex items-center">
                                                    <img src="/images/logos/apple_maps_icon.png" alt="Apple Maps Logo"
                                                         className="h-6 w-6 mr-2"/> Apple Map Links
                                                </dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                    <div className="flex space-x-4 justify-start items-center">
                                                        <div className="flex items-center">
                                                            <a href={`https://maps.apple.com/?ll=${eventDetails.parking_lon},${eventDetails.parking_lat}&q=Parking&t=h`}
                                                               target="_blank" rel="noopener noreferrer"
                                                               className="flex items-center no-underline">
                                                                <FaParking className="text-current h-5 w-5"/>
                                                                <span className="ml-2 text-gray-700">Parking</span>
                                                            </a>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <a href={`https://maps.apple.com/?ll=${eventDetails.linkup_lon},${eventDetails.linkup_lat}&q=Linkup&t=h`}
                                                               target="_blank" rel="noopener noreferrer"
                                                               className="flex items-center no-underline">
                                                                <FaHandshake className="text-current h-5 w-5"/>
                                                                <span className="ml-2 text-gray-700">Linkup</span>
                                                            </a>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <a href={`https://maps.apple.com/?ll=${eventDetails.activity_lon},${eventDetails.activity_lat}&q=Activity&t=h`}
                                                               target="_blank" rel="noopener noreferrer"
                                                               className="flex items-center no-underline">
                                                                <MdScubaDiving className="text-current h-5 w-5"/>
                                                                <span className="ml-2 text-gray-700">Activity</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </Fragment>
                                            <Fragment key='google-links'>
                                                <dt className="mt-10 font-medium text-gray-900 flex items-center">
                                                    <img src="/images/logos/google_maps_icon.png" alt="Google Maps Logo"
                                                         className="h-6 w-6 mr-2"/> Google Map Links
                                                </dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                    <div className="flex space-x-4 justify-start items-center">
                                                        <div className="flex items-center">
                                                            <a href={`https://maps.google.com/maps?q=${eventDetails.parking_lon},${eventDetails.parking_lat}&t=h`}
                                                               target="_blank" rel="noopener noreferrer"
                                                               className="flex items-center no-underline">
                                                                <FaParking className="text-current h-5 w-5"/>
                                                                <span className="ml-2 text-gray-700">Parking</span>
                                                            </a>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <a href={`https://maps.google.com/maps?q=${eventDetails.linkup_lon},${eventDetails.linkup_lat}&t=h`}
                                                               target="_blank" rel="noopener noreferrer"
                                                               className="flex items-center no-underline">
                                                                <FaHandshake className="text-current h-5 w-5"/>
                                                                <span className="ml-2 text-gray-700">Linkup</span>
                                                            </a>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <a href={`https://maps.google.com/maps?q=${eventDetails.activity_lon},${eventDetails.activity_lat}&t=h`}
                                                               target="_blank" rel="noopener noreferrer"
                                                               className="flex items-center no-underline">
                                                                <MdScubaDiving className="text-current h-5 w-5"/>
                                                                <span className="ml-2 text-gray-700">Activity</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </Fragment>
                                        </dl>
                                    </Tab.Panel>

                                    <Tab.Panel className="text-sm text-gray-500">
                                        <h3 className="sr-only">Equipment</h3>
                                        <dl>
                                            <dt className="mt-10 font-medium text-gray-900">Items you must provide</dt>
                                            <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                {eventDetails.customer_provided_gear.map((item) => (
                                                    <Fragment key={item}>
                                                        <li>{item}</li>
                                                    </Fragment>
                                                ))}
                                            </dd>
                                            <dt className="mt-10 font-medium text-gray-900">Instructor provided items
                                            </dt>
                                            <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                {eventDetails.instr_provided_gear.map((item) => (
                                                    <Fragment key={item}>
                                                        <li>{item}</li>
                                                    </Fragment>
                                                ))}
                                            </dd>
                                            {!eventDetails.optional_gear ? null : (
                                                <>
                                                    <dt className="mt-10 font-medium text-gray-900">Optional items</dt>
                                                    <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                        {eventDetails.optional_gear.map((item) => (
                                                            <Fragment key={item}>
                                                                <li>{item}</li>
                                                            </Fragment>
                                                        ))}
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </Tab.Panel>

                                    <Tab.Panel className="text-sm text-gray-500">
                                        <h3 className="sr-only">Dives</h3>
                                        <dl>
                                            {eventDetails.dives.map((dive) => (
                                                <Fragment key={dive}>
                                                    <p className="mt-10 text-gray-700">{dive}</p>
                                                </Fragment>
                                            ))}
                                        </dl>
                                    </Tab.Panel>

                                    <Tab.Panel className="text-sm text-gray-500">
                                        <h3 className="sr-only">Frequently Asked Questions</h3>
                                        <dl>
                                            {eventDetails.faq.map((item, index) => (
                                                index % 2 === 0 ? (
                                                    <Fragment key={item}>
                                                        <dt className="mt-10 font-medium text-gray-900">{eventDetails.faq[index]}</dt>
                                                        <dd className="mt-2 text-gray-700">{eventDetails.faq[index + 1]}</dd>
                                                    </Fragment>
                                                ) : null
                                            ))}
                                        </dl>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div>
            <RsvpModal
                modalContent={modalContent}
                closeModal={closeModal}
                onCancelEventAttendee={cancelEventAttendee}
                isRSVPModalOpen={isRSVPModalOpen}
                onNavigateToCoursePage={navigateToCoursePage}
                onPreRequisiteClick={handlePreRequisiteClick}
                onSignMeUpClick={handleSignMeUpClick}
                isBusy={isBusy}
                onFormsAndPaymentsClick={handleFormsAndPaymentsClick}
            />
        </>

    );
}

export default EventDetails;
