import {BuildingOffice2Icon, EnvelopeIcon, PhoneIcon} from '@heroicons/react/24/outline'
import React, {useState} from 'react';
import ResultModal from "./ResultModal";
import {BsWhatsapp} from "react-icons/bs";
import {post} from "aws-amplify/api";

const ContactSupport = () => {
    const INITIAL_FORM_STATE = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        message: ''
    }

    const [formData, setFormData] = useState(INITIAL_FORM_STATE);
    const [emailError, setEmailError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorInfo, setErrorInfo] = useState(null);

    const validateEmail = (e) => {
        const value = e.target.value;
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setEmailError(emailRegex.test(value) ? '' : 'Invalid email address');
        handleChange(e);
    }

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const apiName = 'InstrOfScubaAPI'
        const path = '/contactsupport'
        const options = {
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            await post({apiName, path, options});
            setResultModalObj({
                type: 'success',
                title: 'Email Sent!',
                body: 'Your support request has been sent.  We will get back to you as soon as possible.'
            });
            setShowModal(true);
            setFormData(INITIAL_FORM_STATE);
        } catch (error) {
            setErrorInfo('ContactSupport-handleSubmit-/contactsupport\n' + error);
        }

        setIsSubmitting(false);
    };

    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });

    const closeModal = () => {
        setShowModal(false);
    }

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div className="relative isolate bg-white">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div
                            className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                            <svg
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        x="100%"
                                        y={-1}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M130 200V.5M.5 .5H200" fill="none"/>
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" strokeWidth={0} fill="white"/>
                                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0}/>
                                </svg>
                                <rect width="100%" height="100%" strokeWidth={0}
                                      fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"/>
                            </svg>
                        </div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Welcome to the support page for Instructor Of Scuba!
                            Navigating this site should be as effortless as gliding through crystal-clear waters.
                            As the sole dive master of this platform, I'm always prepared to assist, just like a buddy
                            in a dive team.
                            If you've encountered any reefs or strong currents – in other words, challenges or questions
                            – please don't hesitate to signal.
                            By working together, we can ensure our digital dive site remains as vibrant and accessible
                            as our beloved underwater world.
                        </p>
                        <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Address</span>
                                    <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                </dt>
                                <dd>
                                    BAMCIS LLC<br/>
                                    1050 Queen St #100<br/>
                                    Honolulu, HI 96814
                                </dd>
                            </div>
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Telephone</span>
                                    <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                </dt>
                                <dd>
                                    <a className="hover:text-gray-900" href="sms:+1 (619) 701-2017">
                                        +1 (619) 701-2017
                                    </a>
                                </dd>
                            </div>
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                </dt>
                                <dd>
                                    <a className="hover:text-gray-900" href="mailto:chris@bamcis.org">
                                        chris@bamcis.org
                                    </a>
                                </dd>
                            </div>

                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <BsWhatsapp className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                </dt>
                                <dd>
                                    <a className="hover:text-gray-900" href="https://wa.me/+16197012017" target='_blank'
                                       rel="noopener noreferrer">
                                        WhatsApp
                                    </a>
                                </dd>
                            </div>

                        </dl>
                    </div>
                </div>
                {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                <form onSubmit={handleSubmit} className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first_name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    First name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        value={formData.first_name}
                                        autoComplete="given-name"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last_name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Last name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        value={formData.last_name}
                                        autoComplete="family-name"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        value={formData.preferred_email}
                                        onChange={validateEmail}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    {emailError && <p className="text-red-500 text-sm italic">{emailError}</p>}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="phone_number"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Phone number
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="tel"
                                        name="phone_number"
                                        id="phone_number"
                                        value={formData.phone_number}
                                        autoComplete="tel"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Message
                                </label>
                                <div className="mt-2.5">
                  <textarea
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >{isSubmitting ? 'Sending...' : 'Send message'}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default ContactSupport;
