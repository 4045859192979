import React, {Fragment, useEffect} from 'react'
import {Tab} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {LoadingSpinner} from "../../containers/LoadingSpinner";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {addVisitedCourse} from "../../redux/visitedCoursesSlice";
import {setLastPage} from "../../redux/navSlice";
import {setCourseSignupInfo} from "../../redux/courseSignupInfoSlice";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PadiCourse({
                                       product,
                                       faqs,
                                       academics,
                                       dives,
                                       courseCode,
                                       imageUrl,
                                       courseSlug,
                                       requirements,
                                       boolDiverMedical,
                                       progressionCourseCodes,
                                       urlText
                                   }) {
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const featureFlags = instrDetails?.enabled_features?.[0] || {
        show_calendar: false,
        show_contact: false,
        instructor_portal: false,
        manage_calendar: false,
        show_course_signup: false
    };
    const instrDetailsStatus = useSelector((state) => state.instrDetails.status);
    const course = instrDetails ? instrDetails.courses.find(course => course.course_code === courseCode): null;
    const isInstrDetailsLoading = instrDetailsStatus === 'loading';

    const url = encodeURIComponent(window.location.href);
    const origin = encodeURIComponent(window.location.origin);

    const dispatch = useDispatch();
    useEffect(() => {
        if (course) {
            dispatch(addVisitedCourse(course.course_name));
        }
    }, [course, dispatch]);

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    if (isInstrDetailsLoading || !course) {
        return <LoadingSpinner isLoading={isInstrDetailsLoading}
                               title={product.title}
                               description={product.description}
                               image={origin + imageUrl}
                               url={url}/>
    }

    const imageSrc = course.image_url || imageUrl;

    if (!course.classroom_knowledge_option) {
        academics = academics.filter(acad => acad.title !== 'Instructor-led Training');
    }

    const courses = instrDetails.courses;
    const progressionOpportunities = courses.filter(course => progressionCourseCodes.includes(course.course_code));

    return (
        <div className="">
            <Helmet>
                <meta property='og:title' content={course.course_name}/>
                <meta property='og:image' content={origin + imageSrc}/>
                <meta property='og:description' content={product.description}/>
                <meta property='og:url' content={origin + courseSlug}/>
            </Helmet>
            <div className="mx-auto px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                    <div className="lg:col-span-4 lg:row-end-1">
                        <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                            <img src={imageSrc} alt={course.course_name} className="object-cover object-center"/>
                        </div>
                    </div>

                    <div
                        className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                        <div className="flex flex-col-reverse">
                            <div className="mt-4 flex justify-between items-center">
                                <h1 className="text-2xl font-padi font-bold tracking-tight text-gray-900 sm:text-3xl">
                                    {course.course_name}</h1>
                                <h2 className="text-2xl font-padi tracking-tight text-gray-700 sm:text-3xl">
                                    ${course.cost}</h2>
                            </div>
                            <h2 id="information-heading" className="sr-only">
                                Product information
                            </h2>
                        </div>

                        <div className="prose mt-6 text-gray-700 font-padi">
                            <p>{product.description}</p>
                            {product.descTwo && (
                                <p>{product.descTwo}</p>
                            )}
                            {product.descTwoList && (
                                <ul>
                                    {product.descTwoList.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                            {featureFlags.show_course_signup ? (
                                <>
                                    <Link
                                        onClick={() => {
                                            dispatch(setCourseSignupInfo({
                                                course_code: course.course_code,
                                                course_name: course.course_name
                                            }));
                                        }}
                                        to='/course-signup'
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium font-padi text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                    >
                                        Sign Up
                                    </Link>
                                    <Link
                                        to="/contact"
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-50 px-8 py-3 text-base font-medium font-padi text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                    >
                                        Inquire
                                    </Link>
                                </>
                            ) : (
                                <Link
                                    to="/about"
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium font-padi text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                >
                                    Inquire
                                </Link>
                            )}
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium font-padi-bold text-gray-900">Minimum Requirements</h3>
                            <div className="prose prose-sm mt-4 text-gray-700 font-padi">
                                <ul>
                                    {requirements.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                    {boolDiverMedical && (
                                        <li key='medical'><Link to="/forms/diver-medical-form"
                                                                className="text-gray-700 no-underline hover:underline hover:text-blue-600">Favorable
                                            Diver Medical Questionnaire</Link></li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium font-padi-bold text-gray-900">Progression
                                Opportunities</h3>
                            <div className="prose prose-sm mt-4 text-gray-700 font-padi">
                                <ul>
                                    {progressionOpportunities.map((course) => (
                                        <li key={course.course_code}>
                                            <Link to={`/courses/${course.course_slug}`}
                                                  className="text-gray-700 no-underline hover:underline hover:text-blue-600">
                                                {course.course_name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium text-gray-900">Share</h3>
                            <ul className="mt-4 flex items-center space-x-6">
                                <li>
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank"
                                       rel="noreferrer"
                                       className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Share on Facebook</span>
                                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://x.com/intent/tweet?text=${urlText}!&url=${url}`}
                                       target="_blank"
                                       rel="noreferrer"
                                       className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Share on X</span>
                                        <FontAwesomeIcon icon={faXTwitter}/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                        <Tab.Group as="div">
                            <div className="border-b border-gray-200">
                                <Tab.List className="-mb-px flex space-x-8">
                                    <Tab
                                        className={({selected}) =>
                                            classNames(
                                                selected
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-padi-medium'
                                            )
                                        }
                                    >
                                        Academics
                                    </Tab>
                                    <Tab
                                        className={({selected}) =>
                                            classNames(
                                                selected
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-padi-medium'
                                            )
                                        }
                                    >
                                        Equipment
                                    </Tab>
                                    <Tab
                                        className={({selected}) =>
                                            classNames(
                                                selected
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-padi-medium'
                                            )
                                        }
                                    >
                                        Dives
                                    </Tab>
                                    <Tab
                                        className={({selected}) =>
                                            classNames(
                                                selected
                                                    ? 'border-indigo-600 text-indigo-600'
                                                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                                                'whitespace-nowrap border-b-2 py-6 text-sm font-padi-medium'
                                            )
                                        }
                                    >
                                        FAQ
                                    </Tab>
                                </Tab.List>
                            </div>

                            <Tab.Panels as={Fragment}>

                                <Tab.Panel className="text-sm text-gray-500">
                                    <h3 className="sr-only">Academics</h3>
                                    <dl>
                                        {academics.map((acad) => (
                                            <Fragment key={acad.title}>
                                                <dt className="mt-10 font-padi-medium text-gray-900">{acad.title}</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700 font-padi">
                                                    <p>{acad.body}</p>
                                                </dd>
                                            </Fragment>
                                        ))}
                                    </dl>
                                </Tab.Panel>

                                <Tab.Panel className="text-sm text-gray-500">
                                    <h3 className="sr-only">Equipment</h3>
                                    <dl>
                                        <dt className="mt-10 font-padi-medium text-gray-900">Items you must provide</dt>
                                        <dd className="prose prose-sm mt-2 max-w-none text-gray-700 font-padi">
                                            {course.student_provided_gear.map((item) => (
                                                <Fragment key={item}>
                                                    <li>{item}</li>
                                                </Fragment>
                                            ))}
                                        </dd>
                                        <dt className="mt-10 font-padi-medium text-gray-900">Instructor provided items
                                        </dt>
                                        <dd className="prose prose-sm mt-2 max-w-none text-gray-700 font-padi">
                                            {course.instr_provided_gear.map((item) => (
                                                <Fragment key={item}>
                                                    <li>{item}</li>
                                                </Fragment>
                                            ))}
                                        </dd>
                                        {!course.optional_gear ? null : (
                                            <>
                                                <dt className="mt-10 font-padi-medium text-gray-900">Optional items</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700 font-padi">
                                                    {course.optional_gear.map((item) => (
                                                        <Fragment key={item}>
                                                            <li>{item}</li>
                                                        </Fragment>
                                                    ))}
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                </Tab.Panel>

                                <Tab.Panel className="text-sm text-gray-500">
                                    <h3 className="sr-only">Dives</h3>
                                    <dl>
                                        {dives.map((dive) => (
                                            <Fragment key={dive.title}>
                                                <dt className="mt-10 font-padi-medium text-gray-900">{dive.title}</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700 font-padi">
                                                    <p>{dive.body}</p>
                                                </dd>
                                            </Fragment>
                                        ))}
                                    </dl>
                                </Tab.Panel>

                                <Tab.Panel className="text-sm text-gray-500">
                                    <h3 className="sr-only">Frequently Asked Questions</h3>
                                    <dl>
                                        {faqs.map((faq) => (
                                            <Fragment key={faq.question}>
                                                <dt className="mt-10 font-medium text-gray-900">{faq.question}</dt>
                                                <dd className="prose prose-sm mt-2 max-w-none text-gray-700">
                                                    <p>{faq.answer}</p>
                                                </dd>
                                            </Fragment>
                                        ))}
                                    </dl>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}
