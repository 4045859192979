import {useEffect, useState} from 'react';
import CalendarHeader from "../components/CalendarHeader";
import CalendarGrid from "../components/CalendarGrid";
import CalendarEventList from "./CalendarEventList";
import EditEventForm from "./EditEventForm";
import ManageLocations from "./ManageLocations";
import {useCalendarLogic} from "../hooks/useCalendarLogic";
import {useDispatch, useSelector} from "react-redux";
import {fetchEvents} from "../redux/eventsSlice";
import withAuth from "./withAuth";
import Error403 from "./403Page";
import ManageEventTemplates from "./ManageEventTemplates";
import {fetchEventTemplates} from "../redux/eventTemplatesSlice";

const ManageCalendar = () => {
    const {
        selectedDate,
        firstDateInDisplayedMonth,
        hasEvent,
        handleDateClick,
        handleNextMonth,
        handlePrevMonth,
        isToday,
        days,
        filteredEvents
    } = useCalendarLogic();

    const [errorInfo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isManagingLocations, setIsManagingLocations] = useState(false);
    const [isManagingEventTemplates, setIsManagingEventTemplates] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [shouldFetchLocations, setShouldFetchLocations] = useState(true);
    const [shouldFetchEventTemplates, setShouldFetchEventTemplates] = useState(true);
    const eventHasChanged = useSelector(state => state.events.eventChanged);
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const dispatch = useDispatch();
    const currentURL = useSelector((state) => state.url.currentURL);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const instrDomains = userDetails && userDetails.instr_domains;
    const isInstrOnOwnSite = instrDomains && instrDomains.includes(currentURL);

    const handleEditClick = (eventId) => {
        const selectedEventDetails = filteredEvents.find(event => event.event_id === eventId);
        setIsEditing(true);
        setSelectedEvent(selectedEventDetails);
    }

    const handleExitForm = () => {
        setIsEditing(false);
        setSelectedEvent(null);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [isEditing, isManagingLocations, isManagingEventTemplates]);

    useEffect(() => {
        if (firstDateInDisplayedMonth) {
            // console.log('eventHasChanged', eventHasChanged);
            dispatch(fetchEvents({
                instr_id,
                month: firstDateInDisplayedMonth.getMonth() + 1,
                year: firstDateInDisplayedMonth.getFullYear(),
            }));
            // TODO: return rejectWithValue(error.message) is ignored without .unwrap
        }
    }, [eventHasChanged]);

    useEffect(() => {
        if (shouldFetchEventTemplates) {
            dispatch(fetchEventTemplates({instr_id}));
            setShouldFetchEventTemplates(false);
        }
    }, [instr_id, shouldFetchEventTemplates]);

    const handleManageLocations = () => {
        setIsManagingLocations(true);
    };

    const handleExitManageLocations = () => {
        setIsManagingLocations(false);
    };

    const handleManageEventTemplates = () => {
        setIsManagingEventTemplates(true);
    };

    const handleExitManageEventTemplates = () => {
        setIsManagingEventTemplates(false);
    };

    const createNewEvent = () => {
        setIsEditing(true);
        setSelectedEvent({
            event_id: -1,
            instr_title: '',
            start_time: selectedDate.toISOString(),
            end_time: selectedDate.toISOString(),
            location_id: null,
            location_name: '',
            parking_coordinates: '',
            linkup_coordinates: '',
            event_coordinates: '',
            global_max_people: '1',
            is_cancelled: false,
            participation_criteria: [],
            custom_msg: ''
        });
    };

    if (!isInstrOnOwnSite) {
        return (
            <Error403/>
        )
    }

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            {isEditing ? (
                isManagingLocations ? (
                    <ManageLocations
                        onExitManageLocations={handleExitManageLocations}
                        shouldFetchLocations={shouldFetchLocations}
                        setShouldFetchLocations={setShouldFetchLocations}
                    />
                ) : (
                    isManagingEventTemplates ? (
                        <ManageEventTemplates
                            onExitManageEventTemplates={handleExitManageEventTemplates}
                            setShouldFetchEventTemplates={setShouldFetchEventTemplates}
                            shouldFetchEventTemplates={shouldFetchEventTemplates}
                        />
                    ) : (
                        <EditEventForm
                            selectedEvent={selectedEvent}
                            onExitForm={handleExitForm}
                            onManageLocations={handleManageLocations}
                            onManageEventTemplates={handleManageEventTemplates}
                            shouldFetchLocations={shouldFetchLocations}
                            setShouldFetchLocations={setShouldFetchLocations}
                            shouldFetchEventTemplates={shouldFetchEventTemplates}
                            setShouldFetchEventTemplates={setShouldFetchEventTemplates}
                        />
                    ))
            ) : (
                <>
                    <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
                        <div
                            className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
                            <CalendarHeader
                                firstDateInDisplayedMonth={firstDateInDisplayedMonth}
                                handlePrevMonth={handlePrevMonth}
                                handleNextMonth={handleNextMonth}
                            />
                            <CalendarGrid
                                days={days}
                                handleDateClick={handleDateClick}
                                hasEvent={hasEvent}
                                isToday={isToday}
                                selectedDate={selectedDate}
                            />
                        </div>
                        <CalendarEventList
                            viewMode="manage"
                            filteredEvents={filteredEvents}
                            selectedDate={selectedDate}
                            onEditClick={handleEditClick}
                            createNewEvent={createNewEvent}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default withAuth(ManageCalendar, {showIntro: false});
