import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import {useNavigate} from "react-router-dom";
import {setLastPage} from "../../redux/navSlice";
import {post} from "aws-amplify/api";
import ResultModal from "../ResultModal";

const SafeDivingForm = () => {
    const navigate = useNavigate();
    const loginEmail = useSelector((state) => state.auth.loginEmail);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const [formData, setFormData] = useState({
        form_short_name: 'Safe Diving',
        diver_name: '',
        signature_date: ''
    });

    useEffect(() => {
        if (userDetails) {
            setFormData({
                ...formData,
                diver_name: userDetails.first_name + ' ' + userDetails.last_name,
            });
        }
    }, [userDetails, dispatch]);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const sigCanvas = useRef(null);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const checkboxElement = document.getElementById('eSignConsent');
        if (e.target.checked) {
            checkboxElement.classList.remove('border-red-500');
            checkboxElement.classList.add('border-gray-300');
        } else {
            checkboxElement.classList.add('border-red-500');
            checkboxElement.classList.remove('border-gray-300');
        }
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });

    const closeModal = () => {
        setShowModal(false);
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            document.getElementById(`sigCanvas`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`sigCanvas`).classList.add('border-red-500');
            document.getElementById(`sigCanvas`).classList.remove('border-gray-500');
            return;
        } else {
            document.getElementById(`sigCanvas`).classList.add('border-gray-500');
            document.getElementById(`sigCanvas`).classList.remove('border-red-500');
        }

        if (!formData.signature_date) {
            document.getElementById(`signature_date`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`signature_date`).classList.add('border-red-500');
            return;
        }

        if (!isCheckboxChecked) {
            document.getElementById(`eSignConsent`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`eSignConsent`).classList.add('border-red-500');
            return;
        }

        if (sigCanvas.current) {
            const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setIsSubmitting(true);

            const dataToSend = {
                formData,
                signature,
                instr_id: instrDetails.instr_id,
                instr_full_name: instrDetails.first_name + ' ' + instrDetails.last_name,
                instr_email: instrDetails.preferred_email,
                form_slug: "safe-diving-form",
                student_login_email: loginEmail,
                student_preferred_email: userDetails.preferred_email,
                store_resort: instrDetails.store_resort
            }

            const apiName = 'InstrOfScubaAPI'
            const path = '/esign'
            const options = {
                method: 'POST',
                body: dataToSend,
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = JSON.parse(body);
                if (data.success) {
                    setResultModalObj({
                        type: 'success',
                        title: 'Document Submitted!',
                        body: 'Your document has been signed, sealed, and delivered.'
                    });
                    setShowModal(true);
                }
            } catch (error) {
                if (error.response && error.response.status === 504) {
                    // Handling specific 504 Gateway Timeout error
                    setResultModalObj({
                        type: 'error',
                        title: 'Timeout Error',
                        body: 'The process is taking longer than expected and may or may not complete successfully.  ' +
                            'Please check your email for confirmation.  If not received, please contact support.'
                    });
                    setShowModal(true);
                } else {
                    setErrorInfo('handleSubmit - /esign\n' + error);
                }
            }
            setIsSubmitting(false);
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                canvas.width = canvas.offsetWidth;
                canvas.height = canvas.offsetHeight;
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/signin', {state: {redirectPath: window.location.pathname}});
    };

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    useEffect(() => {
        console.log(errorInfo);
        if (errorInfo) {
            throw errorInfo;
        }
    }, [errorInfo])

    return (
        <>
            <section className="bg-gray-100 font-sans leading-normal">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <img src="/images/logos/padi-vert-color.png" alt="PADI Logo"
                             className="float-left mr-4 mb-4 max-w-xs"/>
                        <h1 className="text-3xl sm:text-3xl font-extrabold text-gray-900 mb-2">
                            Standard Safe Diving Practices Statement of Understanding
                        </h1>
                    </div>
                    {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="mt-4 mb-4 text-lg text-gray-600 text-justify">
                            <p className="mb-4">Please read carefully before signing.</p>
                            <p className="mb-4">
                                This is a statement in which you are informed of the established safe diving practices
                                for skin and scuba diving. These
                                practices have been compiled for your review and acknowledgement and are intended to
                                increase your comfort and
                                safety in diving. Your signature on this statement is required as proof that you are
                                aware of these safe diving practices.
                                Read and discuss the statement prior to signing it. If you are a minor, this form must
                                also be signed by a parent or
                                guardian.
                            </p>
                            <p className="mb-4">
                                I, {formData.diver_name}, understand that as a diver I should:
                            </p>
                            <div>
                                <ol className="list-decimal list-inside space-y-2">
                                    <li>
                                        Maintain good mental and physical fitness for diving. Avoid being under the
                                        influence of alcohol or dangerous drugs when diving. Keep proficient in diving
                                        skills, striving to increase them through continuing education and reviewing
                                        them in controlled conditions after a period of diving inactivity, and refer to
                                        my course materials to stay current and refresh myself on important information.
                                    </li>
                                    <li>
                                        Be familiar with my dive sites. If not, obtain a formal diving orientation from
                                        a knowledgeable, local source. If diving conditions are worse than those in
                                        which I am experienced, postpone diving or select an alternate site with better
                                        conditions. Engage only in diving activities consistent with my training and
                                        experience. Do not engage in cave or technical diving unless specifically
                                        trained to do so.
                                    </li>
                                    <li>
                                        Use complete, well-maintained, reliable equipment with which I am familiar; and
                                        inspect it for correct fit and function prior to each dive. Have a buoyancy
                                        control device, low-pressure buoyancy control inflation system, submersible
                                        pressure gauge and alternate air source and dive planning/monitoring device
                                        (dive computer, RDP/dive tables—whichever you are trained to use) when scuba
                                        diving. Deny use of my equipment to uncertified divers.
                                    </li>
                                    <li>
                                        Listen carefully to dive briefings and directions and respect the advice of
                                        those supervising my diving activities. Recognize that additional training is
                                        recommended for participation in specialty diving activities, in other
                                        geographic areas and after periods of inactivity that exceed six months.
                                    </li>
                                    <li>
                                        Adhere to the buddy system throughout every dive. Plan
                                        dives – including communications, procedures for reuniting
                                        in case of separation and emergency procedures – with my
                                        buddy.
                                    </li>
                                    <li>Be proficient in dive planning (dive computer or dive table
                                        use). Make all dives no decompression dives and allow a
                                        margin of safety. Have a means to monitor depth and time
                                        underwater. Limit maximum depth to my level of training and
                                        experience. Ascend at a rate of not more than 18 metres/60
                                        feet per minute. Be a SAFE diver
                                        – <b>S</b>lowly <b>A</b>scend <b>F</b>rom <b>E</b>very
                                        dive. Make a safety stop as an added precaution, usually at 5
                                        metres/15 feet for three minutes or longer.
                                    </li>
                                    <li>Maintain proper buoyancy. Adjust weighting at the surface
                                        for neutral buoyancy with no air in my buoyancy control
                                        device. Maintain neutral buoyancy while underwater. Be
                                        buoyant for surface swimming and resting. Have weights
                                        clear for easy removal, and establish buoyancy when in
                                        distress while diving. Carry at least one surface signaling
                                        device (such as signal tube, whistle, mirror).
                                    </li>
                                    <li>Breathe properly for diving. Never breath-hold or skipbreathe
                                        when breathing compressed air, and avoid excessive
                                        hyperventilation when breath-hold diving. Avoid overexertion
                                        while in and underwater and dive within my limitations.
                                    </li>
                                    <li>Use a boat, float or other surface support station, whenever
                                        feasible.
                                    </li>
                                    <li>Know and obey local dive laws and regulations, including
                                        fish and game and dive flag laws.I have read the above
                                        statements and have had any questions answered to my
                                        satisfaction.
                                    </li>
                                </ol>
                                <p className="my-4 font-extrabold">
                                    I understand the importance and purposes of these established
                                    practices. I recognize they are for my own safety and well-being,
                                    and that failure to adhere to them can place me in jeopardy
                                    when diving.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            Sign here with your mouse, finger, or stylus:
                            <div id="sigCanvas" className="relative w-full h-48 border border-gray-300 rounded">
                                <SignatureCanvas ref={sigCanvas}
                                                 canvasProps={{className: 'signature-canvas w-full h-full'}}/>
                            </div>
                            <div className="flex justify-between mt-4 items-start">
                                <button type="button" onClick={clearSignature}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Clear
                                </button>
                                <input type="date" id="signature_date" name="signature_date" value={formData.signature_date}
                                       onChange={handleInputChange}
                                       className="border rounded-md py-0"/>
                            </div>
                        </div>
                        <div key="eSignConsent" id="eSignConsent"
                             className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                            <div className="text-lg text-gray-700">
                                By checking here, you are consenting to the use of your electronic signature in lieu
                                of an original signature on paper. You have the right to request that you sign a
                                paper copy instead. By checking here, you are waving that right. If you prefer to
                                print a paper copy,
                                please <a className="hover:underline text-blue-600"
                                          href="/files/10060_Standard_Safe_Diving_Practices_Statement_of_Understanding.pdf"
                                          download>download the form here</a>. After consent, you
                                may, upon written request to us, obtain a paper copy of an electronic record. No fee
                                will be charged for such copy and no special hardware or software is required to
                                view it. Your agreement to use an electronic signature with us for any documents
                                will continue until such time as you notify us in writing that you no longer wish to
                                use an electronic signature. There is no penalty for withdrawing your consent. You
                                should always make sure that we have a current email address in order to contact you
                                regarding any changes, if necessary.
                            </div>
                            <div id="checkbox-question" className="isolate inline-flex rounded-md shadow-sm">
                                <input
                                    type="checkbox"
                                    id="checkboxEsignConsent"
                                    name="checkboxEsignConsent"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                        </div>
                        {isAuthenticated ? (
                            <button type="submit"
                                    disabled={isSubmitting}
                                    className="float-right rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >{isSubmitting ? 'Submitting...' : 'Agree & Submit'}
                            </button>
                        ) : (
                            <button type="button" onClick={handleLoginClick}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 float-right">
                                Please Login to E-Sign this Document
                            </button>
                        )}
                    </form>
                </div>
            </section>
        </>
    )
        ;
};

export default SafeDivingForm;
