import React from 'react';
import { BounceLoader } from "react-spinners";

export const LoadingOverlay = () => {
    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
            <BounceLoader color={"#123abc"} size={60}/>
        </div>
    );
};
