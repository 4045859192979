import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Drift Diver",
    description: "Diving in a current can feel like flying underwater. In the PADI® Drift Diver course, you’ll learn " +
        "drift diving tips from an experienced instructor and practice buoyancy control, navigation, use of surface " +
        "signaling equipment and buddy communication."
}

const faqs = [
    {
        question: "What makes drift diving exciting?",
        answer: "Drift diving offers a unique and thrilling experience, allowing divers to glide effortlessly with " +
            "the current, covering more distance, and often seeing a wide variety of marine life. It's often described " +
            "as 'flying' underwater."
    },
    {
        question: "What will I learn?",
        answer: "You'll learn how to plan and organize drift dives, how to deal with potential problems, how to use " +
            "drift diving equipment like floats and lines, and how to interact responsibly with the aquatic life."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Complete the independent study part of your course whenever, wherever it’s convenient for you. " +
            "Work at your own pace, online or " +
            "offline, using a computer or mobile device. Connect with your instructor for inwater training, or " +
            "whenever you have a question.  The eLearning typically takes 2-4 hours to complete.",
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are two dives required for this course, typically conducted in the same day. " +
            "You’ll practice buoyancy control, navigation and buddy communication. Practice using a delayed surface " +
            "marker buoy (DSMB), float, line, reel, or other drift diving safety equipment. "
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '12 years old',
    'PADI Open Water Diver'
];

const PadiDrift = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-DRIFT"
        imageUrl='/images/PADI/Drift1-4lVae3Ps.jpg'
        courseSlug='/courses/padi-drift'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Drift%20Diver%20Course"
    />
);

export default PadiDrift;
