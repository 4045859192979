import PadiCourse from "./PadiCourse";
import React from "react";

const product = {
    title: "PADI Advanced Open Water",
    description: "The Advanced Open Water Diver course is all about advancing your skills. " +
        "You'll practice navigation and buoyancy, try deep diving, and choose three more dives to complete your " +
        "certification.  This course is a great way to get more dives under your belt while continuing to learn " +
        "under the supervision of your instructor. For every specialty dive you complete, you can earn credit toward " +
        "the corresponding PADI Specialty Diver course.",
}

const faqs = [
    {
        question: 'How long does it all take?',
        answer:
            "About 2-3 days, not including the Academics/eLearning. Two dives per day is typical, with three the max. " +
            "complete all three dives in one day."
    },
    {
        question: 'There are so many specialty dives to choose from, how do I choose?',
        answer:
            "Your instructor will help you choose the three specialty dives based on the groups shared interest, or may " +
            "have a pre-planned set of dives that are ideal for the local environment. If you do have a specific " +
            "interest, let your instructor know and they will do their best to accommodate. Deep and Underwater " +
            "Navigation are required, but the other three are up to you."
    },
    {
        question: 'How deep do we go?',
        answer: "For 15 year old's and up, the deep dive is to a maximum of 30 metres / 100 feet.  Twelve to fourteen " +
            "year old's are limited to 21 metres / 70 feet."
    }
]

let academics = [
    {
        title: 'eLearning',
        body:
            "The PADI Advanced Open Water Diver eLearning is a self-paced online course that contains the following " +
            "specialties: Underwater Navigation, Deep Diving, Altitude Diving, " +
            "Boat Diving, Digital Underwater Imaging, Drift Diving, Dry Suit Diving, Fish Identification, " +
            "Night Diving, Peak Performance Buoyancy, Search and Recovery, Underwater Naturalist, and Wreck Diving. " +
            "Additional Adventures are available through Instructor-led Training."
    },
    {
        title: 'Paper-based Self Study',
        body:
            "Alternatively, you can use the Adventures in Diving manual to learn about the specialty dives you will " +
            "be conducting. This can be done at your own pace.  Your instructor " +
            "will verify that you have completed the required knowledge reviews and will review any questions you " +
            "may have. There is no dedicated classroom time for this option, as the reviews typically take place " +
            "at the dive site."
    },
    {
        title: 'Instructor-led Training',
        body:
            "For any specialties not available through eLearning or Paper-based Self Study, your instructor " +
            "will teach you everything you need to know before conducting a knowledge review. " +
            "This is typically done at the dive site, but may be " +
            "completed in a classroom setting if the dive site does not have a suitable location. " +
            "There are no exams for this course, regardless of which instructional method you choose."
    }
]

const dives = [
    {
        title: 'Ocean',
        body: "The course includes five dives: a deep dive below 18 metres / 60 feet (down to a maximum of " +
            "30 metres / 100 feet).  Two dives per day is typical, with three training dives per day at most."
    }
]

const progressionCourseCodes = ['PADI-CAVERN', 'PADI-RESCUE', 'PADI-DEEP', 'PADI-ICE', 'PADI-PSD', 'PADI-SERE',
    'PADI-SR', 'PADI-SCRB', 'PADI-WRECK'];

const requirements = [
    '12 years old',
    'PADI Open Water Diver',
];

const PadiAdvancedOpenWater = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-AOW"
        imageUrl='/images/PADI/AOW-Gallery-hero-2-dsKTrUz_.jpg'
        courseSlug='/courses/padi-advanced-open-water'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Advanced%20Diver%20Course"
    />
);

export default PadiAdvancedOpenWater;
