import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Wreck Diver",
    description: "Whether purpose-sunk as an artificial reef for scuba divers, or lost as the result of an accident, " +
        "wrecks are fascinating windows to the past. Ships, airplanes and even cars are fascinating to explore and " +
        "usually teem with aquatic life. Each wreck dive offers a chance for discovery, potentially unlocking a " +
        "mystery or spying something others have missed. The PADI Wreck Diver Specialty course is popular because " +
        "it offers rewarding adventures while observing responsible wreck diving practices."
}

const faqs = [
    {
        question: 'What will I learn?',
        answer: "You'll learn how to map and navigate wrecks, use guidelines, recognize and avoid potential hazards, " +
            "and how to research and appreciate the history of the wrecks you explore."
    },
    {
        question: "Do I need special equipment?",
        answer: "In addition to your standard scuba gear, you may need specialized equipment like a dive light, " +
            "underwater slates or writing books, and line and reel. " +
            "Your instructor will review the required and recommended equipment with you."
    },
    {
        question: "What's the difference between recreational and technical wreck diving?",
        answer: "Recreational wreck diving generally involves exploring the exterior of wrecks and limited " +
            "penetration within the light zone. Technical wreck diving may involve deeper, more complex wrecks, " +
            "and full penetration, requiring specialized training beyond the recreational level."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Complete the independent study part of your course whenever, wherever it’s convenient for you. Learn " +
            "about different types of wrecks and common wreck diving hazards. Work at your own pace, online or " +
            "offline, using a computer or mobile device. Connect with your instructor for inwater training, or " +
            "whenever you have a question.  The eLearning typically takes 2-4 hours to complete.",
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are four dives required for this course, which will be conducted over at least two days. " +
            "You will survey and map a wreck, practice special finning techniques, and may use penetration lines."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '15 years old',
    'PADI Adventure Diver'
];

const PadiWreck = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-WRECK"
        imageUrl='/images/PADI/Wreck2-6UsBuplg.jpg'
        courseSlug='/courses/padi-wreck'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Wreck%20Diver%20Course"
    />
);

export default PadiWreck;
