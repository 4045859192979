import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import {useNavigate} from "react-router-dom";
import {setLastPage} from "../../redux/navSlice";
import {post} from "aws-amplify/api";
import ResultModal from "../ResultModal";

const LiabilityReleaseForm = () => {
    const navigate = useNavigate();
    const loginEmail = useSelector((state) => state.auth.loginEmail);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const lastPage = useSelector((state) => state.navigation.lastPage) || '/';
    const [formData, setFormData] = useState({
        form_short_name: 'Liability Release',
        diver_name: '',
        signature_date: ''
    });

    useEffect(() => {
        if (userDetails) {
            setFormData({
                ...formData,
                diver_name: userDetails.first_name + ' ' + userDetails.last_name,
            });
        }
    }, [userDetails, dispatch]);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const sigCanvas = useRef(null);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const checkboxElement = document.getElementById('eSignConsent');
        if (e.target.checked) {
            checkboxElement.classList.remove('border-red-500');
            checkboxElement.classList.add('border-gray-300');
        } else {
            checkboxElement.classList.add('border-red-500');
            checkboxElement.classList.remove('border-gray-300');
        }
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });

    const closeModal = () => {
        setShowModal(false);
        navigate(lastPage);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            document.getElementById(`sigCanvas`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`sigCanvas`).classList.add('border-red-500');
            document.getElementById(`sigCanvas`).classList.remove('border-gray-500');
            return;
        } else {
            document.getElementById(`sigCanvas`).classList.add('border-gray-500');
            document.getElementById(`sigCanvas`).classList.remove('border-red-500');
        }

        if (!formData.signature_date) {
            document.getElementById(`signature_date`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`signature_date`).classList.add('border-red-500');
            return;
        }

        if (!isCheckboxChecked) {
            document.getElementById(`eSignConsent`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`eSignConsent`).classList.add('border-red-500');
            return;
        }

        if (sigCanvas.current) {
            const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setIsSubmitting(true);

            const dataToSend = {
                formData,
                signature,
                instr_id: instrDetails.instr_id,
                instr_full_name: instrDetails.first_name + ' ' + instrDetails.last_name,
                instr_email: instrDetails.preferred_email,
                form_slug: "liability-release-form",
                student_login_email: loginEmail,
                student_preferred_email: userDetails.preferred_email,
                store_resort: instrDetails.store_resort
            }

            const apiName = 'InstrOfScubaAPI'
            const path = '/esign'
            const options = {
                method: 'POST',
                body: dataToSend,
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = await body.json();
                if (data.success) {
                    setResultModalObj({
                        type: 'success',
                        title: 'Document Submitted!',
                        body: 'Your document has been signed, sealed, and delivered.'
                    });
                    setShowModal(true);
                } else {
                    // This should never run, we either raise an error or return success.
                    console.error(data.error);
                }
            } catch (error) {
                if (error.response && error.response.status === 504) {
                    // Handling specific 504 Gateway Timeout error
                    setResultModalObj({
                        type: 'error',
                        title: 'Timeout Error',
                        body: 'The process is taking longer than expected and may or may not complete successfully.  ' +
                            'Please check your email for confirmation.  If not received, please contact support.'
                    });
                    setShowModal(true);
                } else {
                    setErrorInfo('handleSubmit - /esign\n' + error);
                }
            }
            setIsSubmitting(false);
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                canvas.width = canvas.offsetWidth;
                canvas.height = canvas.offsetHeight;
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/signin', {state: {redirectPath: window.location.pathname}});
    };

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    useEffect(() => {
        console.log(errorInfo);
        if (errorInfo) {
            throw errorInfo;
        }
    }, [errorInfo])

    return (
        <>
            <section className="bg-gray-100 font-sans leading-normal">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <img src="/images/logos/padi-vert-color.png" alt="PADI Logo" className="float-left mr-4 mb-4 max-w-xs" />
                    <div className="text-center">
                        <h2 className="text-2xl sm:text-2xl font-bold text-gray-900 mb-1">
                            Non-Agency Disclosure and Acknowledgement Agreement
                        </h2>
                    </div>
                    {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="mt-4 mb-4 text-lg text-gray-600 text-justify">
                            <p className="mb-4">In European Union and European Free Trade Association countries use
                                alternative form.</p>
                            <p className="mb-4 font-bold">Please read carefully before signing.</p>
                            <p className="mb-4">
                                I understand and agree that PADI Members (“Members”), including {instrDetails.store_resort},
                                and/or any individual PADI Instructors and Divemasters associated with the program in
                                which I am participating, are licensed to use various PADI Trademarks and to conduct
                                PADI training, but are not agents, employees or franchisees of PADI Americas, Inc., or
                                its parent, subsidiary and affiliated corporations (“PADI”). I further understand that
                                Member business activities are independent, and are neither owned nor operated by PADI,
                                and that while PADI establishes the standards for PADI diver training programs, it is
                                not responsible for, nor does it have the right to control, the operation of the
                                Members’ business activities and the day-to-day conduct of PADI programs and supervision
                                of divers by the Members or their associated staff. I further understand and agree on
                                behalf of myself, my heirs and my estate that in the event of an injury or death during
                                this activity, neither I nor my estate shall seek to hold PADI liable for the actions,
                                inactions or negligence of the entities listed above and/or the instructors and
                                divemasters associated with the activity.
                            </p>
                            <h2 className="text-2xl sm:text-2xl font-bold text-gray-900 mb-1 text-center">
                                Liability Release and Assumption of Risk Agreement
                            </h2>
                            <p className="mb-4">In European Union and European Free Trade Association countries use
                                alternative form.</p>
                            <p className="mb-4 font-bold">Please read carefully before signing.</p>
                            <p className="mb-1">
                                I, {formData.diver_name}, hereby affirm that I am aware that skin and scuba diving have
                                inherent risks which may result in serious injury or death.
                            </p>
                            <p className="mb-1">
                                I understand that diving with compressed air involves certain inherent risks; including
                                but not limited to decompression sickness, embolism or other hyperbaric/air expansion
                                injury that require treatment in a recompression chamber. I further understand that the
                                open water diving trips which are necessary for training and for certification may be
                                conducted at a site that is remote, either by time or distance or both, from such a
                                recompression chamber. I still choose to proceed with such instructional dives in spite
                                of the possible absence of a recompression chamber in proximity to the dive site.
                            </p>
                            <p className="mb-1">
                                I understand and agree that neither my
                                instructor(s), {instrDetails.first_name + ' ' + instrDetails.last_name},
                                the facility through which I receive my instruction, {instrDetails.store_resort}, nor PADI
                                Americas, Inc., nor its affiliate and subsidiary corporations, nor any of their
                                respective employees, officers, agents, contractors or assigns (hereinafter referred to
                                as “Released Parties”) may be held liable or responsible in any way for any injury,
                                death or other damages to me, my family, estate, heirs or assigns that may occur as a
                                result of my participation in this diving program or as a result of the negligence of
                                any party, including the Released Parties, whether passive or active.
                            </p>
                            <p className="mb-1">
                                In consideration of being allowed to participate in this course (and optional Adventure
                                Dive), hereinafter referred to as “program,” I hereby personally assume all risks of
                                this program, whether foreseen or unforeseen, that may befall me while I am a
                                participant in this program including, but not limited to, the academics, confined water
                                and/or open water activities.
                            </p>
                            <p className="mb-1">
                                I further release, exempt and hold harmless said program and Released Parties from any
                                claim or lawsuit by me, my family, estate, heirs or assigns, arising out of my
                                enrollment and participation in this program including both claims arising during the
                                program or after I receive my certification.
                            </p>
                            <p className="mb-1">
                                I also understand that skin diving and scuba diving are physically strenuous activities
                                and that I will be exerting myself during this program, and that if I am injured as a
                                result of heart attack, panic, hyperventilation, drowning or any other cause, that I
                                expressly assume the risk of said injuries and that I will not hold the Released Parties
                                responsible for the same.
                            </p>
                            <p className="mb-1">
                                I further state that I am of lawful age and legally competent to sign this liability
                                release, or that I have acquired the written consent of my parent or guardian. I
                                understand the terms herein are contractual and not a mere recital, and that I have
                                signed this Agreement of my own free act and with the knowledge that I hereby agree to
                                waive my legal rights. I further agree that if any provision of this Agreement is found
                                to be unenforceable or invalid, that provision shall be severed from this Agreement. The
                                remainder of this Agreement will then be construed as though the unenforceable provision
                                had never been contained herein.
                            </p>
                            <p className="mb-1">
                                I understand and agree that I am not only giving up my right to sue the Released Parties
                                but also any rights my heirs, assigns, or beneficiaries may have to sue the Released
                                Parties resulting from my death. I further represent I have the authority to do so and
                                that my heirs, assigns, or beneficiaries will be estopped from claiming otherwise
                                because of my representations to the Released Parties.
                            </p>
                            <p className="mb-4 uppercase">
                                I, {formData.diver_name}, BY THIS INSTRUMENT AGREE TO EXEMPT AND RELEASE MY
                                INSTRUCTORS, {instrDetails.first_name + ' ' + instrDetails.last_name},
                                THE FACILITY THROUGH WHICH I RECEIVE MY INSTRUCTION, {instrDetails.store_resort},
                                AND PADI AMERICAS, INC., AND ALL RELATED
                                ENTITIES AS DEFINED ABOVE, FROM ALL LIABILITY OR RESPONSIBILITY WHATSOEVER FOR PERSONAL
                                INJURY, PROPERTY DAMAGE OR WRONGFUL DEATH HOWEVER CAUSED, INCLUDING, BUT NOT LIMITED TO,
                                THE NEGLIGENCE OF THE RELEASED PARTIES, WHETHER PASSIVE OR ACTIVE.
                            </p>
                            <p className="mb-1 font-extrabold">
                                I HAVE FULLY INFORMED MYSELF AND MY HEIRS OF THE CONTENTS OF THIS NON-AGENCY DISCLOSURE
                                AND ACKNOWLEDGEMENT AGREEMENT AND LIABILITY RELEASE AND ASSUMPTION OF RISK AGREEMENT BY
                                READING BOTH BEFORE SIGNING BELOW ON BEHALF OF MYSELF AND MY HEIRS.
                            </p>
                        </div>
                        <div className="flex flex-col">
                            Sign here with your mouse, finger, or stylus:
                            <div id="sigCanvas" className="relative w-full h-48 border border-gray-300 rounded">
                                <SignatureCanvas ref={sigCanvas}
                                                 canvasProps={{className: 'signature-canvas w-full h-full'}}/>
                            </div>
                            <div className="flex justify-between mt-4 items-start">
                                <button type="button" onClick={clearSignature}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Clear
                                </button>
                                <input type="date" id="signature_date" name="signature_date" value={formData.signature_date}
                                       onChange={handleInputChange}
                                       className="border rounded-md py-0"/>
                            </div>
                        </div>
                        <div key="eSignConsent" id="eSignConsent"
                             className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                            <div className="text-lg text-gray-700">
                                By checking here, you are consenting to the use of your electronic signature in lieu
                                of an original signature on paper. You have the right to request that you sign a
                                paper copy instead. By checking here, you are waving that right. If you prefer to
                                print a paper copy,
                                please <a className="hover:underline text-blue-600"
                                          href="/files/10072_Liability_Release_v403_FF.pdf"
                                          download>download the form here</a>. After consent, you
                                may, upon written request to us, obtain a paper copy of an electronic record. No fee
                                will be charged for such copy and no special hardware or software is required to
                                view it. Your agreement to use an electronic signature with us for any documents
                                will continue until such time as you notify us in writing that you no longer wish to
                                use an electronic signature. There is no penalty for withdrawing your consent. You
                                should always make sure that we have a current email address in order to contact you
                                regarding any changes, if necessary.
                            </div>
                            <div id="checkbox-question" className="isolate inline-flex rounded-md shadow-sm">
                                <input
                                    type="checkbox"
                                    id="checkboxEsignConsent"
                                    name="checkboxEsignConsent"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                        </div>
                        {isAuthenticated ? (
                            <button type="submit"
                                    disabled={isSubmitting}
                                    className="float-right rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >{isSubmitting ? 'Submitting...' : 'Agree & Submit'}
                            </button>
                        ) : (
                            <button type="button" onClick={handleLoginClick}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 float-right">
                                Please Login to E-Sign this Document
                            </button>
                        )}
                    </form>
                </div>
            </section>
        </>
    )
        ;
};

export default LiabilityReleaseForm;
