// import {LockClosedIcon} from "@heroicons/react/20/solid";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {post} from "aws-amplify/api";
import ZelleModal from "../containers/ZelleModal";


const PaymentGatewayCheckoutFormDirect = ({
                                              formattedTotal,
                                              total,
                                              shipping,
                                              taxes,
                                              subtotal,
                                              venmoUsername,
                                              zelleUsername,
                                              appleUsername,
                                              cashAppUsername,
                                              products,
                                              instrId,
                                              discounts,
                                              zelleQrUrl,
                                              discountedTotal,
                                              totalDiscountValue
                                          }) => {
    const studentPreferredPmt = useSelector((state) => state.auth?.userDetails?.preferred_payment);
    // const studentPreferredPmtUsername = useSelector((state) => state.auth?.userDetails?.preferred_payment_username);
    const instrPhone = useSelector((state) => state.instrDetails?.instrDetails?.phone);
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const userChargeIds = products.map(product => product.charge_id).join("|");  // 101
    const productIds = products.map(product => product.id).join("|");
    const invoiceNumber = btoa(userChargeIds);
    const appleMessage = encodeURIComponent(`Hi there, I'm about to send you ${formattedTotal} via Apple Cash. Please apply this to invoice ${invoiceNumber}. Thanks!`);
    const venmoMessage = encodeURIComponent(`Invoice ${invoiceNumber}`);
    const cashAppMessage = encodeURIComponent(`Invoice ${invoiceNumber}`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorInfo, setErrorInfo] = useState(null);

    console.log(discountedTotal);

    const paymentMethods = [
        {
            name: "Venmo",
            username: venmoUsername,
            imgSrc: "/images/logos/Venmo_Logo_Blue.png",
            bgColor: "bg-white",
            link: `https://venmo.com/${venmoUsername}?txn=pay&note=${venmoMessage}&amount=${total}`,
            priority: 1
        },
        {
            name: "Cash App",
            username: cashAppUsername,
            imgSrc: "/images/logos/Cash_App-Full-Logo.webp",
            bgColor: "bg-white",
            link: `https://cash.app/${cashAppUsername}/${total}?note=${cashAppMessage}`,
            priority: 2
        },
        {
            name: "Zelle",
            username: zelleUsername,
            imgSrc: "/images/logos/Zelle_logo_white_on_transparent.webp",
            bgColor: "bg-purple-500",
            priority: 3
        },
        {
            name: "Apple Cash",
            username: appleUsername,
            imgSrc: "/images/logos/Apple-Cash-transparent.webp",
            bgColor: "bg-black",
            link: `sms:${instrPhone}?&body=${appleMessage}`,
            priority: 4
        },
    ];

    const getPrimaryPaymentMethod = () => {
        const preferredMethod = paymentMethods.find(method => method.name.toLowerCase() === studentPreferredPmt?.toLowerCase() && method.username);
        if (preferredMethod) {
            return preferredMethod;
        } else {
            return paymentMethods.find(method => method.username);
        }
    };

    const primaryPaymentMethod = getPrimaryPaymentMethod();
    const otherPaymentMethods = paymentMethods.filter(method => method !== primaryPaymentMethod && method.username);

    const handlePaymentClick = async (method) => {
        // console.log(method);
        if (method.name !== 'Zelle') {
            window.open(method.link, '_blank');
        } else {
            setIsModalOpen(true);
        }

        const apiName = 'InstrOfScubaAPI'
        const path = '/save-transaction'
        const options = {
            method: 'POST',
            body: {
                total_amt: total,
                total_products_amt: subtotal,
                status: 'initiated',
                payment_method: method.name,
                beneficiary_instr_id: instrId,
                discounts,
                // discounted_total: discountedTotal,
                discount_amt: totalDiscountValue,
                shipping_amt: shipping,
                tax_amt: taxes,
                user_charge_ids: userChargeIds,
                product_ids: productIds,
                invoice_number: invoiceNumber,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }

        const {body} = await post({apiName, path, options}).response;
        const data = await body.json();
        if (data?.failed) {
            console.error(data?.failed)
            console.log(options);
            setErrorInfo(data?.failed);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <section
            aria-labelledby="payment-heading"
            className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
        >
            <h2 id="payment-heading" className="sr-only">
                Payment details
            </h2>

            <div className="mx-auto max-w-lg lg:pt-16">
                {primaryPaymentMethod && (
                    <a href="#"
                       onClick={() => handlePaymentClick(primaryPaymentMethod)}
                       className="flex w-full items-center justify-center rounded-md border border-transparent"
                    >
                        <button
                            type="button"
                            className={`flex w-full items-center justify-center rounded-md border border-transparent ${primaryPaymentMethod.bgColor} py-2 text-white ${primaryPaymentMethod.bgColor === 'bg-white' ? 'ring-1 ring-black' : ''} hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2`}
                        >
                            <span className="sr-only">Pay with {primaryPaymentMethod.name}</span>
                            <picture>
                                <source type="image/webp" srcSet={primaryPaymentMethod.imgSrc}/>
                                <img src={primaryPaymentMethod.imgSrc} alt={primaryPaymentMethod.name}
                                     className="h-6 w-auto"/>
                            </picture>
                        </button>
                    </a>
                )}

                {otherPaymentMethods.length > 0 && (
                    <div className="mt-6">
                        <button
                            type="button"
                            onClick={() => setShowOtherOptions(!showOtherOptions)}
                            className="flex items-center justify-center w-full rounded-md border border-transparent bg-gray-200 py-2 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                        >
                            {showOtherOptions ? 'Hide' : 'Show'} other digital wallets
                        </button>

                        {showOtherOptions && (
                            <div className="mt-4 space-y-4">
                                {otherPaymentMethods.map(method => (
                                    <a href="#" key={method.name} onClick={() => handlePaymentClick(method)}
                                       className="flex w-full items-center justify-center rounded-md border border-transparent"
                                    >
                                        <button
                                            key={method.name}
                                            type="button"
                                            className={`flex w-full items-center justify-center rounded-md border border-transparent ${method.bgColor} py-2 text-white ${method.bgColor === 'bg-white' ? 'ring-1 ring-black' : ''} hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2`}
                                        >
                                            <span className="sr-only">Pay with {method.name}</span>
                                            <picture>
                                                <source type="image/webp" srcSet={method.imgSrc}/>
                                                <img src={method.imgSrc} alt={method.name} className="h-6 w-auto"/>
                                            </picture>
                                        </button>
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isModalOpen && <ZelleModal zelleUsername={zelleUsername} qr_url={zelleQrUrl} closeModal={closeModal}/>}
        </section>
    )
}

export default PaymentGatewayCheckoutFormDirect;
