import {CalendarIcon, MapPinIcon} from "@heroicons/react/20/solid";
import {FaTimes} from "react-icons/fa";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {format} from 'date-fns';
import {formatEventTimeRange} from "../hooks/useCalendarLogic";
import {getImageUrl} from "../utils/sanitizeFunctions";

const CalendarEventList = ({
                               filteredEvents,
                               selectedDate,
                               viewMode,
                               onEditClick,
                               createNewEvent,
                               isRsvpButtonUpdating,
                               isImagePlaceholderUpdating,
                               isUserImageUpdating
                           }) => {
    const eventsStatus = useSelector((state) => state.events?.status);
    const isFetchingEvents = eventsStatus === 'loading';
    const navigate = useNavigate();
    const currentStudentId = useSelector((state) => state.auth.userDetails?.id);

    let displayDate = "N/A";
    if (selectedDate) {
        // selectedDate is being passed in as a Date object.
        displayDate = format(selectedDate, 'EEEE, MMMM d, yyyy');
    }

    const [portraitModalData, setPortraitModalData] = useState({visible: false, name: '', base64: ''});
    const handlePortraitClick = (name, base64) => {
        setPortraitModalData({
            visible: true,
            name,
            base64
        });
    };

    const handleClosePortraitModal = () => {
        setPortraitModalData({visible: false, name: '', base64: ''});
    };

    const LoadingDots = () => {
        return (
            <div className="pt-10 flex justify-center items-center">
                <div className="animate-pulse flex space-x-2">
                    <div className="w-10 h-10 bg-indigo-500 rounded-full"></div>
                    <div className="w-10 h-10 bg-indigo-500 rounded-full"></div>
                    <div className="w-10 h-10 bg-indigo-500 rounded-full"></div>
                </div>
            </div>
        );
    };

    return (
        <>
            <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                {!filteredEvents.length && (
                    <div className="relative flex space-x-6 py-6 xl:static">
                        <div className="flex-auto">
                            <h3 className="pr-10 font-semibold text-gray-900 xl:pr-2">
                                {isFetchingEvents ? (
                                    <LoadingDots/>
                                ) : (
                                    <div>
                                        {`No Events Scheduled for ${displayDate}`}
                                    </div>
                                )}
                            </h3>
                        </div>
                    </div>
                )}
                {filteredEvents && filteredEvents.map((event) => {
                    const uniqueEventCourseNames = event.participation_criteria
                        .reduce((unique, item) => {
                            if (item.event_course_name && !unique.includes(item.event_course_name)) {
                                unique.push(item.event_course_name);
                            }
                            return unique;
                        }, [])
                        .join(', ');

                    const sumOfGearFactors = event.attendee_details
                        ? event.attendee_details.reduce((sum, attendee) => {
                            return attendee.status !== "Wait list" ? sum + attendee.gear_factor : sum;
                        }, 0)
                        : 0;

                    const remainingCapacity = Math.floor(event.global_max_people - sumOfGearFactors);

                    return (
                        <li key={event.event_id} className="relative flex space-x-6 py-6 xl:static">
                            <div className="flex-auto">
                                <h3 className="pr-24 md:pr-14 font-semibold text-gray-900 lg:pr-20 xl:pr-0">
                                    {event.title} {event.participation_criteria && (
                                    <span className="text-gray-600">
                                    {uniqueEventCourseNames ? `(${uniqueEventCourseNames})` : ""}
                                </span>
                                )}
                                </h3>
                                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                                    <div className="flex items-start space-x-3">
                                        <dt className="mt-0.5">
                                            <span className="sr-only">Date</span>
                                            <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        </dt>
                                        <dd>
                                            <time dateTime={event.start_time}>
                                                {formatEventTimeRange(event.start_time, event.end_time, event.timezone)}
                                            </time>
                                        </dd>
                                    </div>
                                    <div
                                        className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                                        <dt className="mt-0.5">
                                            <span className="sr-only">Location</span>
                                            <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        </dt>
                                        <dd>{event.location_name}</dd>
                                    </div>
                                </dl>
                                {/* The Modal */}
                                {portraitModalData.visible && (
                                    <div
                                        className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-black">
                                        <div className="bg-transparent p-4 rounded relative flex flex-col items-center">
                                            <img src={portraitModalData.base64} alt={portraitModalData.name}
                                                 className="max-w-[calc(100vw-2rem)] max-h-[calc(100vh-4rem)] object-contain"/>
                                            <div
                                                className="w-full text-center text-white text-lg font-semibold bg-black">
                                                {portraitModalData.name}
                                            </div>
                                            <button onClick={handleClosePortraitModal}
                                                    className="absolute top-0 right-0 p-2 bg-white rounded-full">
                                                <FaTimes color="black" size="1.2em"/>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div className="attendee-icons flex flex-wrap mt-2 relative">
                                    {/* Display portrait icons for existing attendees */}
                                    {event.attendee_details && event.attendee_details
                                        .filter(attendee => attendee.status !== 'Wait list')
                                        .map((attendee, idx) => {
                                            const imageUrl = getImageUrl('userPortrait-' + attendee.student_id + '.jpeg');
                                            return (
                                                <div key={idx} className="relative flex-shrink-0">
                                                    <img
                                                        onClick={() => handlePortraitClick(attendee.first_name, imageUrl)}
                                                        src={imageUrl}
                                                        onError={(e) => e.target.src = '/images/portraits/unknown_male.png'}
                                                        alt={attendee.student_id}
                                                        className={`h-14 w-14 rounded-full mx-1 my-1 object-cover ${isUserImageUpdating ? 'animate-fadeInOut' : ''}`}
                                                    />
                                                </div>
                                            );
                                        })}
                                    {/* Display empty circles for available seats */}
                                    {Array.from({length: remainingCapacity > 0 ? remainingCapacity : 0}).map((_, idx) => {
                                        const isAnimating = idx === 0 && isImagePlaceholderUpdating;
                                        return (
                                            <div key={idx}
                                                 className={`mx-1 my-1 h-14 w-14 flex-shrink-0 rounded-full border-2 ${isAnimating ? 'animate-fill border-indigo-500' : 'border-gray-400'}`}></div>
                                        );
                                    })}
                                </div>
                                {/* Check if the current user is on the "Wait list" and display a message if true */}
                                {event.attendee_details && event.attendee_details
                                    .some(attendee => attendee.student_id === currentStudentId && attendee.status === 'Wait list') && (
                                    <div className="bg-red-500 mt-2 p-3 rounded-md shadow-lg">
                                        <p className="text-lg text-white font-bold text-center">
                                            You are on the Wait list.
                                        </p>
                                    </div>
                                )}
                            </div>
                            {viewMode === 'user' ? (
                                <button
                                    className={`absolute ${isRsvpButtonUpdating ? 'animate-pulse cursor-not-allowed' : ''} right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center ${
                                        event.is_cancelled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-700'
                                    } text-white font-bold h-8 w-20 rounded-full`}
                                    onClick={() => {
                                        if (!event.is_cancelled && !isRsvpButtonUpdating) {
                                            // onRSVPClick(event.event_id);
                                            navigate(`/event/${event.event_id}`)
                                        }
                                    }}
                                    disabled={event.is_cancelled || isRsvpButtonUpdating}
                                >
                                    {event.is_cancelled ? 'Cancelled' : 'Details'}
                                </button>
                            ) : (
                                <>
                                    <button
                                        className={`absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center ${
                                            event.is_cancelled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-700'
                                        } text-white font-bold h-8 w-20 rounded-full`}
                                        onClick={() => onEditClick(event.event_id)}
                                        disabled={event.is_cancelled}
                                    >
                                        {
                                            event.is_cancelled ? 'Cancelled' : 'Edit'
                                        }
                                    </button>
                                </>
                            )
                            }
                        </li>
                    )
                })}
                {viewMode === 'manage' ? (
                    <button
                        className="mt-5 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full"
                        onClick={createNewEvent}
                    >
                        New Event
                    </button>
                ) : (
                    <></>
                )}
            </ol>
        </>
    );
};

export default CalendarEventList;
