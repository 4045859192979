import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setLastPage} from "../redux/navSlice";

const lookupCourseDetails = (course) => {
    let description;
    let options;
    let courseName = course.course_name;
    let courseCode = course.course_code;
    let imageAlt = `${courseName} course`;
    let image_url = course.image_url;

    switch (courseCode) {
        case 'PADI-OW':
            description = 'Your first scuba diving certification opens the door to a lifetime of adventures.';
            options = 'BEGINNER';
            image_url = image_url || 'images/PADI/OW-Gallery-hero-1-TlujvLJ-.jpg';
            break;
        case 'PADI-AOW':
            description = 'Experience new adventures with an instructor by your side.  Designed for novice divers who want to improve their skills.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/AOW-Gallery-hero-2-dsKTrUz_.jpg';
            break;
        case 'PADI-RESCUE':
            description = 'Improve your confidence and become a better dive buddy through fun role-playing and skill practice.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Rescue-Gallery-hero--lFaWM8k9.jpg';
            break;
        case 'PADI-REACT':
            description = 'Dive into your next adventure with confidence.  Regain the skills you learned in your first scuba diving course without starting from scratch.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Reactivate-Gallery-h-w3FDETyh.jpg';
            break;
        case 'PADI-DT':
            description = 'Take a deep dive into the science of scuba to expand your knowledge or prepare for a divemaster/instructor course.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/DiveTheory-Gallery-H-TaD8tTaK.jpg';
            break;
        case 'PADI-EAN':
            description = 'Nitrox helps you get more out of your scuba certification.  Get nitrox certified in as little as one day.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Eax-Gallery-hero-1-pYvUsPuz.jpg';
            break;
        case 'PADI-DEEP':
            description = 'Extend your limits and see more of the underwater world.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/DeepDiver-Gallery-He-T9H3_nIU.jpg';
            break;
        case 'PADI-DRY':
            description = 'Want to dive more places, more often?  Learn drysuit diving.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/DrySuitDiver-Gallery-89u2U_E1.jpg';
            break;
        case 'PADI-DM':
            description = 'Learn how to lead dives, assist with classes and be the diver everyone looks up to.  Your scuba career starts here.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/DM_AltImages-2_Artbo-IGtYMt6J.png';
            break;
        case 'PADI-DUP':
            description = "Capture underwater photos you'll be proud to share. Learn pro tips that work with any camera.";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/DUP1-G92nct94.jpg';
            break;
        case 'PADI-UN':
            description = 'Tired of getting lost? Learn how to navigate underwater like a pro.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/UN2-b0FkhHx8.jpg';
            break;
        case 'PADI-PPB':
            description = 'Improve your buoyancy control, dive with less weight and improve air consumption.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/PPB1-TaeMHJYU.jpg';
            break;
        case 'PADI-EFR':
            description = 'Gain the skills and knowledge that could save the life of some-one you care about.';
            options = 'BEGINNER';
            image_url = image_url || 'images/PADI/EFR-Gallery-Hero-1(2-UNG2ieFq.jpg';
            break;
        case 'PADI-WRECK':
            description = 'Every wreck dive is an opportunity for adventure and new discoveries.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Wreck2-6UsBuplg.jpg';
            break;
        case 'PADI-NIGHT':
            description = 'See the underwater world in a whole new light - after the sun goes down.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Night1-9hOmPfBN.jpg';
            break;
        case 'PADI-SERE':
            description = 'Help recover lost items.  Learn effective underwater searc techniques and how to use a lift bag.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/SERE1-v62AB2Hv.jpg';
            break;
        case 'PADI-EQSP':
            description = 'Learn how to make basic repairs and protect your equipment investment.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/ES1-5cnt14-j.jpg';
            break;
        case 'PADI-DPV':
            description = "A DPV allows you to explore more of the underwater world in less time and conserve air.  They're also extremely fun!"
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/DPV2-1tnSpn27.jpg';
            break;
        case 'PADI-DRIFT':
            description = 'Find out what it feels like to fly underwater.  Gain the skills you need to dive confidently in a current.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Drift1-4lVae3Ps.jpg';
            break;
        case 'PADI-BOAT':
            description = "Learn boat diving etiquette, terminology and important do's and don'ts.";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/BD3-GnkZglmN.jpg';
            break;
        case 'PADI-DLD':
            description = 'Have an experienced dive professional help you understand a local dive environment so you can better enjoy the dive adventure.';
            options = 'FUN-DIVES';
            image_url = image_url || 'images/PADI/discover-local-diving.jpg';
            break;
        case 'PADI-CAVERN':
            description = 'Gain the knowledge and skills to explore caverns correctly and safely.  This course guides you to enter far enough for adventure, but stay within the light zone for an easy exit to open water.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/cavern-diver.jpg';
            break;
        case 'PADI-ICE':
            description = 'Ice diving is one of the most adventurous scuba specialities.  Learn the roles and responsibilities of support personnel, tenders and safety divers. See beauty few others ever experience.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/IceDiver-M3bUeCkg.jpeg';
            break;
        case 'PADI-ADVENTURE':
            description = 'Complete three Adventure Dives and earn the Adventure Diver certification, a subgroup of the PADI Advanced Open Water Diver Course.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/adventure-diver_0.jpg';
            break;
        case 'PADI-MSD':
            description = 'Join the best of the best, the elite group of respected divers who have earned this rating through both significant experience and scuba training.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/master-scuba-diver.jpg';
            break;
        case 'PADI-SCUBA':
            description = "This course is a subset of the PADI Open Water Diver course.  If you're short on time but really want to become a diver, the PADI Scuba Diver rating might be right for you.";
            options = 'BEGINNER';
            image_url = image_url || 'images/PADI/padi-scuba-diver_0.jpg';
            break;
        case 'PADI-NATURE':
            description = 'Get a better understanding about the local ecosystem and the differences between terrestrial and aquatic worlds.  See new things, even on the most familiar scuba diving sites.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/underwater-naturalist.jpg';
            break;
        case 'PADI-ALT':
            description = 'Diving in altitudes higher than 300 metres/1000 feet above sea level is altitude diving.  Discover a hidden world where only a few have ventured.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/altitude-diver_0.jpg';
            break;
        case 'PADI-ADAPT':
            description = "Increase your awareness of divers' varying abilities, and explore adaptive techniques to apply while diving or freediving with a buddy wit a disability.";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/adaptive_hero_standard_com.jpg';
            break;
        case 'PADI-SHARK':
            description = 'Sharks are an essential part of a healthy ocean. This course explores why they are vulnerable, the importance of a healthy shark population, managing threats and how to take action to protect them.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/aware-shark-conservation.jpg';
            break;
        case 'PADI-FULL':
            description = 'Diving with a full face mask allows you to breathe from your nose and mouth, and provides added comfort in colder water.  The mask can also be fitted with communication gear, which can be useful for various types of specialty diving.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/fullfacemaskdiver.jpg';
            break;
        case 'PADI-BUBBLE':
            description = 'Experience scuba diving under the direct supervision of a PADI Pro.  Take your first breaths underwater in water shallower than 2 metres/6 feet. For children who are at least 8 years old.';
            options = 'YOUTH';
            image_url = image_url || 'images/PADI/bubble-maker.jpg';
            break;
        case 'PADI-DSMB':
            description = "Many divers wouldn't think of diving without carrying a delayed surface marker buoy (DSMB, and in some locations it's considered required safety equipment.  KNowing how to properly deploy and manage a DSMB are valuable skilsl learned in this course.";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/dsmb1.jpg';
            break;
        case 'PADI-SR':
            description = 'Although diving with a buddy is the norm, there are reasons for an experienced diver to develop self-reliance and independence while diving.  Learning to carry out dives without a partner can make you a stronger diver in most dive situations.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/self reliant diver_0.jpg';
            break;
        case 'PADI-OXYGEN':
            description = 'Breathe easy with PADI Emergency Oxygen Provider training.  Learn how to administer emergency oxygen for scuba diving injuries.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/Emergency_oxygen_pro-HgBkG35u.jpg';
            break;
        case 'PADI-VIDEO':
            description = 'Capture the sights, sounds, and the dynamic motion of th underwater world as a videographer. Cover the fundamentals and create interesting, entertaining video worth watching again and again.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || '';
            break;
        case 'PADI-FISH':  // TODO: NOT IN COURSES TABLE
            description = 'Enjoy dives even more when you can recognize and identify fish families and their characteristics.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || '';
            break;
        case 'PADI-DEBRIS': // TODO: NOT IN COURSES TABLE
            description = 'Pay the ocean forward by helping clean it up on your next dive.  Learn about the problems of marine debris, conducting Dive Against Debris surveys, and how divers are a part of the solution to keeping our oceans clean.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || '';
            break;
        case 'PADI-SIDE':
            description = "Don't like wearing a heavy tank on your back? Sidemount diving is a comfortable, streamlined alternative.";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || '';
            break;
        case 'PADI-DSD':
            description = "If you're not ready to sign up for a scuba certification course, Discover Scuba Diving (DSD) is a fun way to try scuba diving.";
            options = 'BEGINNER';
            image_url = image_url || '';
            break;
        case 'PADI-CD':
            description = 'This rating is the highest and most respected professional rating in recreational scuba diving.  To become a Course Director, you must earn a spot in a Course Director Training Course (CDTC).';
            options = 'PROFESSIONAL';
            image_url = image_url || 'Course Director.jpg';
            break;
        case 'PADI-DTEC':
            description = 'This short course is designed to let divers experience techncial diving in confined water.  Get introducted a few basic skills and procedures.  Discover Tec may credit towards the Tec 40 course.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'discover-technical-diving.jpg';
            break;
        case 'PADI-MFDI':
            description = 'PADI Advanced Freediver Instructors or advanced freediver instructors from other organizations who have teaching experience can qualify for the PADI Master Freediver Instructor ratings.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/master-freediver-instructor.jpg';
            break;
        case 'PADI-AFD':
            description = 'Designed to refine and improve your freediving skills, allowing you to progress slowly while learning how your body performs during apnea (suspension of breathing).';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/advanced_freediver_r1.jpg';
            break;
        case 'PADI-FDI':
            description = 'Turn your freediving passion into a profession.  This instructor course is for the avid freediver who spends a lot of time thinking about freediving and sharing details of their latest adventures.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/freediver-instructor.jpg';
            break;
        case 'PADI-FDIT':
            description = 'PADI Freediver Instructor Trainers are experienced PADI Master Freediver Instructors who have joined an elite group of professionals to develop the next generation of PADI Freediver Instructors.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/freediver-instructor-trainer.jpg';
            break;
        case 'PADI-BFD':
            description = 'Learn basic freediving principles and focus on practicing breathhold techniques in a pool or confined water site.';
            options = 'BEGINNER';
            image_url = image_url || 'images/PADI/Basic Freediver.jpg';
            break;
        case 'PADI-IDCSI':
            description = 'This course provides you with in-depth knowledge of the instructor development process and prepares you to shape the next generation of PADI Professionals.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/IDC-staff-instructor.jpg';
            break;
        case 'PADI-OWSI':
            description = 'This is a two-part course - the Assistant Instructor (AI) course and the Open Water Scuba Instructor (OWSI) program.  Become a better public speaker and get really good at demonstrating skills.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/open-water-instructor.jpg';
            break;
        case 'PADI-EFRI':
            description = 'Teach skills based on internationally recognized emergency care guidelines and learn how to present course content while encouraging student self-discovery.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/emergency-first-response-instructor.jpg';
            break;
        case 'PADI-REBREATH':
            description = "Get longer no stop limits, reduced gas conception (reuse most of your exhaled gas), and unmatched wildlife encounters because you don't release annoying bubbles";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/rebreather-diver_0.jpg';
            break;
        case 'PADI-AWARE':
            description = "Uniting scuba divers and water enthusiasts to make a difference.  By earning this certification, you'll learn how you can make a difference for ocean protection every time you dive or travel.";
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/1280x960_AWARE_Spec.jpg';
            break;
        case 'PADI-CORAL':
            description = 'this course helps you appreciate the complexity of coral reef habitats and teaches how you can help conserve these vital systems.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/coral-reef-conservation-diver_0.jpg';
            break;
        case 'PADI-TECDI':
            description = 'Tec Deep Instructors contribute to deep exploration by training the next generation of tec divers. Certifications from other diver training organizations may apply - ask your Tec Instructor Trainer.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/tec_deep_instructor_r2.jpg';
            break;
        case 'PADI-TGBI':
            description = 'Help meet the demand for qualified gas blenders.  As a Tec Gas Blender Instructor, you will understand the differences between gases and be able to eplain crtiical information to others.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/tec-gas-blender-instructor.jpg';
            break;
        case 'PADI-AREBREATH':
            description = 'Dive in silence and train to dive as deep as 40 metres/130 feet.  Learn about scrubbers, oxygen consumption and bailout requirements including how to configure a bailout cylinder system.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/advanced-rebreather_0.jpg';
            break;
        case 'PADI-AI':
            description = 'Start here to become a PADI dive instructor. Share your passion with others and help them experience the same joy you have for scuba diving.';
            options = 'PROFESSIONAL';
            image_url = image_url || 'images/PADI/assistant instructor development_0.jpg';
            break;
        case 'PADI-SKIN':
            description = 'Learn snorkeling and skin diving techniques including checking buoyancy, surface swimming, clearing water from your snorkel and performing effortless surface dives.';
            options = 'BEGINNER';
            image_url = image_url || 'images/PADI/skin-diver.jpg';
            break;
        case 'PADI-TSIDE':
            description = 'Wearing more than one tank has become increasingly popular for technical diving.  With the Tec Sidemount Diver course you can apply what you learn to other TecRec courses.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/sidemount-diver.jpg';
            break;
        case 'PADI-TECTRI65':
            description = 'Extend your depth range the right way with a blend of helium, oxygen, and nitrogen (trimix). The Tec Trimix 65 course introduces you to using trimix down to a maximum depth of 64 metres/210 feet.';
            options = 'CONTINUING-EDUCATION';
            image_url = image_url || 'images/PADI/tec-trimix-instructor.jpg';
            break;
        // Left off on page 6. https://www.padi.com/courses?sort=popularity&page=6
        case 'EFR-PSCARE':
            description = 'Emergency First Response® primary and secondary care training teaches you what to do in the critical moments between when a life-threatening emergency occurs and when emergency medical services arrive.';
            options = 'BEGINNER';
            image_url = image_url || 'images/PADI/EFR-Gallery-Hero-1(2-UNG2ieFq.jpg';
            break;
        default:
            description = 'Description not available.';
            options = '';
            image_url = '';
            break;
    }

    return {
        description: description,
        options: options,
        imageSrc: image_url,
        imageAlt: imageAlt,
    };
}

const Courses = () => {
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    return (
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            <h2 className="sr-only">Courses</h2>

            <div
                className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                {instrDetails.courses.map((course) => {
                    const {description, options, imageSrc, imageAlt} = lookupCourseDetails(course);
                    return (
                        <div
                            key={course.course_slug}
                            className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                        >
                            <div
                                className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                                <img
                                    src={imageSrc}
                                    alt={imageAlt}
                                    className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                                />
                            </div>
                            <div className="flex flex-1 flex-col space-y-2 p-4">
                                <h3 className="text-sm font-medium text-gray-900">
                                    <Link to={`/courses/${course.course_slug}`}>
                                        <span aria-hidden="true" className="absolute inset-0"/>
                                        {course.course_name}
                                    </Link>
                                </h3>
                                <p className="text-sm text-gray-500">{description}</p>
                                <div className="flex flex-1 flex-col justify-end">
                                    <p className="text-sm italic text-gray-500">{options}</p>
                                    <p className="text-base font-medium text-gray-900">
                                        {course.cost === null ? "Pricing Varies - Contact for Details" : `$${course.cost}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Courses;
