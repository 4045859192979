import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';
// import transactionsMockData from '../transactions.json'


export const fetchTransactions = createAsyncThunk(
    'students/fetchTransactions',
    async () => {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-transactions';
        const {body} = await get({apiName, path}).response;
        return await body.json();
        // return transactionsMockData;
    }
);

const initialState = {
    transactions: [],
    status: 'idle',
    error: null,
};

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        clearTransactions: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const {clearTransactions} = transactionsSlice.actions;
export default transactionsSlice.reducer;