import React from 'react';
import {Link} from 'react-router-dom';
import {Disclosure} from '@headlessui/react';

const NavItem = ({item, closeMenu, notification = false, mobile = false}) => {
    const handleLinkClick = (event) => {
        if (item.onClick) {
            item.onClick(event);
        }
        closeMenu();
    };

    return mobile ? (
        <Disclosure.Button
            as={Link}
            to={item.to}
            onClick={handleLinkClick}
            className="rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white relative flex items-center"
        >
            {item.name}
            {notification && (
                <span className="flex absolute right-3 h-full items-center">
                    <span
                        className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                </span>
            )}
        </Disclosure.Button>
    ) : (
        <Link
            to={item.to}
            onClick={(event) => {
                handleLinkClick(event);
                closeMenu();
            }}
            className="rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white relative flex items-center"
        >
            {item.name}
            {notification && (
                <span className="flex absolute right-3 h-full items-center">
                    <span
                        className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                </span>
            )}
        </Link>
    );
};

export default NavItem;
