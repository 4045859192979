import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingSpinner} from "./LoadingSpinner";
import {fetchStudents} from "../redux/studentsSlice";

const StudentCharges = () => {
    const {id} = useParams();
    const student = useSelector((state) =>
        state.students.studentList.find((student) => student.id === parseInt(id))
    );
    const studentsStatus = useSelector((state) => state.students.status);
    const studentsError = useSelector((state) => state.students.error);
    const [errorInfo, setErrorInfo] = useState(null);
    const dispatch = useDispatch();
    const charges = student?.user_charges;

    useEffect(() => {
        if (!student && studentsStatus === 'idle') {
            dispatch(fetchStudents());
        }
    }, [studentsStatus, dispatch, student]);

    if (!student) {
        if (studentsStatus === 'loading') {
            return <LoadingSpinner isLoading={studentsStatus === 'loading'}/>
        } else {
            return <div>Student not found</div>;
        }
    }

    if (studentsStatus === 'failed') {
        setErrorInfo(studentsError);
    }

    const handleAddClick = () => {
        // TODO: implement modal to select event and epc_id to RSVP into. This triggers same workflow as student selecting RSVP.
        // setSelectedCert({
        //     course_code: '',
        //     cert_number: '',
        //     cert_date: '',
        //     equivalency: false,
        //     verified: false
        // });
        // setIsEditing(false);
        // setIsModalOpen(true);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard')
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatDateTime = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes ? String(minutes).padStart(2, '0') : '';
        return minutes ? `${year}-${month}-${day} ${hours}:${minutes} ${ampm}` : `${year}-${month}-${day} ${hours}${ampm}`;
    };

    const formatCurrency = (amount) => {
        return amount.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    };

    if (errorInfo) {
        console.log(errorInfo);
        throw errorInfo;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Student Charges</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        ...
                    </p>
                    <h1 className="mt-4 text-base font-semibold leading-6 text-gray-900">
                        <button
                            onClick={() => copyToClipboard(student?.first_name)}
                            className="mt-4 text-base font-semibold leading-6 text-gray-900"
                        >
                            {student?.first_name}
                        </button>
                        &nbsp;
                        {student?.middle_init ? student?.middle_init + ' ' : ''}
                        <button
                            onClick={() => copyToClipboard(student?.last_name)}
                            className="mt-4 text-base font-semibold leading-6 text-gray-900"
                        >
                            {student?.last_name}
                        </button>

                    </h1>
                    <button
                        onClick={() => copyToClipboard(student?.preferred_email)}
                        className="mt-2 text-sm text-gray-700"
                    >
                        {student?.preferred_email}
                    </button>
                    <p className="mt-2 text-sm text-gray-700">DOB: {student?.date_of_birth}</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        onClick={handleAddClick}
                        className="mb-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        New Charge
                    </button>
                </div>
            </div>
            <div className="mx-1 mt-8 sm:-mx-0">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Product
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                            Price
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Fees
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                    {charges?.map((charge) => (
                        <tr key={charge.entity_type + charge.reference_id}>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                {charge.course_code
                                    ? charge.course_code
                                    : charge.event_title
                                        ? (
                                            <>
                                                {charge.event_title}
                                                <br/>
                                                {formatDateTime(charge.event_start_time)}
                                            </>
                                        )
                                        : ''}
                                <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">Price</dt>
                                    <dd className="mt-1 truncate text-gray-700">{charge.subtotal}</dd>
                                </dl>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell">{formatCurrency(charge.subtotal)}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{formatCurrency(charge.taxes + charge.shipping)}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{charge.status}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
        ;
};

export default StudentCharges;
