import {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {FaCheck} from "react-icons/fa";
import {MdCheckCircleOutline, MdHelpOutline, MdInfoOutline, MdMenu} from "react-icons/md";
import {BiError} from "react-icons/bi";
import {IoWarningOutline} from "react-icons/io5";

export default function GenericModal({isOpen, closeModal, modalContent, buttonActions}) {
    if (!isOpen) return null;

    const iconMapping = {
        success: {
            icon: <MdCheckCircleOutline/>,
            iconBgClass: 'bg-green-100'
        },
        error: {
            icon: <BiError/>,
            iconBgClass: 'bg-red-100'
        },
        warning: {
            icon: <IoWarningOutline />,
            iconBgClass: 'bg-yellow-100'
        },
        info: {
            icon: <MdInfoOutline/>,
            iconBgClass: 'bg-blue-100'
        },
        confirm: {
            icon: <MdHelpOutline/>,
            iconBgClass: 'bg-blue-100'
        },
        options: {
            icon: <MdMenu className="text-indigo-500"/>,
            iconBgClass: 'bg-indigo-100'
        }
    };

    function getModalContentByType(type) {
        return iconMapping[type] || {};
    }

    const { icon, iconBgClass } = getModalContentByType(modalContent.type);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div
                                        className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${iconBgClass}`}>
                                        {icon}
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            {modalContent.title}
                                        </Dialog.Title>
                                        <div className="mt-2 text-sm text-gray-600">
                                            {modalContent.body}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    {modalContent.buttons.map((button, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`mb-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 text-white ${button.className}`}
                                            onClick={buttonActions[button.action]}
                                        >
                                            {button.text}
                                        </button>
                                    ))}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
