import React, {useEffect, useState} from 'react';
import {haversineDistance} from "../functions/haversine";
import {getImageUrl} from "../utils/sanitizeFunctions";

const InstructorCard = ({instr, desiredLocation, onInstructorClick}) => {
    const getFirstNonEfrOrgMemNum = (proLevelInfo) => {
        const firstNonEfr = proLevelInfo ? proLevelInfo.find(info => info.organization !== 'EFR') : null;
        return firstNonEfr ? `${firstNonEfr.organization}-${firstNonEfr.member_number}` : null;
    }

    const instrOrgMemNum = getFirstNonEfrOrgMemNum(instr.pro_level_info);
    const [instrImage, setInstrImage] = useState('/images/portraits/unknown_male.png');

    let distance = 0;
    let distanceUnit = "K";
    const userLang = navigator.language;

    if (userLang === 'en-US') {
        distanceUnit = "M";
    }

    if (desiredLocation) {
        const {lat: lat1, long: lon1} = desiredLocation;
        const {latitude: lat2, longitude: lon2} = instr;
        distance = haversineDistance(lat1, lon1, lat2, lon2, distanceUnit);
    }

    useEffect (() => {
        // console.log(instrOrgMemNum);
        if (instrOrgMemNum) {
            setInstrImage(getImageUrl(instrOrgMemNum + '.jpeg'));
            // console.log(instrImage);
        }
    }, [instrOrgMemNum])

    return (
        <div onClick={onInstructorClick}
             className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
            <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                <img className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                     src={instrImage} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/portraits/unknown_male.png"
                }}
                     alt="Instructor Portrait"
                />
            </div>
            <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-base font-medium text-gray-900">
                    {instr.first_name} {instr.last_name}
                </h3>
                {(instr.pro_level_info || []).map((info, index) => (
                    <p key={index} className="text-sm text-gray-700">
                        {`${info.organization} ${info.pro_level} ${info.member_number ? '#' + info.member_number : ''}`}
                    </p>
                ))}
                <div>
                    <p className="text-sm italic text-gray-700">{instr.location}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-700">
                        {`${distance.toFixed(2)} ${distanceUnit === 'M' ? 'miles' : 'km'} away`}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InstructorCard;
