import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchInstructors, setSelectedInstructorId} from '../redux/instructorsSlice';
import InstructorCard from '../components/InstructorCard';
import {setDesiredLocation, setCourseFilter, setRawInput} from "../redux/instructorsSlice";
import {useNavigate} from "react-router-dom";
import {haversineDistance} from "../functions/haversine";
import {LoadingSpinner} from "./LoadingSpinner";
import {post} from "aws-amplify/api";
import {MdGpsFixed} from "react-icons/md";

const sortInstructorsByDistance = (instructors, desiredLocation) => {
    return instructors.slice().sort((a, b) => {
        const {lat: lat1, long: lon1} = desiredLocation;
        const {latitude: lat2a, longitude: lon2a} = a;
        const {latitude: lat2b, longitude: lon2b} = b;

        const distanceA = haversineDistance(lat1, lon1, lat2a, lon2a);
        const distanceB = haversineDistance(lat1, lon1, lat2b, lon2b);

        return distanceA - distanceB;
    });
};

const InstructorDirectory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {desiredLocation} = useSelector((state) => state.instructors);
    const rawInput = useSelector((state) => state.instructors.desiredLocation.rawInput) || '';
    const {instructors, courseFilter, status} = useSelector((state) => state.instructors);
    const [allCourses, setAllCourses] = useState([]);
    const [errorInfo, setErrorInfo] = useState(null);
    const [isApiCalling, setIsApiCalling] = useState(false);

    useEffect(() => {
        if (Array.isArray(instructors)) {
            const uniqueCourses = Array.from(
                instructors
                    .flatMap((instr) => instr.courses || [])
                    .reduce((map, course) => {
                        if (course && course.course_name) {
                            return map.set(course.course_name, course);
                        }
                        return map;
                    }, new Map())
                    .values()
            );
            setAllCourses(uniqueCourses);
        }
    }, [instructors]);

    useEffect(() => {
        async function fetchGeoLocation() {
            const apiName = 'InstrOfScubaAPI'
            const path = '/get-geolocation'
            const options = {
                method: 'POST',
                body: {location: rawInput},
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = await body.json();
                const formattedAddress = data.formattedAddress;
                const lat = data.lat;
                const long = data.long;

                dispatch(setDesiredLocation({
                    lat: lat,
                    long: long,
                    formattedAddress: formattedAddress,
                }));
            } catch (error) {
                setErrorInfo('InstructorDirectory-fetchGeoLocation\n' + error);
            }
        }

        if (rawInput?.length > 3) {  // Only start geocoding when input length > 3
            fetchGeoLocation();
        }
    }, [rawInput, dispatch]);

    const filteredInstructors = Array.isArray(instructors) ? (courseFilter !== '' ?
        instructors.filter(instr => (instr.courses ?? []).some(course => course.course_name === courseFilter)) : instructors) : [];
    let sortedInstructors = filteredInstructors;
    if (desiredLocation) {
        sortedInstructors = sortInstructorsByDistance(filteredInstructors, desiredLocation);
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchInstructors());
        }
    }, [status, dispatch]);

    const isLoading = status === 'loading';
    if (isLoading) {
        return (
            <div className="flex flex-col justify-center items-center min-h-[calc(100vh-64px-48px)] bg-gray-100">
                <LoadingSpinner isLoading={isLoading}/>
            </div>
        )
    }

    if (status === 'failed') {
        throw new Error('Error loading instructors.');
    }

    const handleInstructorClick = (instrId) => {
        dispatch(setSelectedInstructorId(instrId));
        navigate('/about-instructor');
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        dispatch(setRawInput(value));
    }

    const handleLocationClick = async () => {
        setIsApiCalling(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                const apiName = 'InstrOfScubaAPI';
                const path = '/get-geolocation';
                const options = {
                    method: 'POST',
                    body: {lat: latitude, long: longitude},
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                try {
                    const {body} = await post({apiName, path, options}).response;
                    const data = await body.json();
                    const formattedAddress = data.formattedAddress;

                    dispatch(setDesiredLocation({
                        lat: latitude,
                        long: longitude,
                        formattedAddress: formattedAddress,
                    }));

                    dispatch(setRawInput(formattedAddress));
                } catch (error) {
                    setErrorInfo('InstructorDirectory-handleLocationClick\n' + error);
                }
                setIsApiCalling(false);
            }, (error) => {
                console.warn(`ERROR(${error.code}): ${error.message}`);
                setIsApiCalling(false);
            });
        } else {
            alert("Geolocation is not supported by this browser.");
            setIsApiCalling(false);
        }
    };


    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-2 gap-4 pb-4">
                <div>
                    <label htmlFor="course" className="block text-sm font-medium leading-6 text-gray-900">Filter
                        by course offering: </label>
                    <select
                        value={courseFilter}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        id="course"
                        onChange={(e) => dispatch(setCourseFilter(e.target.value))}>
                        <option value="">All</option>
                        {allCourses.map((course, index) => (
                            <option key={index} value={course.course_name}>{course.course_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                        Desired location:
                    </label>
                    <div className="flex flex-row w-full">
                        <input
                            type="text"
                            id="location"
                            value={rawInput}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <button
                            onClick={handleLocationClick}
                            disabled={isApiCalling}
                            className={`mx-1 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm 
              ${isApiCalling ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}`}
                        >
                            <MdGpsFixed className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                    <small
                        className="block mt-2 text-gray-600">{desiredLocation.formattedAddress ? 'Matched Location: ' + desiredLocation.formattedAddress : 'Try a location name, such as a city and state, a zipcode, country name, etc.'}
                    </small>
                </div>
            </div>
            <h2 className="sr-only">Instructors</h2>
            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                {Array.isArray(sortedInstructors) ?
                    sortedInstructors.map((instr) => (
                        <InstructorCard
                            key={instr.instr_id}
                            instr={instr}
                            desiredLocation={desiredLocation}
                            onInstructorClick={() => handleInstructorClick(instr.instr_id)}
                        />
                    )) : <p>No data available</p>
                }
            </div>
        </div>
    );
};

export default InstructorDirectory;
