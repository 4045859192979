import {checkUser, refreshToken} from './authSlice';
import {Hub} from 'aws-amplify/utils';
import {debounce} from "../containers/sharedHooks";

const debouncedCheckUser = debounce((storeAPI) => storeAPI.dispatch(checkUser()), 1000);
let listenerRegistered = false;

const authMiddleware = (storeAPI) => (next) => (action) => {
    if (!listenerRegistered) {
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signedIn':
                case 'signedOut':
                    debouncedCheckUser(storeAPI);
                    break;
                default:
                    break;
            }
        });
        listenerRegistered = true;
    }

    if (action.type === 'auth/checkUser/rejected') {
        const error = action.payload.error;
        if (error === 'Invalid login token') {
            storeAPI.dispatch(refreshToken());
        }
    }

    return next(action);
};

export default authMiddleware;
