import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bioVersions: []
};

const bioSlice = createSlice({
    name: 'bio',
    initialState,
    reducers: {
        saveBioVersions: (state, action) => {
            state.bioVersions = action.payload;
        },
    },
});

export const { saveBioVersions } = bioSlice.actions;
export const selectBioVersions = state => state.bio.bioVersions;
export default bioSlice.reducer;
