import React, {useEffect, useState} from 'react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {Combobox} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {fetchLocations} from "../redux/locationSlice";
import {MdSave, MdCancel} from "react-icons/md";
import {post} from "aws-amplify/api";

const ManageLocations = ({onExitManageLocations, shouldFetchLocations, setShouldFetchLocations}) => {
    const [errorInfo, setErrorInfo] = useState(null);
    const locations = useSelector((state) => state.locations.locations);
    const [query, setQuery] = useState('')
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const [selectedLocation, setSelectedLocation] = useState("New Location");
    const [isNewLocation, setIsNewLocation] = useState(true);
    const [inputLabel, setInputLabel] = useState("New Location Name");
    const [formStatus, setFormStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const sliceError = useSelector((state) => state.locations.error);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [formData, setFormData] = useState({
        location_id: -1,  // -1 will indicate new location to the backend save function.
        location_name: '',
        description: '',
        directions: '',
        parking_coordinates: '',
        linkup_coordinates: '',
        activity_coordinates: '',
        location_type: '',
    });

    const [formErrors, setFormErrors] = useState({
        location_name: null,
        description: null,
        directions: null,
        parking_coordinates: null,
        linkup_coordinates: null,
        activity_coordinates: null,
        location_type: null
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleLocationComboChange = (newLocation) => {
        setSelectedLocation(newLocation);

        if (newLocation === 'New Location') {
            setIsNewLocation(true);
            setInputLabel("New Location Name");
        } else {
            setIsNewLocation(false);
            setInputLabel("Updated Location Name");
        }

        // Find the full location object from the locations array
        const locationObj = locations.find(loc => loc.location_name === newLocation);

        if (locationObj) {
            setFormData({
                ...formData,
                location_name: locationObj.location_name,
                location_id: locationObj.location_id,
                description: locationObj.description,
                directions: locationObj.directions,
                parking_coordinates: locationObj.parking_coordinates,
                linkup_coordinates: locationObj.linkup_coordinates,
                activity_coordinates: locationObj.activity_coordinates,
                location_type: locationObj.location_type
            });
        }
    };

    const handleLocationTypeComboChange = (newLocationType) => {
        setFormData({...formData, location_type: newLocationType});
    }

    const filteredLocations =
        query === ''
            ? locations
            : locations.filter((location) => {
                return location.location_name
                    ? location.location_name.toLowerCase().includes(query.toLowerCase())
                    : false;
            })

    const locationTypes = [
        {id: 1, name: 'Shore'},
        {id: 2, name: 'Boat'},
        {id: 3, name: 'Pool'},
        {id: 4, name: 'Classroom'},
    ]

    const filteredLocationTypes =
        query === ''
            ? locationTypes
            : locationTypes.filter((locationType) => {
                return locationType.name
                    ? locationType.name.toLowerCase().includes(query.toLowerCase())
                    : false;
            })

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let errors = validate(formData);

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setFormStatus({error: 'Some information was missing or invalid.  Please check again.'})
        } else {
            setFormErrors({})
            const apiName = 'InstrOfScubaAPI'
            const path = '/save-location'
            const options = {
                method: 'POST',
                body: {formData, instr_id},
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            try {
                await post({apiName, path, options});
                setShouldFetchLocations(true);
                onExitManageLocations();
            } catch (error) {
                setErrorInfo('handleSubmit - /save-location\n' + error);
            }
        }

        setIsSubmitting(false);
    }

    function validate(formData) {
        let errors = {};
        if (!formData.location_name.trim()) {
            errors.location_name = 'Location Name is required';
        }

        if (!formData.location_type.trim()) {
            errors.location_type = 'Location Type is required';
        }

        if (!formData.description) {
            errors.description = 'Description is required';
        }

        if (!formData.directions) {
            errors.directions = 'Directions are required';
        }

        if (!formData.parking_coordinates) {
            errors.parking_coordinates = 'Parking Coordinates are required';
        }

        if (!formData.linkup_coordinates) {
            errors.linkup_coordinates = 'Linkup Coordinates are required';
        }

        if (!formData.activity_coordinates) {
            errors.activity_coordinates = 'Activity Coordinates are required';
        }

        if (!isValidLatLong(formData.parking_coordinates)) {
            errors.parking_coordinates = 'Parking Coordinates are not valid';
        }

        if (!isValidLatLong(formData.linkup_coordinates)) {
            errors.linkup_coordinates = 'Linkup Coordinates are not valid';
        }

        if (!isValidLatLong(formData.activity_coordinates)) {
            errors.activity_coordinates = 'Activity Coordinates are not valid';
        }

        return errors;
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (shouldFetchLocations) {
            // console.log('fetching locations for instr_id: ', instr_id);
            dispatch(fetchLocations({instr_id}));
            setShouldFetchLocations(false);
        }
    }, [instr_id, shouldFetchLocations]);

    useEffect(() => {
        if (sliceError) {
            setErrorInfo(`fetchLocations - /get-locations\n${sliceError}`);
        }
    }, [sliceError]);

    if (errorInfo) {
        throw errorInfo;
    }

    function isValidLatLong(latLong) {
        const latLongRegex = /^([-+]?[1-8]?\d(\.\d+)?|90(\.0+)?),\s*([-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/;
        return latLongRegex.test(latLong);
    }

    return (
        <form noValidate onSubmit={handleSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Manage Locations
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Locations are used with calendar events to indicate where the event will take place. Your
                        customers will get an email when they register for an event that contains all this information
                        along with links to a map with directions as well as an easily importable calendar event.
                        Take the time now to ensure the data is complete and accurate to ensure the best possible
                        experience for your customers.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2 sm:col-start-1">
                            <Combobox as="div" value={selectedLocation} onChange={handleLocationComboChange}>
                                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                    Existing Location to Modify
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(event) => setQuery(event.target.value)}
                                        displayValue={(location) => location}
                                    />
                                    <Combobox.Button
                                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </Combobox.Button>

                                    {filteredLocations.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredLocations.map((location) => (
                                                <Combobox.Option
                                                    key={location.location_id}
                                                    value={location.location_name}
                                                    className={({focus}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                        )
                                                    }
                                                >
                                                    {({focus: isFocused, selected}) => (
                                                        <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{location.location_name}</span>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        isFocused ? 'text-white' : 'text-indigo-600'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="location_name"
                                   className={`block text-sm font-medium leading-6 ${formErrors.location_name ? 'text-red-900' : 'text-gray-900'}`}>
                                {inputLabel}
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="location_name"
                                    id="location_name"
                                    value={formData.location_name}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.location_name ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                            {/*<p className="mt-2 text-sm text-gray-500">*/}
                            {/*</p>*/}
                        </div>
                        <div className="sm:col-span-2">
                            <Combobox as="div" value={formData.location_type} onChange={handleLocationTypeComboChange}>
                                <Combobox.Label className={`block text-sm font-medium leading-6 ${formErrors.location_type ? 'text-red-900' : 'text-gray-900'}`}>
                                Location Type
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className={`w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 ${formErrors.location_type ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6`}
                                        onChange={(event) => setQuery(event.target.value)}
                                        displayValue={(locationType) => locationType}
                                    />
                                    <Combobox.Button
                                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </Combobox.Button>

                                    {filteredLocationTypes.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredLocationTypes.map((locationType) => (
                                                <Combobox.Option
                                                    key={locationType.id}
                                                    value={locationType.name}
                                                    className={({focus}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                        )
                                                    }
                                                >
                                                    {({focus, selected}) => (
                                                        <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{locationType.name}</span>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        focus ? 'text-white' : 'text-indigo-600'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-3 sm:col-start-1">
                            <label htmlFor="description"
                                   className={`block text-sm font-medium leading-6 ${formErrors.description ? 'text-red-900' : 'text-gray-900'}`}>
                                Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    id="description"
                                    rows="5"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.description ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="directions"
                                   className={`block text-sm font-medium leading-6 ${formErrors.directions ? 'text-red-900' : 'text-gray-900'}`}>
                                Directions
                            </label>
                            <div className="mt-2">
                                <textarea
                                    name="directions"
                                    value={formData.directions}
                                    onChange={handleChange}
                                    id="directions"
                                    rows="5"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.directions ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2 sm:col-start-1">
                            <label htmlFor="parking_coordinates"
                                   className={`block text-sm font-medium leading-6 ${formErrors.parking_coordinates ? 'text-red-900' : 'text-gray-900'}`}>
                                Parking Coordinates
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="parking_coordinates"
                                    id="parking_coordinates"
                                    value={formData.parking_coordinates}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.parking_coordinates ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="linkup_coordinates"
                                   className={`block text-sm font-medium leading-6 ${formErrors.linkup_coordinates ? 'text-red-900' : 'text-gray-900'}`}>
                                Linkup Coordinates
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="linkup_coordinates"
                                    id="linkup_coordinates"
                                    value={formData.linkup_coordinates}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.linkup_coordinates ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="activity_coordinates"
                                   className={`block text-sm font-medium leading-6 ${formErrors.activity_coordinates ? 'text-red-900' : 'text-gray-900'}`}>
                                Activity Coordinates
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="activity_coordinates"
                                    id="activity_coordinates"
                                    value={formData.activity_coordinates}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.activity_coordinates ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-6 sm:col-start-1">
                            <p className="text-sm leading-6 text-gray-600">
                                Coordinates are in the format of latitude, longitude. You can find these by using Google
                                Maps. Right click on the location and click the first option displaying the coordinates.
                                This will copy them to your clipboard. They should look something like:
                                21.39688, -157.75710. Paste them into the appropriate box above. If you are using a
                                mobile device, long press on the location and the coordinates will be displayed at the
                                bottom of the screen. Tap on the coordinates to copy them to your clipboard. How to
                                use the three coordinate types: <u>Parking</u> is self-explanatory, and is
                                what is used in the calendar event so they can quickly navigate with driving
                                direction. <u>Linkup</u> is where they walk to meet you after parking. Perhaps
                                that's on a specific pier in the harbor, the door to your building, or down by the
                                beach. <u>Activity</u> is where the actual activity takes place, perhaps the point in
                                the water where you will descend to begin the dive, or the location of the pool where
                                you will be teaching the class.
                            </p>
                        </div>
                        <div className="sm:col-span-2">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${formStatus && (formStatus.error) || isSubmitting ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                <MdSave className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                            <button
                                type="button"
                                onClick={onExitManageLocations}
                                className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                                <MdCancel className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                formStatus && (formStatus.error ?
                        <div
                            className="w-full rounded-md bg-red-200 text-red-500 text-center font-bold py-2 mt-2">{formStatus.error}</div>
                        :
                        <div
                            className="w-full rounded-md bg-green-200 text-green-500 text-center font-bold py-2 mt-2">{formStatus.success}</div>

                )
            }
        </form>
    );
};

export default ManageLocations;
