import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setLastPage} from "../redux/navSlice";
import PaymentGateway from "./PaymentGateway";
import {addProductIfNotExists} from "../redux/paymentGatewaySlice";
import {fetchOutstandingData} from "../redux/outstandingDataSlice";
import {LoadingSpinner} from "./LoadingSpinner";
import withAuth from "./withAuth";

const OutstandingCharges = () => {
    const outstandingCharges = useSelector(state => state.outstandingData.outstandingCharges);
    const lastFetchedCharges = useSelector(state => state.outstandingData.lastFetchedCharges);
    const dispatch = useDispatch();
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const [errorInfo, setErrorInfo] = useState(null);
    const status = useSelector(state => state.outstandingData.status);

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
        const timeSinceLastFetch = Date.now() - lastFetchedCharges;
        const shouldRefetch = timeSinceLastFetch > 1000 * 60 * 5; // 5 minutes

        if (instr_id && shouldRefetch) {
            dispatch(fetchOutstandingData(instr_id))
                .unwrap()
                .catch(error => {
                    setErrorInfo('OutstandingCharges-fetchOutstandingData\n' + error);
                });
        }

        outstandingCharges.forEach(charge => {
            const newProductId = charge.entity_type + '-' + charge.ref_id;

            const newProduct = {
                id: newProductId,
                charge_id: charge.charge_id,
                title: charge.title,
                price: charge.price,
                subtitle1: charge.subtitle1,
                subtitle2: charge.subtitle2,
                imageSrc: charge.imageSrc,
                imageAlt: charge.imageAlt,
                discount: {code: null, type: null, rate: 0, amount: 0},
                remainingAmt: charge.remainingAmt,
                shipping: charge.shipping,
                taxes: charge.taxes,
                payments_made: charge.payments_made,

            };
            // console.log(newProduct);
            dispatch(addProductIfNotExists(newProduct));
    });
    }, [dispatch, outstandingCharges, lastFetchedCharges, instr_id]);

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div>
            <LoadingSpinner isLoading={status === 'loading'}/>
            {outstandingCharges && outstandingCharges.length > 0 ? (
                <PaymentGateway />
            ) : (
                <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Surface Interval Over!</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">You're all paid up. Gear up, your underwater adventure awaits!</p>
                    </div>
                </main>
            )}
        </div>
    );
};

export default withAuth(OutstandingCharges);
