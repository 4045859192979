import React from 'react';
import {FaEnvelope, FaSms, FaPhoneSquare, FaWhatsapp, FaFacebookMessenger} from 'react-icons/fa';

const ContactIcons = ({email, phone, fbUsername}) => {
    const whatsapp_link = `https://wa.me/${phone.replace(/[+\s-]/g, '')}`;
    const messengerLink = `https://m.me/${fbUsername}?ref=${phone.replace(/[+\s-]/g, '')}`;

    return (
        <div className="flex items-center space-x-2 mt-1">
            <a href={`mailto:${email}`} target="_blank"
               rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                <FaEnvelope className="h-5 w-5"/>
            </a>
            <a href={`sms:${phone}`} target="_blank"
               rel="noopener noreferrer" className="text-green-500 hover:text-green-700">
                <FaSms className="h-5 w-5"/>
            </a>
            <a href={`tel:${phone}`} target="_blank"
               rel="noopener noreferrer" className="text-gray-500 hover:text-gray-700">
                <FaPhoneSquare className="h-5 w-5"/>
            </a>
            <a href={whatsapp_link} target="_blank"
               rel="noopener noreferrer" className="text-green-500 hover:text-green-700">
                <FaWhatsapp className="h-5 w-5"/>
            </a>
            {/*TODO: Add preferred contact method and username to students table, include facebook messenger option */}
            {/*<a href={messengerLink} className="text-blue-600 hover:text-blue-800">*/}
            {/*    <FaFacebookMessenger className="h-5 w-5"/>*/}
            {/*</a>*/}
        </div>
    );
};

export default ContactIcons;
