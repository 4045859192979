import { createSlice } from '@reduxjs/toolkit';

const courseSignupInfoSlice = createSlice({
    name: 'courseSignupInfo',
    initialState: {
        course_code: null,
        course_name: null
    },
    reducers: {
        setCourseSignupInfo: (state, action) => {
            state.course_code = action.payload.course_code;
            state.course_name = action.payload.course_name;
        },
        clearCourseSignupInfo: state => {
            state.course_code = null;
        }
    }
});

export const { setCourseSignupInfo, clearCourseSignupInfo } = courseSignupInfoSlice.actions;

export default courseSignupInfoSlice.reducer;
