import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {fetchEventById, setModalContent} from "../redux/eventsSlice";
import {LoadingSpinner} from "./LoadingSpinner";
import {Helmet} from "react-helmet-async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {Tab} from "@headlessui/react";
import {FaParking, FaHandshake, FaTimes} from "react-icons/fa";
import {MdHelpOutline, MdScubaDiving} from "react-icons/md";
import {setLastPage} from "../redux/navSlice";
import RsvpModal from "../components/RsvpModal";
import {RadioGroup} from '@headlessui/react'
import Tooltip from "../components/Tooltip";
import {formatEventTimeRange} from "../hooks/useCalendarLogic";
import {myCustomAuthTheme as portraitModalData} from "./sharedHooks";
import {getImageUrl} from "../utils/sanitizeFunctions";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// TODO: Use dive planning chart from PADI logbook as image for multiple dive details. i.e. depth, bottom time, PG, SI, PG, depth, bottom time, PG.

function AfterEventDetails({eventId, eventDetails}) {
    const dispatch = useDispatch();
    const url = encodeURIComponent(window.location.href);
    const navigate = useNavigate();
    const [isRSVPModalOpen, setRSVPModalOpen] = useState(false);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const modalContent = useSelector((state) => state.events.modalContent);
    const [errorInfo, setErrorInfo] = useState(null);
    const student_login_email = useSelector((state) => state.auth.userDetails?.login_email ?? null);
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const currentStudentId = userDetails?.id;
    const [isBusy, setIsBusy] = useState(false);
    const rsvpStatusChanged = useSelector(state => state.events.rsvpChanged);
    const [selectedParticipantCriteria, setSelectedParticipantCriteria] = useState({})
    const [currentUserCriteria, setCurrentUserCriteria] = useState(null);
    const [eventImage, setEventImage] = useState('/images/generic-event.webp');

    const [formErrors, setFormErrors] = useState({
        participation_criteria: ''
    });

    const [portraitModalData, setPortraitModalData] = useState({visible: false, name: '', base64: ''});
    const handlePortraitClick = (name, base64) => {
        setPortraitModalData({
            visible: true,
            name,
            base64
        });
    };

    const participationCriteria = useMemo(() => {
        return eventDetails ? eventDetails.participation_criteria : [];
    }, [eventDetails]);

    useEffect(() => {
        setFormErrors({
            participation_criteria: ''
        })
    }, [selectedParticipantCriteria])

    useEffect(() => {
        if (!eventDetails) {
            dispatch(fetchEventById({event_id: eventId}));
        }
    }, [eventId, eventDetails, dispatch]);

    useEffect(() => {
        dispatch(fetchEventById({event_id: eventId}));
    }, [rsvpStatusChanged]);

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    const closeModal = () => {
        setRSVPModalOpen(false);
    };

    const navigateToCoursePage = (courseSlug) => {
        if (courseSlug) {
            navigate(`/courses/${courseSlug}`)
        }
    };

    if (errorInfo) {
        throw errorInfo;
    }

    useEffect(() => {
        if (eventDetails?.image_url) {
            setEventImage(eventDetails.image_url);
        }
    }, [eventDetails?.image_url]);

    if (!eventDetails) {
        return <LoadingSpinner isLoading={!eventDetails}/>
    }

    const handleClosePortraitModal = () => {
        setPortraitModalData({visible: false, name: '', base64: ''});
    };

    return (
        <>
            <div className="">
                <Helmet>
                    <meta property='og:title' content={eventDetails.title}/>
                    <meta property='og:image' content={origin + eventDetails.image_url}/>
                    <meta property='og:description' content={eventDetails.location_name}/>
                    <meta property='og:url' content={url}/>
                </Helmet>
                <div className="mx-auto px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                        <div className="lg:col-span-4 lg:row-end-1">
                            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                                <img src={eventImage}
                                     alt={eventDetails.title}
                                     className="object-cover object-center"
                                     onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/generic-event.webp"
                            }}/>
                            </div>
                        </div>

                        <div
                            className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                            <div className="flex flex-col-reverse">
                                <div className="mt-4 flex justify-between items-center">
                                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                                        {eventDetails.title}</h1>
                                    {/*<h2 className="text-2xl font-padi tracking-tight text-gray-700 sm:text-3xl">*/}
                                    {/*    ${course.cost}</h2>*/}
                                </div>
                                <h2 id="information-heading" className="sr-only">
                                    Event information
                                </h2>
                            </div>

                            <div className="prose mt-6 text-gray-900">
                                <p className="ml-0">{formatEventTimeRange(eventDetails.start_time, eventDetails.end_time, eventDetails.timezone)}</p>
                            </div>

                            {/* The Modal */}
                            {portraitModalData.visible && (
                                <div
                                    className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-black">
                                    <div className="bg-transparent p-4 rounded relative flex flex-col items-center">
                                        <img src={portraitModalData.base64} alt={portraitModalData.name}
                                             className="max-w-[calc(100vw-2rem)] max-h-[calc(100vh-4rem)] object-contain"/>
                                        <div
                                            className="w-full text-center text-white text-lg font-semibold bg-black">
                                            {portraitModalData.name}
                                        </div>
                                        <button onClick={handleClosePortraitModal}
                                                className="absolute top-0 right-0 p-2 bg-white rounded-full">
                                            <FaTimes color="black" size="1.2em"/>
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div className="attendee-icons flex flex-wrap mt-2 relative">
                                {/* Display portrait icons for existing attendees */}
                                {eventDetails?.attendee_details && eventDetails?.attendee_details.map((attendee, idx) => {
                                    const portraitUrl = getImageUrl('userPortrait-' + attendee.student_id + '.jpeg');
                                    return (
                                        <div key={idx} className="relative flex-shrink-0">
                                            <img
                                                onClick={() => handlePortraitClick(attendee.first_name, portraitUrl)}
                                                src={portraitUrl}
                                                alt={attendee.student_id}
                                                className='h-14 w-14 rounded-full mx-1 my-1 object-cover'
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="mt-10 border-t border-gray-200 pt-10">
                                <h3 className="text-sm font-medium text-gray-900">Share</h3>
                                <ul className="mt-4 flex items-center space-x-6">
                                    <li>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank"
                                           rel="noreferrer"
                                           className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Share on Facebook</span>
                                            <svg className="h-5 w-5" aria-hidden="true" fill="currentColor"
                                                 viewBox="0 0 20 20">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`https://x.com/intent/tweet?text=${url}!&url=${url}`}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Share on X</span>
                                            <FontAwesomeIcon icon={faXTwitter}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <RsvpModal
                modalContent={modalContent}
                closeModal={closeModal}
                isRSVPModalOpen={isRSVPModalOpen}
                onNavigateToCoursePage={navigateToCoursePage}
                isBusy={isBusy}
            />
        </>

    );
}

export default AfterEventDetails;
