import ProspectiveInstrGoals from '../components/ProspectiveInstrGoalsSection';
import ProspectiveInstrPricingSection from "../components/ProspectiveInstrPricingSection";
import React from "react";
import withAuth from "./withAuth";

const ManageSubscription = () => {
    return (
        <div>
            <div>
                <main className="isolate">
                    <ProspectiveInstrPricingSection/>
                    <div className="mx-auto max-w-4xl text-center">
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Feature Set
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                        Need a refresher on the features we offer? Check out our feature set below.
                    </p>
                    <ProspectiveInstrGoals/>
                </main>
            </div>
        </div>
    );
};

export default withAuth(ManageSubscription, {showIntro: false});
