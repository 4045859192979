import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SignatureBlock from "../components/SignatureBlock";
import {getImageUrl} from "../utils/sanitizeFunctions";

export default function About() {
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const featureFlags = instrDetails?.enabled_features?.[0] || {
        show_calendar: false,
        show_contact: false,
        instructor_portal: false,
        manage_calendar: false,
        show_course_signup: false
    };
    const instrOrgMemNum = useSelector((state) => state.instrDetails?.instrDetails.organization + '-' + state.instrDetails?.instrDetails.member_number);
    const [instrImage, setInstrImage] = useState('/images/portraits/unknown_male.png');

    useEffect(() => {
        if (instrOrgMemNum) {
            setInstrImage(getImageUrl(instrOrgMemNum + '.jpeg'));
        }
    }, [instrOrgMemNum])

    const getPrimaryProLevelInfo = (proLevelInfo) => {
        return proLevelInfo ? proLevelInfo.find(info => info.foremost) || {} : {};
    }

    const primaryProLevelInfo = getPrimaryProLevelInfo(instrDetails?.pro_level_info);

    const instrWithAdditionalFields = {
        ...instrDetails,
        organization: primaryProLevelInfo.pro_level_organization,
        pro_level: primaryProLevelInfo.pro_level,
        member_number: primaryProLevelInfo.member_number,
    };

    return (
        <>
            <section className="bg-gray-100">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="col-span-2">
                            <span className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                                {instrDetails.pro_level_info.map((info, index) => (
                                    <p key={index} className="text-xl font-bold text-gray-800">
                                        {`${info.pro_level_organization} ${info.pro_level} ${info.member_number ? '#' + info.member_number : ''}`}
                                    </p>
                                ))}
                            </span>
                            {instrDetails && Array.isArray(instrDetails.about_me) && instrDetails.about_me.map((para, index) => (
                                <p key={index} className="mt-4 text-gray-600 text-lg">{para}</p>
                            ))}
                            {featureFlags.show_contact && (
                                <div className="mt-8">
                                    <Link
                                        to="/contact"
                                        className="text-blue-500 hover:text-blue-600 font-medium"
                                    >
                                        Contact me <span className="ml-2">&#8594;</span>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="col-span-1">
                            <div className="mb-8">
                                <img
                                    className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                                    src={instrImage}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "/images/portraits/unknown_male.png"
                                    }}
                                    alt="Instructor Portrait"
                                />
                            </div>
                            <div>
                                <SignatureBlock instrDetails={instrWithAdditionalFields} theme='light'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
