import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import {useNavigate} from "react-router-dom";
import {setLastPage} from "../../redux/navSlice";
import {post} from "aws-amplify/api";
import ResultModal from "../ResultModal";

const DiverActivitiesForm = () => {
    const navigate = useNavigate();
    const loginEmail = useSelector((state) => state.auth.loginEmail);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const [formData, setFormData] = useState({
        form_short_name: 'Diver Activities',
        diver_name: '',
        insurance: 'no',
        policy_number: '',
        signature_date: ''
    });

    const handleInsuranceChange = (value) => {
        setFormData({...formData, insurance: value});

        document.getElementById("insurance").classList.remove('border-red-500');
        document.getElementById("insurance").classList.add('border-gray-300')
    };

    useEffect(() => {
        if (userDetails) {
            setFormData(prevFormData => ({
                ...prevFormData,
                diver_name: userDetails.first_name + ' ' + userDetails.last_name,
            }));
        }
    }, [userDetails, dispatch]);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const sigCanvas = useRef(null);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        const checkboxElement = document.getElementById('eSignConsent');
        if (e.target.checked) {
            checkboxElement.classList.remove('border-red-500');
            checkboxElement.classList.add('border-gray-300');
        } else {
            checkboxElement.classList.add('border-red-500');
            checkboxElement.classList.remove('border-gray-300');
        }
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });

    const closeModal = () => {
        setShowModal(false);
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sigCanvas.current.isEmpty()) {
            document.getElementById(`sigCanvas`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`sigCanvas`).classList.add('border-red-500');
            document.getElementById(`sigCanvas`).classList.remove('border-gray-500');
            return;
        } else {
            document.getElementById(`sigCanvas`).classList.add('border-gray-500');
            document.getElementById(`sigCanvas`).classList.remove('border-red-500');
        }

        if (!formData.signature_date) {
            document.getElementById(`signature_date`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`signature_date`).classList.add('border-red-500');
            return;
        }

        if (!formData.insurance) {
            document.getElementById(`insurance`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`insurance`).classList.add('border-red-500');
            return;
        }

        if (!isCheckboxChecked) {
            document.getElementById(`eSignConsent`).scrollIntoView({block: 'center', behavior: 'smooth'});
            document.getElementById(`eSignConsent`).classList.add('border-red-500');
            return;
        }

        if (sigCanvas.current) {
            const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setIsSubmitting(true);

            const dataToSend = {
                formData,
                signature,
                instr_id: instrDetails.instr_id,
                instr_full_name: instrDetails.first_name + ' ' + instrDetails.last_name,
                instr_email: instrDetails.preferred_email,
                form_slug: "diver-activities-form",
                student_login_email: loginEmail,
                student_preferred_email: userDetails.preferred_email,
                store_resort: instrDetails.store_resort
            }

            const apiName = 'InstrOfScubaAPI'
            const path = '/esign'
            const options = {
                method: 'POST',
                body: dataToSend,
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = await body.json();
                if (data.success) {
                    setResultModalObj({
                        type: 'success',
                        title: 'Document Submitted!',
                        body: 'Your document has been signed, sealed, and delivered.'
                    });
                    setShowModal(true);
                }
            } catch (error) {
                if (error.response && error.response.status === 504) {
                    // Handling specific 504 Gateway Timeout error
                    setResultModalObj({
                        type: 'error',
                        title: 'Timeout Error',
                        body: 'The process is taking longer than expected and may or may not complete successfully.  ' +
                            'Please check your email for confirmation.  If not received, please contact support.'
                    });
                    setShowModal(true);
                } else {
                    setErrorInfo('handleSubmit - /esign\n' + error);
                }
            }
            setIsSubmitting(false);
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                canvas.width = canvas.offsetWidth;
                canvas.height = canvas.offsetHeight;
            }
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/signin', {state: {redirectPath: window.location.pathname}});
    };

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    useEffect(() => {
        console.log(errorInfo);
        if (errorInfo) {
            throw errorInfo;
        }
    }, [errorInfo])

    return (
        <>
            <section className="bg-gray-100 font-sans leading-normal">
                <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <img src="/images/logos/padi-vert-color.png" alt="PADI Logo" className="float-left mr-4 mb-4 max-w-xs" />
                    <div className="text-center">
                        <h2 className="text-2xl sm:text-2xl font-bold text-gray-900 mb-1">
                            Release of Liability / Assumption of Risk / Non-agency Acknowledgment Form
                        </h2>
                        <h1 className="text-3xl sm:text-3xl font-extrabold text-gray-900 mb-2">Diver Activities</h1>
                    </div>
                    {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="mt-4 mb-4 text-lg text-gray-600 text-justify">
                            <p className="mb-4">Please read carefully before signing.</p>
                            <h3 className="text-lg text-gray-900 font-bold mb-2 text-center">NON-AGENCY DISCLOSURE AND
                                ACKNOWLEDGEMENT AGREEMENT</h3>
                            <p className="mb-4">
                                I understand and agree that PADI Members (“Members”), including {instrDetails.store_resort},
                                and/or any individual PADI Instructors and Divemasters associated with the program in
                                which I am participating, are licensed to use various PADI Trademarks and to conduct
                                PADI training, but are not agents, employees or franchisees of PADI Americas, Inc., or
                                its parent, subsidiary and affiliated corporations (“PADI”). I further understand that
                                Member business activities are independent, and are neither owned nor operated by PADI,
                                and that while PADI establishes the standards for PADI diver training programs, it is
                                not responsible for, nor does it have the right to control, the operation of the
                                Members’ business activities and the day-to-day conduct of PADI programs and supervision
                                of divers by the Members or their associated staff. I further understand and agree on
                                behalf of myself, my heirs and my estate that in the event of an injury or death during
                                this activity, neither I nor my estate shall seek to hold PADI liable for the actions,
                                inactions or negligence of the entities listed above and/or the instructors and
                                divemasters associated with the activity.
                            </p>
                            <h3 className="text-lg text-gray-900 font-bold mb-2 text-center">
                                LIABILITY RELEASE AND ASSUMPTION OF RISK AGREEMENT
                            </h3>
                            <p className="mb-1">
                                I, {formData.diver_name}, hereby affirm that I am a certified scuba diver trained in
                                safe dive
                                practices, or a student diver under the control and supervision of a certified scuba
                                instructor. I know that skin diving, freediving
                                and scuba diving have inherent risks including those risks associated with boat travel
                                to and from the dive site (hereinafter
                                “Excursion”), which may result in serious injury or death. I understand that scuba
                                diving with compressed air involves certain
                                inherent risks; including but not limited to decompression sickness, embolism or other
                                hyperbaric/air expansion injury that require
                                treatment in a recompression chamber. If I am scuba diving with oxygen enriched air
                                (“Enriched Air”) or other gas blends including
                                oxygen, I also understand that it involves inherent risks of oxygen toxicity and/or
                                improper mixtures of breathing gas. I
                                acknowledge this Excursion includes risks of slipping or falling while on board the
                                boat, being cut or struck by a boat while in
                                the water, injuries occurring while getting on or off a boat, and other perils of the
                                sea. I further understand that the Excursion
                                will be conducted at a site that is remote, either by time or distance or both, from a
                                recompression chamber. I still choose to
                                proceed with the Excursion in spite of the absence of a recompression chamber in
                                proximity to the dive site(s).
                            </p>
                            <p className="mb-1">
                                I understand and agree that neither {instrDetails.store_resort}; nor the dive
                                professional(s) who may
                                be present at the dive site, nor PADI Americas, Inc., nor any of their affiliate and
                                subsidiary corporations, nor any of their respective
                                employees, officers, agents, contractors and assigns (hereinafter “Released Parties”)
                                may be held liable or responsible in any way for
                                any injury, death or other damages to me, my family, estate, heirs or assigns that may
                                occur during the Excursion as a result of my
                                participation in the Excursion or as a result of the negligence of any party, including
                                the Released Parties, whether passive or active.</p>
                            <p className="mb-1">
                                I affirm I am in good mental and physical fitness for the Excursion. I further state
                                that I will not participate in the Excursion if I am
                                under the influence of alcohol or any drugs that are contraindicated to diving. If I am
                                taking medication, I affirm that I have seen
                                a physician and have approval to dive while under the influence of the medication/drugs.
                                I understand that diving is a physically
                                strenuous activity and that I will be exerting myself during the Excursion and that if I
                                am injured as a result of heart attack, panic,
                                hyperventilation, drowning or any other cause, that I expressly assume the risk of said
                                injuries and that I will not hold the Released
                                Parties responsible for the same.</p>
                            <p className="mb-1">
                                I am aware that safe dive practices suggest diving with a buddy unless trained as a
                                self-reliant diver. I am aware it is my responsibility
                                to plan my dive allowing for my diving experience and limitations, and the prevailing
                                water conditions and environment. I will not
                                hold the Released Parties responsible for my failure to safely plan my dive, dive my
                                plan, and follow the instructions and dive briefing
                                of the dive professional(s).</p>
                            <p className="mb-1">
                                If diving from a boat, I will be present at and attentive to the briefing given by the
                                boat crew. If there is anything I do not understand
                                I will notify the boat crew or captain immediately. I acknowledge it is my
                                responsibility to plan my dives as no-decompression dives,
                                and within parameters that allow me to make a safety stop before ascending to the
                                surface, arriving on board the vessel with gas
                                remaining in my cylinder as a measure of safety. If I become distressed on the surface I
                                will immediately drop my weights and inflate
                                my BCD (orally or with low pressure inflator) to establish buoyancy on the surface.
                            </p>
                            <p className="mb-1">
                                I am aware safe dive practices recommend a refresher or guided orientation dive
                                following a
                                period of diving inactivity. I understand
                                such refresher/guided dive is available for an additional fee. If I choose not to follow
                                this recommendation I will not hold the Released
                                Parties responsible for my decision.</p>
                            <p className="mb-1">
                                I acknowledge Released Parties may provide an in-water guide (hereinafter “Guide”)
                                during
                                the Excursion. The Guide is present to
                                assist in navigation during the dive and identifying local flora and fauna. If I choose
                                to
                                dive with the Guide I acknowledge it is my
                                responsibility to stay in proximity to the Guide during the dive. I assume all risks
                                associated with my choice whether to dive in proximity
                                to the Guide or to dive independent of the Guide. I acknowledge my participation in
                                diving
                                is at my own risk and peril.</p>
                            <p className="mb-1">
                                I affirm it is my responsibility to inspect all of the equipment I will be using prior
                                to
                                the leaving the dock for the Excursion and that I
                                should not dive if the equipment is not functioning properly. I will not hold the
                                Released
                                Parties responsible for my failure to inspect
                                the equipment prior to diving or if I choose to dive with equipment that may not be
                                functioning properly.</p>
                            <p className="mb-1">
                                I acknowledge Released Parties have made no representation to me, implied or otherwise,
                                that
                                they or their crew can or will perform
                                affective rescues or render first aid. In the event I show signs of distress or call for
                                aid
                                I would like assistance and will not hold the
                                Released Parties, their crew, dive boats or passengers responsible for their actions in
                                attempting the performance of rescue or first aid.</p>
                            <p className="mb-1">
                                I hereby state and agree that this Agreement will be effective for all Excursions in
                                which I
                                participate for one (1) year from the date
                                on which I sign this Agreement.</p>
                            <p className="mb-1">
                                I further state that I am of lawful age and legally competent to sign this liability
                                release, or that I have acquired the written consent of
                                my parent or guardian. I understand the terms herein are contractual and not a mere
                                recital,
                                and that I have signed this Agreement
                                of my own free act and with the knowledge that I hereby agree to waive my legal rights.
                                I
                                further agree that if any provision of
                                this Agreement is found to be unenforceable or invalid, that provision shall be severed
                                from
                                this Agreement. The remainder of this
                                Agreement will then be construed as though the unenforceable provision had never been
                                contained herein. I understand and agree
                                that I am not only giving up my right to sue the Released Parties but also any rights my
                                heirs, assigns, or beneficiaries may have to
                                sue the Released Parties resulting from my death. I further represent that I have the
                                authority to do so and that my heirs, assigns, and
                                beneficiaries will be estopped from claiming otherwise because of my representations to
                                the Released Parties.</p>
                            <p className="mb-1 uppercase">
                                I, {formData.diver_name}, BY THIS INSTRUMENT, AGREE TO EXEMPT AND RELEASE THE RELEASED
                                PARTIES DEFINED ABOVE FROM ALL LIABILITY OR RESPONSIBILITY WHATSOEVER FOR PERSONAL
                                INJURY,
                                PROPERTY DAMAGE OR WRONGFUL DEATH HOWEVER CAUSED, INCLUDING BUT NOT LIMITED TO THE
                                NEGLIGENCE OF THE RELEASED PARTIES, WHETHER PASSIVE OR ACTIVE.
                                I HAVE FULLY INFORMED MYSELF AND MY HEIRS OF THE CONTENTS OF THIS NON-AGENCY DISCLOSURE
                                AND
                                ACKNOWLEDGMENT AGREEMENT, AND LIABILITY RELEASE AND ASSUMPTION OF RISK AGREEMENT BY
                                READING
                                BOTH BEFORE SIGNING BELOW ON BEHALF OF MYSELF AND MY HEIRS.</p>
                        </div>
                        <div className="flex flex-col">
                            Sign here with your mouse, finger, or stylus:
                            <div id="sigCanvas" className="relative w-full h-48 border border-gray-300 rounded">
                                <SignatureCanvas ref={sigCanvas}
                                                 canvasProps={{className: 'signature-canvas w-full h-full'}}/>
                            </div>
                            <div className="flex justify-between mt-4 items-start">
                                <button type="button" onClick={clearSignature}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Clear
                                </button>
                                <input type="date" id="signature_date" name="signature_date" value={formData.signature_date}
                                       onChange={handleInputChange}
                                       className="border rounded-md py-0"/>
                            </div>
                        </div>
                        <div id="insurance"
                             className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                            <div className="text-lg text-gray-700">Diver Accident Insurance</div>
                            <span className="isolate inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => handleInsuranceChange('yes')}
                                        className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                            formData.insurance === 'yes' ? 'text-white bg-green-500' : 'text-gray-900 bg-white'
                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-l-md`}
                                    >
                                      Yes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleInsuranceChange('no')}
                                        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                            formData.insurance === 'no' ? 'text-white bg-red-500' : 'text-gray-900 bg-white'
                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-r-md`}
                                    >
                                      No
                                    </button>
                                  </span>
                        </div>
                        {formData.insurance === 'yes' && (
                            <div>
                                <label htmlFor="instructorName" className="block text-sm font-medium text-gray-700">
                                    Policy Number
                                </label>
                                <input
                                    type="text"
                                    id="policy_number"
                                    name="policy_number"
                                    value={formData.policy_number}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    className="w-full rounded-md"
                                />
                            </div>
                        )}
                        <div key="eSignConsent" id="eSignConsent"
                             className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                            <div className="text-lg text-gray-700">
                                By checking here, you are consenting to the use of your electronic signature in lieu
                                of an original signature on paper. You have the right to request that you sign a
                                paper copy instead. By checking here, you are waving that right. If you prefer to
                                print a paper copy,
                                please <a className="hover:underline text-blue-600"
                                          href="/files/10086_Divers_Liability_Form_v3.pdf"
                                          download>download the form here</a>. After consent, you
                                may, upon written request to us, obtain a paper copy of an electronic record. No fee
                                will be charged for such copy and no special hardware or software is required to
                                view it. Your agreement to use an electronic signature with us for any documents
                                will continue until such time as you notify us in writing that you no longer wish to
                                use an electronic signature. There is no penalty for withdrawing your consent. You
                                should always make sure that we have a current email address in order to contact you
                                regarding any changes, if necessary.
                            </div>
                            <div id="checkbox-question" className="isolate inline-flex rounded-md shadow-sm">
                                <input
                                    type="checkbox"
                                    id="checkboxEsignConsent"
                                    name="checkboxEsignConsent"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                        </div>
                        {isAuthenticated ? (
                            <button type="submit"
                                    disabled={isSubmitting}
                                    className="float-right rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >{isSubmitting ? 'Submitting...' : 'Agree & Submit'}
                            </button>
                        ) : (
                            <button type="button" onClick={handleLoginClick}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 float-right">
                                Please Login to E-Sign this Document
                            </button>
                        )}
                    </form>
                </div>
            </section>
        </>
    )
        ;
};

export default DiverActivitiesForm;
