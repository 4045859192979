import {loadStripe} from "@stripe/stripe-js";
import {post} from "aws-amplify/api";
import {useState} from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStripeCheckout = () => {
    const [isBusy, setIsBusy] = useState(false);
    const [errorInfo, setErrorInfo] = useState(null);

    const handleCheckout = async (tierId, discountCodes) => {
        setIsBusy(true);
        const apiName = 'InstrOfScubaAPI';
        const path = '/stripe-payment';
        const options = {
            method: 'POST',
            body: {tier_id: tierId, discount_codes: discountCodes},
            headers: {
                'Content-Type': 'application/json',
            }
        };
        try {
            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            const sessionId = data.sessionId
            const stripe = await stripePromise;
            stripe.redirectToCheckout({sessionId});
        } catch (error) {
            setIsBusy(false);
            setErrorInfo('StripeCheckout-handleCheckout-/stripe-payment\n' + error);
        }
    };

    return {isBusy, errorInfo, handleCheckout};
};

export default useStripeCheckout;
