import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Master Scuba Diver",
    description: "Join the best of the best in recreational scuba diving and live the dive life as a PADI Master Scuba " +
        "Diver. The Master Scuba Diver rating places you in an elite group of respected divers who have earned this " +
        "rating through both significant experience and scuba training. Fewer than two percent of divers ever " +
        "achieve this rating. When you flash your Master Scuba Diver card, people know that you've spent time " +
        "underwater in a variety of environments and had your share of dive adventures."
}

const faqs = [
    {
        question: "What's the Difference Between a Master Scuba Diver and a Divemaster?",
        answer: "A Master Scuba Diver is a non-professional certification and represents the highest level of " +
            "recreational diving. A Divemaster is a professional rating, and Divemasters can work in the diving " +
            "industry supervising recreational diving activities and assisting with diving instruction."
    }
]

const academics = [
    {
        title: 'Not Applicable',
        body:
            "There are no additional academics for this course.  Once you have obtained the requisite experience " +
            "and certifications, you can apply for the Master Scuba Diver rating.  The price listed for the course " +
            "represents a discounted price for committing to the five specialty courses required for the rating."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "Refer to the individual specialities included in your package to determine if confined water dives " +
            "will be part of your training."
    },
    {
        title: 'Ocean',
        body: "Specialities range from 2 to 4 dives each.  Talk to your instructor to determine exactly which " +
            "specialities you will be taking and how many dives will be required."
    }
]

const progressionCourseCodes = ['PADI-DM'];

const requirements = [
    '12 years old',
    '50 logged dives',
    'PADI Rescue Diver',
    'Five PADI Specialty Diver certifications'
];

const PadiMasterScubaDiver = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-MSD"
        imageUrl='/images/PADI/master-scuba-diver.jpg'
        courseSlug='/courses/padi-master-scuba-diver'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Master%20Scuba%20Diver"
    />
);

export default PadiMasterScubaDiver;
