import {useEffect, useState} from 'react';
import CalendarHeader from "../components/CalendarHeader";
import CalendarGrid from "../components/CalendarGrid";
import CalendarEventList from "./CalendarEventList";
import {useCalendarLogic} from "../hooks/useCalendarLogic";
import {setLastPage} from "../redux/navSlice";
import {useDispatch} from "react-redux";

const CalendarPage = () => {
    const [isUserImageUpdating, setIsUserImageUpdating] = useState(false);
    const [isImagePlaceholderUpdating, setIsImagePlaceholderUpdating] = useState(false);
    const [isRsvpButtonUpdating, setIsRsvpButtonUpdating] = useState(false);

    const {
        selectedDate,
        firstDateInDisplayedMonth,
        hasEvent,
        handleDateClick,
        handleNextMonth,
        handlePrevMonth,
        isToday,
        days,
        filteredEvents,
    } = useCalendarLogic();

    const [errorInfo] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);


    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
                <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
                    <CalendarHeader
                        firstDateInDisplayedMonth={firstDateInDisplayedMonth}
                        handlePrevMonth={handlePrevMonth}
                        handleNextMonth={handleNextMonth}
                    />
                    <CalendarGrid
                        days={days}
                        handleDateClick={handleDateClick}
                        hasEvent={hasEvent}
                        isToday={isToday}
                        selectedDate={selectedDate}
                    />
                </div>
                <CalendarEventList
                    viewMode="user"
                    filteredEvents={filteredEvents}
                    selectedDate={selectedDate}
                    isRsvpButtonUpdating={isRsvpButtonUpdating}
                    isImagePlaceholderUpdating={isImagePlaceholderUpdating}
                    isUserImageUpdating={isUserImageUpdating}
                />
            </div>
        </div>
    )
}

export default (CalendarPage);
