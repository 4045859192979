import React from 'react';
import {FaCcMastercard, FaCcVisa, FaApple} from "react-icons/fa6";
import {IoLogoVenmo} from "react-icons/io5";
import {SiCashapp, SiZelle} from "react-icons/si";

const PaymentOptions = ({formData, handleChange}) => {

    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
                Payment Options
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                How would you like to handle payments from your customers? You can use your own digital wallets like
                Venmo, Apple Cash, Cash App, Zelle, etc., or you can disable
                payments altogether. To display QR Codes for your customers to scan, share your QR code to
                support@instructorofscuba.com from your registered email address and we will upload it for you.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/*<div className="sm:col-span-2 sm:col-start-1">*/}
                {/*    <label htmlFor="stripe_integration"*/}
                {/*           className="flex items-center text-sm font-medium leading-6 text-gray-900">*/}
                {/*        <FaCcVisa className="mr-1"/><FaCcMastercard className="mr-1"/>Visa/Mastercard*/}
                {/*    </label>*/}
                {/*    <div className="mt-2">*/}
                {/*        <div*/}
                {/*            className="flex rounded-md sm:max-w-md">*/}
                {/*            <div className="flex items-center">*/}
                {/*                <input*/}
                {/*                    type="radio"*/}
                {/*                    name="stripe_enabled"*/}
                {/*                    value="enabled"*/}
                {/*                    id="stripe_enabled"*/}
                {/*                    checked={formData.pmt_stripe_integration}*/}
                {/*                    onChange={handleChange}*/}
                {/*                    className="mr-2"*/}
                {/*                />*/}
                {/*                <label htmlFor="facebook_link_enabled"*/}
                {/*                       className="mr-4 text-sm font-medium text-gray-900">*/}
                {/*                    Enable*/}
                {/*                </label>*/}
                {/*                <input*/}
                {/*                    type="radio"*/}
                {/*                    name="stripe_enabled"*/}
                {/*                    value="disabled"*/}
                {/*                    id="stripe_disabled"*/}
                {/*                    checked={formData.pmt_stripe_integration === false}*/}
                {/*                    onChange={handleChange}*/}
                {/*                    className="mr-2"*/}
                {/*                />*/}
                {/*                <label htmlFor="stripe_disabled" className="text-sm font-medium text-gray-900">*/}
                {/*                    Disable*/}
                {/*                </label>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="sm:col-span-3">
                    <label htmlFor="pmt_venmo_username"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <IoLogoVenmo className="mr-1"/>Venmo
                    </label>
                    <div className="mt-2 relative rounded-md border-0 shadow-sm">
                        <div
                            className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">@</span>
                        </div>
                        <input
                            type="text"
                            name="pmt_venmo_username"
                            value={formData.pmt_venmo_username}
                            onChange={handleChange}
                            id="pmt_venmo_username"
                            placeholder='username'
                            autoComplete="text"
                            className="block w-full pl-7 pr-12 border-0 border-gray-300 rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="pmt_zelle_username"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <SiZelle className="mr-1"/>Zelle
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                            <input
                                type="text"
                                name="pmt_zelle_username"
                                value={formData.pmt_zelle_username || ''}
                                onChange={handleChange}
                                id="pmt_zelle_username"
                                autoComplete="text"
                                placeholder='email or phone number'
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-3 sm:col-start-1">
                    <label htmlFor="pmt_apple_username"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <FaApple className="mr-1"/>Apple Cash
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                            <input
                                type="text"
                                name="pmt_apple_username"
                                value={formData.pmt_apple_username || ''}
                                onChange={handleChange}
                                id="pmt_apple_username"
                                autoComplete="text"
                                placeholder="iMessage number or email"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="pmt_cash_app_username"
                           className="flex items-center text-sm font-medium leading-6 text-gray-900">
                        <SiCashapp className="mr-1"/>Cash App
                    </label>
                    <div className="mt-2">
                        <div
                            className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span
                                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">$</span>
                            <input
                                type="text"
                                name="pmt_cash_app_username"
                                value={formData.pmt_cash_app_username || ''}
                                onChange={handleChange}
                                id="pmt_cash_app_username"
                                autoComplete="text"
                                placeholder="cashtag"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PaymentOptions;
