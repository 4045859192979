import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';

const initialState = {
    outstandingForms: [],
    outstandingCharges: [],
    lastFetchedForms: null,
    lastFetchedCharges: null,
    status: 'idle',
    error: null
};

export const fetchOutstandingStudentData = createAsyncThunk(
    // Here we retrieve outstanding forms and charges by studentId. This is used when the instructor is logged in,
    // navigates to Student List, and wants to view a specific student's outstanding data.

    'outstandingStudentData/fetchStudentOutstandingData',
    async ({ studentId }, { rejectWithValue }) => {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-outstanding-data';
        const options = {
            queryParams: { student_id: studentId }
        };
        try {
            const { body } = await get({ apiName, path, options }).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const outstandingStudentDataSlice = createSlice({
    name: 'outstandingStudentData',
    initialState,
    reducers: {
        resetOutstandingStudentData: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOutstandingStudentData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOutstandingStudentData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.outstandingForms = action.payload.outstanding_forms;
                state.lastFetchedForms = Date.now();
                state.outstandingCharges = action.payload.outstanding_charges;
                state.lastFetchedCharges = Date.now();
            })
            .addCase(fetchOutstandingStudentData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { resetOutstandingStudentData } = outstandingStudentDataSlice.actions;
export default outstandingStudentDataSlice.reducer;
