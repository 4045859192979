import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';

const padZero = (num) => String(num).padStart(2, '0');

export const fetchEvents = createAsyncThunk('events/fetchEvents', async ({
                                                                             month,
                                                                             year,
                                                                             instr_id
                                                                         }, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-events';
        const options = {queryParams: {month, year, instr_id}};
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});

export const fetchEventById = createAsyncThunk('events/fetchEventById', async ({
                                                                                   event_id,
                                                                               }, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-events';
        const options = {queryParams: {event_id}};
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});


const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        items: {},  // { year: { month: { day: [events] } } }
        modalContent: {
            type: '',
            title: '',
            options: [],
            handleClick: null,
            eventId: ''
        },
        selectedDate: null,
        error: null,
        status: 'idle',
        rsvpChanged: false,
        eventChanged: false,
        selectedEvent: '',
        autoRsvp: {
            enabled: false,
            eventId: null,
            participationCriteria: null,
        }
    },
    reducers: {
        setModalContent: (state, action) => {
            state.modalContent = {
                ...state.modalContent,
                ...action.payload
            };
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        rsvpChanged: state => {
            state.rsvpChanged = !state.rsvpChanged;
        },
        eventChanged: state => {
            state.eventChanged = !state.eventChanged;
        },
        setAutoRsvp: (state, action) => {
            state.autoRsvp = {
                enabled: true,
                ...action.payload
            };
        },
        resetAutoRsvp: (state) => {
            state.autoRsvp = {
                enabled: false,
                eventId: null,
                participationCriteria: null,
            };
        },
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(fetchEvents.pending, (state) => {
                    state.status = 'loading';
                    state.error = null;
                })
                .addCase(fetchEvents.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    state.error = null;

                    const year = String(action.meta.arg.year);
                    const month = padZero(action.meta.arg.month);

                    if (!state.items[year]) {
                        state.items[year] = {};
                    }
                    if (!state.items[year][month]) {
                        state.items[year][month] = {};
                    }

                    action.payload.forEach(event => {
                        const eventDateIso = event.start_time.split('T')[0];
                        const day = padZero(eventDateIso.split('-')[2]);

                        if (!state.items[year][month][day]) {
                            state.items[year][month][day] = [];
                        }

                        const eventIndex = state.items[year][month][day].findIndex(item => item.event_id === event.event_id);
                        if (eventIndex !== -1) {
                            state.items[year][month][day][eventIndex] = event;
                        } else {
                            state.items[year][month][day].push(event);
                        }
                    });
                })
                .addCase(fetchEvents.rejected, (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message;
                })
                .addCase(fetchEventById.pending, (state) => {
                    state.status = 'loading';
                    state.error = null;
                })
                .addCase(fetchEventById.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    state.error = null;

                    action.payload.forEach(event => {
                        const eventDate = new Date(event.start_time);
                        const year = String(eventDate.getFullYear());
                        const month = padZero(eventDate.getMonth() + 1); // Zero-pad month
                        const day = padZero(eventDate.getDate()); // Zero-pad day

                        if (!state.items[year]) {
                            state.items[year] = {};
                        }

                        if (!state.items[year][month]) {
                            state.items[year][month] = {};
                        }

                        if (!state.items[year][month][day]) {
                            state.items[year][month][day] = [];
                        }

                        // Find the index of the event if it exists, otherwise add it
                        const existingIndex = state.items[year][month][day].findIndex(e => e.event_id === event.event_id);
                        if (existingIndex >= 0) {
                            state.items[year][month][day][existingIndex] = event;
                        } else {
                            state.items[year][month][day].push(event);
                        }
                    });
                })
                .addCase(fetchEventById.rejected, (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message;
                });
        },
});

export default eventsSlice.reducer;
export const {
    setModalContent,
    setSelectedDate,
    rsvpChanged,
    eventChanged,
    setAutoRsvp,
    resetAutoRsvp
} = eventsSlice.actions;