import React from 'react';
import {useSelector} from "react-redux";
import { format } from 'date-fns';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CalendarGrid = ({ days, handleDateClick, hasEvent, isToday, selectedDate }) => {
    const getDayStyles = (day, selectedDate) => {
        let classes = 'mx-auto flex h-7 w-7 items-center justify-center rounded-full ';
        const formattedDayDate = format(day.date, 'yyyy-MM-dd');
        const formattedSelectedDate = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : null;

        if (hasEvent(formattedDayDate) && selectedDate && formattedDayDate === formattedSelectedDate) {
            classes += 'border-4 border-green-500 bg-blue-500 text-white';
        } else if (hasEvent(formattedDayDate)) {
            classes += 'bg-green-500 text-white';
        } else if (isToday(day.date) && selectedDate && formattedDayDate === formattedSelectedDate) {
            classes += 'bg-blue-500 text-white';
        } else if (isToday(day.date)) {
            classes += 'border-2 border-blue-500 ';
        } else if (selectedDate && formattedDayDate === formattedSelectedDate) {
            classes += 'bg-blue-500 text-white';
        }
        return classes;
    }

    const eventsStatus = useSelector((state) => state.events?.status);
    const isFetchingEvents = eventsStatus === 'loading';

    return (
        <>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
            </div>
            <div className={`isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200 ${isFetchingEvents ? 'animate-pulse' : ''}`}>
                {days.map((day, dayIdx) => (
                    <button
                        key={`${day.date.toISOString()}-${dayIdx}`}
                        type="button"
                        onClick={() => handleDateClick(day.date)}
                        className={classNames(
                            'py-1.5 hover:bg-gray-100 focus:z-10',
                            day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                            dayIdx === 0 && 'rounded-tl-lg',
                            dayIdx === 6 && 'rounded-tr-lg',
                            dayIdx === days.length - 7 && 'rounded-bl-lg',
                            dayIdx === days.length - 1 && 'rounded-br-lg'
                        )}
                    >
                        <time
                            dateTime={day.date.toISOString()}
                            className={getDayStyles(day, selectedDate)}
                        >
                            {day.date.getDate()}
                        </time>
                    </button>
                ))}
            </div>
        </>
    );
};

export default CalendarGrid;
