import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Deep Diver",
    description: "During the PADI® Deep Diver course, you'll learn how to plan deep dives, manage your gas supply, " +
        "how to identify and manage narcosis, safety considerations and buoyancy control at depth."
}

const faqs = [
    {
        question: 'How deep do we go?',
        answer: "Your first deep dive will be between 18 metres/60 feet and 30 metres / 100 feet.  Dives two through " +
            "four will be between 30 metres / 100 feet and 40 metres / 130 feet."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Online learning makes it easy to fit scuba lessons into a busy schedule. " +
            "Complete the knowledge development section of your course whenever, wherever it's convenient for you. " +
            "Work at your own pace, online or offline, using a computer or mobile device. "
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "This is done in a classroom setting and typically takes 2-4 hours. " +
            "Use the Inquire button if you are interested in this option.",
    }
]

const dives = [
    {
        title: 'Ocean',
        body: "There are four dives required for this course.  You will conduct these over a minimum of two days."
    }
]

const progressionCourseCodes = ['PADI-SCRB', 'PADI-TEC40', 'PADI-MSD'];

const requirements = [
    '15 years old',
    'PADI Adventure Diver',
];

const PadiDeep = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-DEEP"
        imageUrl='/images/PADI/DeepDiver-Gallery-He-T9H3_nIU.jpg'
        courseSlug='/courses/padi-deep'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Deep%20Diver%20Course"
    />
);

export default PadiDeep;
