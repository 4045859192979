import {signOut} from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {signOut as signOutAction} from "../redux/authSlice";
import {resetOutstandingData} from "../redux/outstandingDataSlice";
import {clearStudentList} from "../redux/studentsSlice";
import {clearTransactions} from "../redux/transactionsSlice";
import withAuth from "./withAuth";

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            signOut()
                .then(() => {
                    dispatch(signOutAction());
                    dispatch(resetOutstandingData());
                    dispatch(clearStudentList());
                    dispatch(clearTransactions());
                    navigate('/change-password');
                })
                .catch((error) => console.error('Error signing out:', error));
        }
    }, [isAuthenticated, navigate, dispatch]);

    return null;
};

export default withAuth(ChangePassword, {initialState: "forgotPassword"})