import React, { useState } from 'react';

const Tooltip = ({ children, content }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="relative flex items-center">
            <div
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                className="cursor-pointer"
            >
                {children}
            </div>
            {isVisible && (
                <div
                    className="absolute bottom-full mb-2 px-2 py-1 text-white bg-black text-xs rounded-md transform -translate-x-1/2 left-1/2 z-10"
                >
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
