import { createSlice } from '@reduxjs/toolkit';

export const diverMedicalFormAnswersSlice = createSlice({
    name: 'diverMedicalFormAnswers',
    initialState: {
        answers1: Array(10).fill(null), // 10 questions for the first page
        answers2: null,
        answers3: {
            lastName: '',
            firstName: '',
            middleInitial: '',
            signatureDate: '',
            facilityName: '',
            birthday: ''
        }
    },
    reducers: {
        saveAnswers1: (state, action) => {
            state.answers1 = action.payload;
        },
        saveAnswers2: (state, action) => {
            state.answers2 = action.payload;
        },
        saveAnswers3: (state, action) => {
            state.answers3 = {
                ...state.answers3,
                ...action.payload
            };
        },
        initializeAnswers2: (state, action) => {
            state.answers2 = Array(action.payload).fill(null);
        },
        initializeFacilityName: (state, action) => {
            state.answers3.facilityName = action.payload;
        }
    },
});

export const { saveAnswers1, saveAnswers2, saveAnswers3, initializeAnswers2, initializeFacilityName } = diverMedicalFormAnswersSlice.actions;

export const selectAnswers1 = (state) => state.diverMedicalFormAnswers.answers1;
export const selectAnswers2 = (state) => state.diverMedicalFormAnswers.answers2;
export const selectAnswers3 = (state) => state.diverMedicalFormAnswers.answers3;

export default diverMedicalFormAnswersSlice.reducer;
