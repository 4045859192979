import PadiCourse from './PadiCourse';
import React from "react";

const product = {
    title: "PADI Delayed Surface Marker Buoy (DSMB) Diver Course",
    description: "Boats and currents are two common factors associated with many dive sites. " +
        "Boaters don't always recognize that divers are in the area and current can carry you further than " +
        "expected. Launching a delayed surface marker buoy (DSMB) before you surface can alert others to your " +
        "location in advance of your ascent. Adding another layer of safety, you can also use the DSMB line to " +
        "complete your safety stop, which is advantageous in a current or when there are no other visual references. " +
        "Take this course to learn about all the beneficial uses of a DSMB."
}

const faqs = [
    {
        question: "Do I need special equipment?",
        answer: "Yes, you'll need a DSMB and reel.  Not all DSMBs are created equal, so be sure to get one that is " +
            "large enough to be seen from a distance and has a one-way valve to prevent air from escaping once it's " +
            "inflated.  You'll also need a reel to deploy the DSMB and a spool to stow the line.  Your instructor " +
            "will help you choose the equipment that's right for you."
    }
]

const academics = [
    {
        title: 'eLearning',
        body:
            "Complete the independent study part of your course whenever, wherever it’s convenient for you. " +
            "Work at your own pace, online or " +
            "offline, using a computer or mobile device. Connect with your instructor for inwater training, or " +
            "whenever you have a question.  The eLearning typically takes 2-4 hours to complete.",
    },
    {
        title: 'Instructor-led Training',
        body: "Alternatively, your instructor can teach the course by presenting the material to you in person. " +
            "Use the Inquire button if you are interested in this option."
    }
]

const dives = [
    {
        title: 'Confined Water',
        body: "There are no confined water dives for this course."
    },
    {
        title: 'Ocean',
        body: "There are two dives required for this course, typically conducted in the same day. During the two dives " +
            "you'll practice preparing and stowing your DSMB and reel, deploying the DSMB from a stationary position " +
            "and from mid-water, swimming while towing a DSMB and managing the reel and line, and making a safety stop " +
            "using the DSMB line."
    }
]

const progressionCourseCodes = ['PADI-MSD'];

const requirements = [
    '12 years old',
    'PADI Open Water Diver'
];

const PadiDelayedSurfaceMarker = () => (
    <PadiCourse
        product={product}
        faqs={faqs}
        academics={academics}
        dives={dives}
        courseCode="PADI-DSMB"
        imageUrl='/images/PADI/dsmb1.jpg'
        courseSlug='/courses/padi-delayed-surface-marker-buoy-dsmb'
        requirements={requirements}
        boolDiverMedical={true}
        progressionCourseCodes={progressionCourseCodes}
        urlText="PADI%20Delayed%20Surface%20Marker%20Buoy%20(DSMB)%20Diver%20Course"
    />
);

export default PadiDelayedSurfaceMarker;
