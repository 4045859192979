import { createSlice } from '@reduxjs/toolkit';

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        preferredDatesByMonth: {},
    },
    reducers: {
        setPreferredDate: (state, action) => {
            const { monthKey, date } = action.payload;
            state.preferredDatesByMonth[monthKey] = date;
        },
    },
});

export const { setPreferredDate } = calendarSlice.actions;

export default calendarSlice.reducer;
